.mainWrapper{
    display: none;
}
@media only screen and (max-width: 600px) {
    .mainWrapper {
        display: block;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100svh;
        z-index: 200;
    }


    .mainContainer {
        width: 100%;
        height: 100svh;
        position: relative;
        display: flex;
        justify-content: flex-start;
        background-color: #00000080;
    }

    .mainContent {
        height: 50svh;
        width: 100%;
        background-color: white;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
        border-bottom-left-radius: 34px;
        border-bottom-right-radius: 34px;
    }

    .formWrapper {
        width: 90%;
        padding: 0px 5%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 50svh;
    }

    .fieldContainer {
        width: 100%;
    }

    .profileSearchWrapper {
        padding: 0px 0px;
        width: 100%;
    }

    .profileSearchWrapper input {
        width: calc(100% - 22px);
        padding: 16px 10px;
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;

        border: 1px solid #b4b4b4;
        border-bottom: none;
        font-weight: 500;
        font-size: 1rem;
        background-color: whitesmoke;
    }

    .citySearchWrapper {
        padding: 0px 0px;
        width: 100%;
    }

    .citySearchWrapper input {
        width: calc(100% - 22px);
        padding: 16px 10px;
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border: 1px solid #b4b4b4;
        font-weight: 500;
        font-size: 1rem;
        background-color: whitesmoke;
    }


    .findJobBtn {
        padding: 30px 3% 10px;
        width: 94%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .findJobBtn a {
        background-color: #212121;
        color: white;
        padding: 16px 20px;
        border-radius: 40px;
        -webkit-border-radius: 40px;
        -moz-border-radius: 40px;
        -ms-border-radius: 40px;
        -o-border-radius: 40px;
        width: 240px;
        font-weight: 500;
        text-align: center;
    }

    .closeWrapper {
        font-weight: 500;
        text-align: center;
        text-transform: lowercase;
        padding: 20px 0px 0px;
        color: #b4b4b4;
    }


}