.mainWrapper{
    width: 100%;
}
.sectionWrapper{
    padding: 0px 7%;
}
.sectionTitle{
    text-align: center;
    padding: 4px 0px 20px;
}
.questionsWrapper{
    padding: 10px 0px 50px;
}



@media screen and (max-width: 480px) {
    .sectionWrapper{
        padding: 0px 4%;
    }
}