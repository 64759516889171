.mainWrapper{
    position: fixed;
    top: 55svh;
    left: 0px;
    background-color: transparent;
    width: 100%;
    display: none;
    z-index: 1000;
}
.mainContainer{
    padding: 10px 30%;
    width: 40%;
    height: 14svh;   
    position: relative;
}
.mainContent{
    height: 18svh;
    width: 100%;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.findSalonJobsWrapper{
    background-color: #ffffff;
    flex: 1;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    gap: 25px;
}
.hireSalonStaffWrapper{
    background-color: #dfdfdf40;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: #4d4d4d;
    gap: 25px;
}
.findSalonJobsWrapper .iconWrapper{
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hireSalonStaffWrapper .iconWrapper{
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btnTxt{
    padding: 0px 25px 0px 0px;
}
.hidden{
    display: none;
}
@media only screen and (max-width: 600px) {
    .mainWrapper{
        display: block;
        top: 75svh;
    }
    .mainContainer{
        padding: 10px 7%;
        width: 86%;
        height: 14svh;   
        position: relative;
    }
    
}

