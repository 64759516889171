.mainWrapper{
    width: 100%;
    background-color: #101010;
    position: relative;
    padding: 20px 0px;
}
.mainTitle{
    width: 70%;
    color: white;
    text-align: center;
    margin: 0px 15%;
}
.mainTitle h2{
    padding: 10px 0px;
    border-bottom: 2px solid #dfdfdf;
}


@media screen and (max-width: 640px) {
    .mainTitle{
        width: 90%;
        margin: 0px 5%;
    }

}
