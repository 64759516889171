.mainContainer {
    width: 100%;
    background-image: linear-gradient(#F0F9FF, white);
}

.privacyPolicyContainer {
    width: 100%;
    padding: 10vh 0;
}

.privacyPolicyContent {
    margin: 0px 7%;
    padding: 0px 1%;
    border: 1px solid #dfdfdf;
    background-color: white;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    box-shadow: -1px 0px 59px -12px rgb(0 0 0 / 19%);
    ;
}

.highlightedTitle {
    color: #ffcb08;
    font-weight: 700;
    font-size: 20px;
}


@media only screen and (max-width: 600px) {
    .privacyPolicyContent {
        padding: 0px 6%;
        text-align: justify;
    }
}