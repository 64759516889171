/* SlideInContent.module.css */

.content {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.4s ease-out, transform 0.4s ease-out;
    will-change: opacity, transform;
    -webkit-transition: opacity 0.4s ease-out, transform 0.4s ease-out;
    -moz-transition: opacity 0.4s ease-out, transform 0.4s ease-out;
    -ms-transition: opacity 0.4s ease-out, transform 0.4s ease-out;
    -o-transition: opacity 0.4s ease-out, transform 0.4s ease-out;
}

.inView {
    opacity: 1;
    transform: translateY(0);
}
