.mainContainer{
    width: 100%;
    font-family: 'Montserrat';
}
.firstPaintWrapper{
    overflow: hidden;
    position: relative;
    height: 100vh;
}
.firstPaintContent{
    position: absolute;
    height: 100vh;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 2;
}
.firstPaint{
    height: 100vh;
    width: 100%;
    background-color: transparent;
    position: relative;
}
.header{
    display: flex;
    justify-content: space-between;
    height: 10vh;
    align-items: center;
}
.menu{
    padding: 15px 2%;
}
.menu img{
    height: 24px;
}
.fpContent{
    height: 90vh;
}
.firstLineWeb{
    color: #E4CEA5;
    text-align: center;
    height: 15vh;
    display: flex;
    flex-direction: column;
    padding: 0px;
    justify-content: center;
}
.firstLineWeb h2{
    padding: 0px;
    font-weight: 600;
}
.firstLineMobile{
    display: none;
}
.boxLineWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
}
.boxLineContainer{
    text-align: center;
    padding: 5px 20px;
    background-color:#214358;
    color: #E4CEA5;
    /* border: 1px solid #E4CEA5;; */
}
.boxLineContainer h1{
    font-size: 1rem;
    font-weight: 500;
}
.shapeWrapper{
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    height: 100vh;
    width: 100%;
    background-image: linear-gradient(#05141D, #063846);
}
.shapeContainer{
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: flex-end;
}
.shapeContainer img{
    width: 100%;
}


.howToHireWrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #E4CEA5;
    flex-direction: column;
    height: 35vh;
}
.howToHireHeader{
    padding: 5px 0px 20px;
    font-weight: 500;
}
.howToHireSteps{
    display: flex;
    justify-content: space-around;
}
.eachStepWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 5%;
}
.stepCount{
    height: 50px;
    width: 50px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    display: flex;
    border: 2px solid #E4CEA5;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 600;
    margin: 0px 0px 20px;
}
.stepTxt{
    text-align: center;
}
.hireNowBtnWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
}
.hireNowBtnWrapper button{
    padding: 12px 20px;
    border: 1px solid white;
    width: 200px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.benefitTitle{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.benefitTitleWrapper{
    height: 15vh;
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: center;
}
.benefitTitle{
    width: 100%;
    padding: 0px 5%;
}
.leftLine{
    width: 10%;
    height: 3px;
    background-image: linear-gradient(90deg, #E4CEA5, #A1784F);
}
.benefitTitleTxt{
    background-color: #21435840;
    padding: 8px 20px;
}
.benefitTitleTxt h5{
    margin: 0px;
    padding: 0px 3px;
    font-weight: 500;
    color: #da1884;
    font-size: 1.4rem;
}
.rightLine{
    width: 10%;
    height: 3px;
    background-image: linear-gradient(270deg, #E4CEA5, #A1784F);
}





.uspWrapper{
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    padding: 40px 0px;
}
.eachUsp{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 40px 0px;
    
}
.caption{
    font-weight: 500;
    padding: 20px 0px;
}

.ourPresence{
    background-image: url('https://lokaci.s3.ap-south-1.amazonaws.com/dotted-map.png');
    background-repeat: no-repeat;
    height: 60vh;
    background-position: left;
    width: 100%;
    background-size: 50%;
    /* border: 1px solid rebeccapurple; */
    /* background-color: #D7CEB2; */
    /* background-color: #DCC39B; */
    /* background-color: #DEC59C; */
    /* background-color: #07323F; */
    background-color: #F6E4B0;
}
.opHeader{
    display: flex;
    justify-content: center;
}
.opHeader h4{
    font-weight: 600;
    font-size: 1.2rem;
}
.opBody{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50vh;
}
.opbLeft{
    min-width: 50%;
}
.opbRight{
    min-width: 40%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 5%;
}
.opbRight p{
    line-height: 190%;
    text-align: justify;
    font-weight: 500;
    color: #00000087;
}
.seePricingWrapper{
    width: 100%;
    text-align: center;
    padding: 0px 0px 40px;
}
.seePricingWrapper a{
    padding: 12px 80px;
    width: 300px;
    background-color: #da1884;
    color: white;
    font-weight: 500;
}

.stickyBtn {
    position: fixed;
    bottom: -1px;
    left: 0;
    right: 0;
    background-color: #E4CEA5;
    color: black;
    padding: 10px;
    transition: bottom 0.3s ease-in-out;
    cursor: pointer;
    display: none;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    -webkit-transition: bottom 0.3s ease-in-out;
    -moz-transition: bottom 0.3s ease-in-out;
    -ms-transition: bottom 0.3s ease-in-out;
    -o-transition: bottom 0.3s ease-in-out;
}

.stickyBtnHidden {
    bottom: -100px;
    display: none;
}
.hireNowSticky{
    padding: 5px 0px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
}
.hireNowStickyHidden{
    display: none;
}


@media screen and (max-width: 480px) {
    .menu{
        padding: 15px 5%;
    }
    .menu img{
        height: 14px;
    }
    .firstLineWeb{
        display: none;
    }
    .firstLineMobile{
        padding: 0px 0px;
        height: 20vh;
        display: block;
        color: #E4CEA5;
        text-align: center;
        display: flex;
        flex-direction: column;
        padding: 0px;
        justify-content: center;
    }
    .firstLineMobile h2{
        font-weight: 600;
        font-size: 1.3rem;
        margin: 2px 0px 10px;
        padding: 0px;
    }
    .boxLineWrapper{
        height: 10vh;
    }
    .boxLineContainer h1{
        font-size: 0.8rem;
    }
    .howToHireWrapper{
        height: 30vh;
    }
    .howToHireSteps{
        display: flex;
        justify-content: space-around;
        padding: 0px 5%;
    }
    .eachStepWrapper{
        padding: 10px 2%;
    }
    .stepCount{
        height: 34px;
        width: 34px;
        border-radius: 34px;
        -webkit-border-radius: 34px;
        -moz-border-radius: 34px;
        -ms-border-radius: 34px;
        -o-border-radius: 34px;
        display: flex;
        border: 2px solid #E4CEA5;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        font-weight: 600;
        margin: 0px 0px 10px;
    }
    .stepTxt{
        font-size: 0.6rem;
    }

    .benefitTitleTxt h5{
        font-size: 0.8rem;
    }

    .uspWrapper{
        flex-wrap: wrap;
    }

    .ourPresence{
        background-size: 100%;
        height: 55vh;
    }
    .opHeader{
        text-align: center;
        padding: 0px 5%;
    }
    .opHeader h4{
        font-weight: 1rem;
    }
    .opbLeft{
        min-width: 0px;
    }
    .opbRight{
        min-width: 90%;
        align-items: flex-start;
        padding: 20px 5%;
        height: 45vh;
    }
    .stickyBtn{
        display: block;
    }
    .stickyBtnHidden{
        display: block;
    }
}