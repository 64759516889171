.mainContainer {
    width: 100%;
    background-color: white;
}

.heroContainer {
    height: 75vh;
    color: black;
    display: inline-flex;
    justify-content: center;
    width: 100%;
    align-items: center;
}

.centerContainer {
    text-align: center;
}

.centerContainer h1 {
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.centerContainer h2 {
    font-size: 48px;
}

.centerContainer h3 {
    font-weight: 400;
}

.sectionTwo {
    height: 300vh;
    background-color: whitesmoke;
}




.bgimg1,
.bgimg2,
.bgimg3 {
    position: relative;
    /* opacity: 0.65; */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

.bgimg1 {
    background-image: url("https://paytm.com/offer/assets/psa/img/home/pf-art01.svg");
    min-height: 30vh;
}

.bgimg2 {
    background-image: url("https://paytm.com/offer/assets/psa/img/home/pf-art02.svg");
    min-height: 400px;
}

.bgimg3 {
    background-image: url("https://paytm.com/offer/assets/psa/img/home/pf-art03.svg");
    min-height: 400px;
}
.bgimg1Txt{
    color: papayawhip;
    background-color: black;
    text-align: center;
    padding:50px 80px;
    text-align: justify;
}
.bgimg1Txt p{
    font-size: 28px;
    padding: 5px 15%;
}
.watchThisVideoRow{
    width: 100%;
}
.watchThisVideoBtnContainer{
    width: 100%;
    display: inline-flex;
    justify-content: center;
    padding: 20px 0px;
    cursor: pointer;
}
.playBtn{
    padding: 30px;
    background-color: #da1884;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.watchThisVideoMsg{
    text-align: center;
    font-size: 30px;
    width: 50%;
    padding: 20px 25%;
    color: #ddd;
}
.bgimg1Txt h3{
    text-align: center;
}


.caption {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    text-align: center;
    color: #000;
}

.caption span.border {
    background-color: #000;
    color: #fff;
    padding: 18px;
    font-size: 25px;
    letter-spacing: 10px;
}
.borderSpanStyle{
    background-color:transparent;
    font-size:25px;color: #f7f7f7;
}


.bgimg2TxtContainer{
    position:relative;
}
.bgimg2ParaContainer{
    color:#ddd;background-color:#282E34;
    text-align:center;padding:50px 80px;text-align: justify;
}
