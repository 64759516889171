.mainContainer{
    width: 100%;
    /* background-color: #8b979b; */
    
}
.heroContainer{
    background-image: linear-gradient(#abbcc2, #fff, #FFFFFF);
    width: 100%;
    height: 80vh;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.heroContainer h1{
    text-transform: uppercase;
    letter-spacing: 1.4px;
}
.heroContainer p{
    padding: 0px 20%;
    font-size: 20px;
    text-align: center;
}


.ourMission{
    border-top: 1px solid black;
    background-color: black;
    height: 100vh;
    position: relative;
    color: papayawhip;

}
.leftBorder{
    height: 60vh;
    position: absolute;
    top: 20vh;
    left: 7%;
    border-left: 5px solid papayawhip;
}
.ourMissionContent{
    position: absolute;
    left: 12%;
    top: 20vh;
    height: 60vh;
    justify-content: center;
    align-items: center;
    align-content: center;
    display: flex;
}
.ourMissionContentContainer{
    width: 50%;
}
.sectionalTitle{
    font-size: 16px;
    padding: 10px 0px;
    text-transform: uppercase;
    letter-spacing: 1.4px;
    font-weight: 800;
}
.sectionalSubTitle{
    font-size: 30px;
    width: 70%;
}
.sectionalDescription{
    font-size: 16px;
    font-weight: 400;
    padding: 20px 0px;
    width: 75%;
    display: flex;
}



@media only screen and (max-width: 600px) {
    .heroContainer{
        height: 100vh;
    }
    .heroContainer p{
        line-height: inherit;
        padding: 0px 5%;
        
    }
    .ourMissionContentContainer{
        width: 100%;
    }
}