.mainWrapper{
    background-color: #212121;
    width: 100%;
}
.firstPaintWrapper{
    height: 90vh;
    color: white;
}
.firstPaintContainer{
    padding: 0px 7%;
    width: 86%;
    display: flex;
    height: 90vh;
}
.fpcLeft{
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}
.fpcRight{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.iceBreaker h2{
    font-size: 3.5rem;
    font-weight: 600;
}
.subIceBreaker h3{
    font-weight: 500;
}
.videoWrapper{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.videoContainer{
    width: 70%;
    height: 100vh;
}
.videoWrapperMobile{
    display: none;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.fpcRightContainer{
    width: 100%;
    display: flex;
    align-items: right;
    justify-content: right;
}
.fpcrBox{
    border: 1px solid #424242;
    height: 60vh;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-image: url("https://stylelink.s3.ap-south-1.amazonaws.com/stylelink-logos/iPhone-11-Pro-mockup_compress.png");
    background-size: cover;
    background-repeat: no-repeat;
}
.fpcrHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10vh;
    padding: 0px 20px 0px 0px ;
}
.scanTxt{
    font-weight: 500;
}
.fpcrBody{
    height: 50vh;
}
.fpcrBody img{
    width: 100%;
}
.qrIcon{
    height: 100px;
    width: 100px;
}
.buildNowWrapper{
    background-color: #424242;
    height: 80vh;
    width: 100%;
}
.buildNowContainer{
    height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    text-align: center;
}
.buildNowHeader h3{
    font-weight: 600;
    font-size: 3.2rem;
    padding: 0px 0px 10px;
    margin: 0px;
}
.buildNowHeader h4{
    padding: 0px;
    font-weight: 600;
    margin: 0px;
    font-size: 1.3rem;
}
.buildNowBody{
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.giveUsTxt{
    font-size: 0.9rem;
    font-weight: 600;
    color: #dfdfdf;
}
.callNowBtn{
    background-color: white;
    padding: 14px;
    width: 200px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    font-weight: 600;
}


@media only screen and (max-width: 600px) {
    .firstPaintWrapper{
        height: 100svh;
    }
    .firstPaintContainer{
        flex-direction: column;
        height: 100svh;
        gap: 20px;
    }
    .iceBreaker h2{
        font-size: 2.5rem;
        font-weight: 600;
    }
    .fpcRight{
        width: 100%;
    }
    .fpcRightContainer{
        width: 100%;
    }
    .fpcrBox{
        width: 100%;
        background-image: url("https://stylelink.s3.ap-south-1.amazonaws.com/stylelink-logos/iPhone-11-Pro-mockup_compress.png");
        background-size: cover;
        

    }
    .videoWrapper{
        display: none;
    }
    .videoWrapperMobile{
        display: flex;
        height: 80vh;
        overflow: hidden;
        width: 100%;
    }
    .videoContainer{
        overflow: hidden;
        width: 100%;
        height: 80vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .videoWrapperMobile .videoContainer svg{
        transform: scale(1.8) !important;
        -webkit-transform: scale(1.8) !important;
        -moz-transform: scale(1.8) !important;
        -ms-transform: scale(1.8) !important;
        -o-transform: scale(1.8) !important;
    }
    .giveUsTxt{
        font-size: 0.8rem;
        font-weight: 500;
        color: #dfdfdf;
    }
}