.mainWrapper {
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: antiquewhite;
    font-family: 'Montserrat';
}

.headerWrapper {
    width: 100%;
    text-align: center;
}
.mainHeader h3{
    font-size: 2.5rem;
    font-weight: 600;
    text-transform: capitalize;
}
.subTitle{
    color: #00000080;
}
.subTitle h6{
    font-size: 0.9rem;
    font-weight: 600;
    padding: 10px 0px 60px;
    margin: 0px;
}
.rowWrapper{
    width: 100%;
    overflow: hidden;
}
.movingRowWrapper {
    display: flex;
    width: 100%;
    /* overflow: hidden; */
    white-space: nowrap;
    position: relative;
    height: 150px;
    animation: moveDiv 15s linear infinite;
    -webkit-animation: moveDiv 15s linear infinite;
}

.oppMovingRowWrapper {
    display: flex;
    width: 100%;
    /* overflow: hidden; */
    white-space: nowrap;
    position: relative;
    height: 150px;
    animation: moveDivOpp 15s linear infinite;
    -webkit-animation: moveDivOpp 15s linear infinite;
}

.eachBrand {
    padding: 10px 20px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eachBrand img {
    height: 50px;
}
.viewAllWrapper{
    display: flex;
    justify-content: center;
}
.viewAllBtn{
    padding: 12px 0px;
    background-color: black;
    width: 230px;
    color: white;
    text-align: center;
    font-weight: 600;
}

@keyframes moveDiv {
    0% {
        transform: translateX(90%);
        -webkit-transform: translateX(90%);
        -moz-transform: translateX(90%);
        -ms-transform: translateX(90%);
        -o-transform: translateX(90%);
}

    100% {
        transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);
}
}

@keyframes moveDivOpp {
    0% {
        transform: translateX(-80%);
        -webkit-transform: translateX(-80%);
        -moz-transform: translateX(-80%);
        -ms-transform: translateX(-80%);
        -o-transform: translateX(-80%);
}

    100% {
        transform: translateX(50%);
        -webkit-transform: translateX(50%);
        -moz-transform: translateX(50%);
        -ms-transform: translateX(50%);
        -o-transform: translateX(50%);
}
}



@media screen and (max-width: 480px) {
    .mainHeader h3{
        font-size: 1.3rem;
        justify-content: center;
    }


}