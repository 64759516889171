.eachItemWrapper {
    border: 1px solid #dfdfdfcf;
    width: calc(94% - 2px);
    align-items: center;
    background-color: white;
    padding: 10px 3%;
    margin: 10px 0px;
    cursor: pointer;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.eachItemWrapperSelected {
    border: 1px solid #da1884;
    width: calc(94% - 2px);
    align-items: center;
    background-color: white;
    padding: 10px 3%;
    margin: 10px 0px;
    cursor: pointer;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.eachItemWrapper h4 {
    padding: 0px 0px;
    margin: 0px 0px 4px 0px;
}

.eachItemWrapperSelected h4 {
    padding: 0px 0px;
    margin: 0px 0px 4px 0px;
}

.noCourseFound {
    text-align: center;
    font-weight: 600;
    height: 60vh;
    display: inline-flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.courseSmallCardFooter {
    padding: 20px 0px 5px 0px;
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}

.trendingNStats {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
}

.isTrendingSmall {
    font-size: 14px;
    color: #b9b9b9;
    padding: 0px 8px;
}

.tagsContainerEachRow {
    display: inline-flex;

}

.eachTag {
    font-weight: 600;
    color: #a5a5a5;
    font-size: .8rem;
    background-color: #f2f2f2;
    padding: 5px 8px;
    margin: 4px 4px 4px 0px;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
}

.courseShortDescription {
    font-size: 0.7rem;
    text-align: justify;
}


@media only screen and (max-width: 600px) {
    .eachCourseWrapper {
        background-color: white;
        width: 96%;
        margin: 10px 2%;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        overflow: hidden;
    }

    .courseBannerContainer {
        position: relative;
        height: 23vh;
    }

    .courseBannerImg {
        width: 100%;
        position: absolute;
        height: 23vh;
    }

    .courseBannerImg img {
        height: 100%;
    }

    .courseBannerLayer {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        background-color: #0000008c;
        height: 23vh;
    }

    .courseTitleMobile {
        padding: 4px 2% 0px 2%;
    }

    .courseTitleMobile h2 {
        padding: 0px;
        margin: 0px;
        font-size: 1rem;
    }

    .courseLevel {
        position: relative;
        color: white;
        padding: 9px 14px;
        font-weight: 700;
        background-color: #da1884;
        text-transform: uppercase;
        letter-spacing: 1.4px;
    }


    .courseDurationNLecMobile {
        padding: 5px 2% 2px 2%;
        display: inline-flex;
        justify-content: space-between;
        width: 96%;
        color: #9c9c9c;
    }

    .ratingsNPriceContainer {
        padding: 0px 2% 0px 2%;
        width: 96%;
        justify-content: space-between;
        display: inline-flex;
    }

    .descriptionContainer {
        padding: 0px 2% 12px 2%;
        color: #9c9c9c;
        font-size: 0.8rem;
    }

    .priceContainer{
        display: inline-flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    .basePrice{
        font-size: 12px;
        text-align: right;
        text-decoration: line-through;
    }
    .disPrice{
        font-size: 16px;
        font-weight: 600;
    }

}


@media only screen and (max-width: 1380px) {
    .eachTag {
        font-size: 0.6rem;
    }

    .eachItemWrapper h4 {
        padding: 0px 0px 6px;
        margin: 0px;
        font-size: 0.7rem;
    }

    .eachItemWrapperSelected h4 {
        padding: 0px 0px 6px;
        margin: 0px;
        font-size: 0.7rem;
    }

    .courseShortDescription {
        font-size: 0.6rem;
        color: black;
        opacity: 85%;
        text-align: justify;
    }

    .courseSmallCardFooter {
        padding: 10px 0px 5px 0px;
    }

    .trendingNStats {
        font-size: 10px;
    }

    .isTrendingSmall {
        font-size: 10px;
    }
}