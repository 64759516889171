.mainWrapper{
    height: 100vh;
    width: 100%;
}
.mainContainer{
    width: 100%;
    height: 100vh;
}
.mainHeader{
    width: 100%;
    height: 12vh;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.mainHeaderContainer{
    height: 12vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.upperTxt{
    text-align: center;
    font-size: 0.7rem;
    padding: 5px 0px 10px;
    font-weight: 500;
    color: #9e9e9e;
    text-transform: capitalize;
}
.searchWrapper{
    padding: 0px 30%;
}

.searchContainer{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 36px;
    -webkit-border-radius: 36px;
    -moz-border-radius: 36px;
    -ms-border-radius: 36px;
    -o-border-radius: 36px;
    display: flex;
    align-items: center;
    height: 6vh;
    padding: 3px 12px;
    gap: 10px;
    background-color: white;
}
.filterIconSmall{
    display: none;
}
.profileSearchContainer{
    flex: 4;
}
.citySearchContainer{
    flex: 4;
}
.profileSearchContainerSmall{
    flex: 4;
    display: none;
}
.citySearchContainerSmall{
    flex: 4;
    display: none;
}

.inputWrapper{
    width: 100%;
}
.inputWrapper input{
    width: calc(100% - 20px);
    padding: 12px 10px;
}
.profileSearchContainer input{
    border: none;
    border-right: 1px solid #dfdfdf;
    font-weight : 500;
}
.citySearchContainer input{
    border: none;
    font-weight : 500;
}
.citySearchContainerSmall input{
    border: none;
    border-right: 1px solid #dfdfdf;
    font-weight : 500;
}
.citySearchContainerSmall input{
    border: none;
    font-weight : 500;
}
.findJobContainer{
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.findJobContainer a{
    background-color: black;
    color: white;
    padding: 10px 20px;
    border-radius: 34px;
    -webkit-border-radius: 34px;
    -moz-border-radius: 34px;
    -ms-border-radius: 34px;
    -o-border-radius: 34px;
    font-weight: 600;
    font-size: 0.8rem;
}
.findJobContainerMobile{
    display: none;
}
.profilesWrapper{
    display: none;
}



.bodyWrapper{
    height: 88vh;
    width: 100%;
}
.bodyContainer{
    width: 90%;
    height: 88vh;
    display: flex;
    padding: 0px 5%;
}
.leftColWrapper{
    width: 17%;
    display: flex;
    justify-content: flex-start;
    /* border-right: 1px solid #dfdfdf; */
    flex-direction: column;
    padding: 0px 3% 0px 0px;
}
.filterRight{
    font-weight: 500;
    padding: 14px 0px;
    color: #9e9e9e;
}
.rightColWrapper{
    width: 80%;
}

.headerWrapper{
    display: flex;
    height: 6vh;
}
.leftHeaderWrapper{
    flex: 3;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.jobTagTitle{
    display: flex;
    justify-content: center;
    align-items: center;
}
.jobTagTitle h3{
    font-weight: 600;
    padding: 0px;
    margin: 0px;
}
.jobCount{
    display: flex;
    align-items: center;
    padding: 0px 10px;
}
.rightHeaderWrapper{
    flex: 2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.rightHeaderContainer{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}
.sortOutTag{
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 0.8rem;
    color: #6e6e6e;
}
.dropdownWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}
.dropdownWrapper select{
    padding: 6px 12px 6px 8px;
    border: 1px solid #dfdfdf;
    border-radius: 36px;
    -webkit-border-radius: 36px;
    -moz-border-radius: 36px;
    -ms-border-radius: 36px;
    -o-border-radius: 36px;
    font-size: 0.8rem;
    font-weight: 500;
    background-color: #f1f1f1;
}
.filterIconWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}
.filterIcon{
    height: 34px;
    width: 34px;
    border-radius: 32px;
    -webkit-border-radius: 32px;
    -moz-border-radius: 32px;
    -ms-border-radius: 32px;
    -o-border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dfdfdf;
    background-color: #f1f1f1;
    cursor: pointer;
}
.jobsHolder{
    display: flex;
    gap: 20px;
    row-gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    height: calc(82vh - 8px);
    overflow-y: scroll;
    padding: 4px 4px;
}


.eachJobCardWrapper{
    /* width: 31.4%; */
    flex: 1 1 calc(33.33% - 20px);
    box-sizing: border-box;
}
.eachJobCardWrapperLoader{
    width: calc(31.4% - 20px);
    border: 1px solid #dfdfdf;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
    height: 40vh;
    padding: 10px;
}
.footerWrapper{
    height: 8vh;
}

.eachSalaryRange{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.esrBtn{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.esrTxt{
    padding: 0px 0px 0px 30px;
    font-size: 0.8rem;
    font-weight: 500;
    color: #212121;
}
.thatsAllWrapper{
    flex: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
    font-weight: 500;
    color: #9e9e9e;
}

@media only screen and (max-width: 600px) {
    .mainWrapper{
        height: 100svh;
    }
    .mainContainer{
        height: 100svh;
    }
    .mainHeader{
        background-color: #f7f7f7;
        height: 13svh;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    }
    .bodyWrapper{
        height: 87svh;
    }
    .bodyContainer{
        height: 87svh;
        padding: 0px 0%;
        width: 100%;
    }
    .searchWrapper{
        padding: 0px 3%;
        display: flex;
        justify-content: space-between;
    }
    .searchContainer{
        width: 85%;
    }
    .filterIconSmall{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 15%;
    }
    .filterIconSmallBox{
        height: 40px;
        width: 40px;
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 40px;
        -webkit-border-radius: 40px;
        -moz-border-radius: 40px;
        -ms-border-radius: 40px;
        -o-border-radius: 40px;
}
    .searchContainer{
        padding: 3px 6px;
        overflow: hidden;
    }
    .profileSearchContainer{
        display: none;
    }

    .citySearchContainer{
        flex: 4;
        display: none;
    }
    .profileSearchContainerSmall{
        flex: 4;
        display: none;
    }
    .citySearchContainerSmall{
        flex: 4;
        display: block;
    }
    .eachJobCardWrapperLoader{
        width: calc(100% - 20px);
    }

    .findJobContainer{
        display: none;
    }
    .findJobContainerMobile{
        flex: 2;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .findJobContainerMobile a{
        background-color: black;
        color: white;
        height: 38px;
        width: 38px;
        border-radius: 34px;
        -webkit-border-radius: 34px;
        -moz-border-radius: 34px;
        -ms-border-radius: 34px;
        -o-border-radius: 34px;
        font-weight: 600;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .profilesWrapper{
        display: flex;
        width: 100%;
    }
    .profilesContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 5px 0px;
    }
    .eachProfileWrapper{
        width: 60px;
    }
    .eachProfileTxt{
        font-size: 0.7rem;
        text-align: center;
    }
    .leftColWrapper{
        display: none;
    }
    
    .rightColWrapper{
        width: 100%;
    }
    .headerWrapper{
        display: none;
        height: 6svh;
        background-color: #ebebeb;
        border-bottom: 1px solid #dfdfdf;
    }
    .leftHeaderWrapper{
        display: none;
    }
    .rightHeaderWrapper{
        padding: 0px 3%;
    }
    .jobsHolder{
        height: calc(85svh - 8px);
        padding: 2svh 3% 0px;
    }
    .thatsAllWrapper{
        height: 20vh;
    }
}