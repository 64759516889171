.mainWrapper{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 14px;
    flex: 1;
    border-radius: 29px;
    -webkit-border-radius: 29px;
    -moz-border-radius: 29px;
    -ms-border-radius: 29px;
    -o-border-radius: 29px;
    display: flex;
    flex-direction: column;
}
.mainWrapper:hover{
    background-color: #FEE7E5;
    /* background-color: #ffecf7; */
}
.mainContainer{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 22px;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    -ms-border-radius: 22px;
    -o-border-radius: 22px;
    padding: 12px;
    flex: 1;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.storeWrapper{
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0px 10px;
}
.storeName{
    font-weight: 500;
}
.bookmarkIconContainer{
    height: 40px;
    width: 40px;
    border-radius: 45px;
    -webkit-border-radius: 45px;
    -moz-border-radius: 45px;
    -ms-border-radius: 45px;
    -o-border-radius: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dfdfdf;
    cursor: pointer;
}
.applyNowWrapper{
    padding: 8px 0px;
    width: 100%;
}
.applyNowBtn{
    padding: 10px 0px;
    background-color: #212121;
    color: white;
    width: 100%;
    text-align: center;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
}
.mainFooter{
    text-align: center;
    font-weight: 500;
    color: #b4b4b4;
    text-transform: uppercase;
    font-size: 0.9rem;
    padding: 10px 0px 0px;
}
.jobProfile{
    font-size: 1.2rem;
    font-weight: 600;
    padding: 8px 0px;
    color: #212121;
}
.jobFeatureWrapper{
    display: flex;
    gap: 3%;
    flex-wrap: wrap;    
}
.eachJobFeature{
    width: 45%;
    display: flex;
    align-items: center;
}
.ejfIcon{
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ejfTxt{
    font-size: 0.8rem;
    font-weight: 500;
}
.ejfIconCal{
    height: 42px;
    width: 42px;
    padding: 4px;
}

@media only screen and (max-width: 600px) {
    .eachJobFeature{
        width: 100%;
    }
    .ejfIcon{
        height: 44px;
        width: 44px;
    }
    .ejfIconCal{
        height: 36px;
        width: 36px;
        padding: 4px;
    }
    
}