.mainContainer {
    width: 100%;
    height: 100vh;
    display: inline-flex;
    justify-content: space-between;
    /* background-color: #0D314C; */
    /* background-color: #101010; */
    /* background-image: linear-gradient(90deg, #101010, #515151, #00000045); */
}

.lc {
    /* border-right: 1px solid #dfdfdf; */
    width: calc(45% - 1px);
    justify-content: center;
    display: inline-flex;
    align-items: center;
    background-color: #0e0e10;
    /* background-color: #414245; */
    
}
.rc{
    width: 55%;
    background-color: black;
    position: relative;

}
.loginContainer {
    width: 55%;
    margin: 10% 5vh;
    height: 84vh;
    /*border: 1px solid #dfdfdfd0;
    */border: 1px solid #414245;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    padding: 3vh 7%;
    /*background-color: white;
    *//*background-color: #121212;
    *//*background-color: #414245;
    */background-color: #151214;
    /*box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;
    */
    
}

.lch {
    width: 100%;
    display: inline-flex;
    padding: 4%;
}

.lchl img {
    height: 16px;
    padding-right: 5px;
}

.lchr {
    color: white;
    opacity: 65%;
    font-size: 0.8rem;

}

.lcTitle {
    padding: 0px 4% 30px;
}

.lcTitle h1 {
    padding: 0px;
    margin: 0px;
    font-size: 1.5rem;
    color: white;
    opacity: 80%;
}

.lcTitle h2 {
    padding: 10px 0px;
    margin: 0px;
    font-size: 0.8rem;

    font-weight: 400;
    color: white;
    opacity: 30%;
}

.instruct {
    padding: 20px 4%;
    color: white;
    opacity: 87%;
    width: 92%;
    display: inline-flex;
    justify-content: space-between;
    font-size: 0.7rem;
    color: white;
}

.instruct button {
    background-color: transparent;
    border: none;
    color: white;
    opacity: 87%;
}

.instruct button:hover {
    color: red;
}

.fieldsContainer {
    padding: 0px 4% 20px;
    width: 92%;
}

.eachField {
    position: relative;
    width: 100%;
    border: 1px solid #dfdfdf;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
}

.eachFieldTitle {
    position: absolute;
    left: 5%;
    top: -10px;
    background-color: #121212;
    padding: 0px 8px;
    font-size: 0.9rem;
    color: white;
}

.eachFieldInput {
    overflow: hidden;
    width: 100%;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.eachFieldInputOtp {
    overflow: hidden;
    width: 100%;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.eachFieldInput input {
    width: 94%;
    padding: 12px 3%;
    border: none;
    letter-spacing: 5px;
    background-color: #121212;
    color: white;
}

.eachFieldInputOtp input {
    width: 94%;
    padding: 12px 3%;
    border: none;
    letter-spacing: 5px;
}

.eachFieldInput input:focus {
    outline: none;
}

.eachFieldInput input::placeholder {
    letter-spacing: normal;
    color: white;
    opacity: 30%;
}



.tnc {
    padding: 20px 4% 0px;
    color: rgba(255, 255, 255, 0.651);
}

.tnc label {
    font-size: 0.7rem;

}

.masterBtn {
    padding: 0px 4% 8px 4%;
    width: 92%;
}

.activeBtn {
    width: 100%;
    border: none;
    background-color: #da1884;
    color: white;
    padding: 12px;
    font-weight: 700;
    font-size: 1rem;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.disabledBtn {
    width: 100%;
    border: none;
    background-color: #da1883d8;
    color: white;
    padding: 12px;
    font-weight: 700;
    font-size: 1rem;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.trouble {
    text-align: center;
    padding: 20px 4% 0px;
    color: white;
    opacity: 87%;

}

.troubleTitle {
    font-size: 0.9rem;
    color: white;
    opacity: 87%;
}

.chatUsNow {
    /* color: #2b6b32; */
    font-weight: 600;
    color: white;
}

.callNow {
    /* color: blue; */
    font-weight: 600;
    color: white;
}

.smallCaption {
    padding: 0px 4%;
    font-size: 10px;
    color: #6e6e6e;
}

.lch {
    height: 10vh;
}

.lcb {
    height: 60vh;
}

.lcf {
    height: 10vh;
}



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.rh {
    position: fixed;
    right: 2%;
    top: 2vh;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    opacity: 87%;
    cursor: pointer;
    z-index: 2;
}

.rh span {
    padding: 0px 6px;
}

.vpContainer {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
}
.videoPlayer{
    object-fit: cover;
    filter: blur(3px);
    -webkit-filter: blur(3px);
}

.rb {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 90vh;
    display: inline-flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 5vh 0px;
    background-color: #1a1a1a73;
    width: 100%;
}

.eachContentWrapper {
    display: inline-flex;
    justify-content: flex-end;
    padding-right: 5%;
    /* width: 90%; */
}

.eachContentContainer {
    width: 80%;
}

.eachContentFeedback {
    background-color: rgba(255, 255, 255, 0.568);
    padding: 36px 7%;
    border: 2px solid rgb(209, 209, 209);
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    color: white;
    margin: 0px 0px 10px;
    
}

.eachContentFeedback h2 {
    padding: 0px;
    margin: 0px;
}

.eachContentFooterOne {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 10px 0px;
}

.eachContentFooterTwo {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.actionBtn {
    display: inline-flex;
}

.actionBtn button {
    border: 1px solid white;
    height: 40px;
    width: 40px;
    margin: 0px 0px 0px 20px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}

.personName {
    font-size: 1.4rem;
    color: white;
    font-weight: 700;
}

.profileName {
    font-size: 0.9rem;
    color: white;
    font-weight: 400;
}
.profile{
    color: white;
    opacity: 65%;
}
.profileDetails{
    color: white;
    opacity: 30%;
    font-size: 12px;
}
.twoBtns {
    width: 92%;
    padding: 0px 4% 80px;
    display: inline-flex;
    justify-content: space-between;

}

.resendOtp {
    background-color: white;
    border: 1px solid #dfdfdf;
    padding: 12px 0px;
    width: 45%;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    font-weight: 700;
    text-transform: uppercase;
}

.submitOtp {
    background-color: #da1884;
    border: 1px solid #dfdfdf;
    padding: 12px 0px;
    width: 45%;
    color: white;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    font-weight: 700;
    text-transform: uppercase;
}

.mainContainerMobile {
    display: none;

}


@media only screen and (max-width: 1280px) {
    .lchr {
        font-size: 0.84rem;
    }

    .instruct {
        font-size: 0.7rem;
    }

    .tnc {
        font-size: 0.8rem;
        padding: 0px 4% 0px;
    }

    .trouble {
        font-size: 0.8rem;
    }

    .loginContainer {
        width: 70%;
        padding: 3vh 4%;
    }

    .eachFieldTitle{
        font-size: 0.8rem;
    }
    .eachFieldInput input{
        padding: 10px 3%;
    }
    .eachFieldInput input::placeholder{
        font-size: 0.7rem;
    }
    .disabledBtn{
        font-size: 0.8rem;
        padding: 10px;
    }
    .activeBtn{
        font-size: 0.8rem;
        padding: 10px;
    }

    .twoBtns {
        padding: 0px 4% 30px;
    }


    .resendOtp {
        padding: 9px 0px;
        font-size: 0.6rem;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
    }

    .submitOtp {
        padding: 9px 0px;
        font-size: 0.6rem;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
    }
    .smallCaption{
        font-size: 8px;
    }
}

@media only screen and (max-width: 650px) {
    .mainContainer {
        display: none;
    }

    .mainContainerMobile {
        display: block;
        /* background-image: url("https://klight.co.za/wp-content/uploads/2022/07/bulbsLEDWEB.jpg"); */
        height: 100vh;
        width: 100%;
        backdrop-filter: blur(10px);
        background-color: #dfdfdf;
    }

    .first {
        display: inline-flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100vh;
        width: 100%;
    }

    .topContainer {
        height: 50vh;
        width: 100%;
    }

    .tcWrapper {
        width: calc(100% - 8px);
        height: 100%;
        background-color: #da1884;
        border: 4px solid #ffffff;
        border-bottom-left-radius: 32px;
        border-bottom-right-radius: 32px;
    }

    .tcWrapper {
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
        position: relative;
    }

    .tcWrapper video {
        object-fit: cover;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .heroTxt {
        position: absolute;
        background-color: #00000045;
        top: 0px;
        width: 100%;
        height: 100%;
        border-bottom-left-radius: 80px;
        border-bottom-right-radius: 80px;

    }

    .heroTxtContainer {
        padding: 40px 4%;
        position: relative;
        color: white;
        display: inline-flex;
        justify-content: flex-end;
        align-items: flex-end;
        height: calc(100% - 70px);
    }

    .reviewContainer {
        padding: 16px 4%;
        color: white;
        border: 2px solid white;
        border-radius: 14px;
        -webkit-border-radius: 14px;
        -moz-border-radius: 14px;
        -ms-border-radius: 14px;
        -o-border-radius: 14px;
        backdrop-filter: saturate(180%) blur(10px);
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    }


    .bcheader {
        width: 86%;
        text-align: center;
        padding: 0px 7%;
    }

    .bcbody {
        width: 100%;
    }

    .bcActions {
        padding: 30px 0px;
    }

    .bcInput {
        width: 86%;
        padding: 0px 7%;
    }

    .bcInput input {
        width: calc(94% - 2px);
        padding: 12px 3%;
        border-radius: 28px;
        -webkit-border-radius: 28px;
        -moz-border-radius: 28px;
        -ms-border-radius: 28px;
        -o-border-radius: 28px;
        border: 2px solid #c7c7c7;
        text-align: center;
        letter-spacing: 3px;
        font-weight: 800;
    }

    .bcInput input::placeholder {
        font-weight: 500;
        letter-spacing: normal;
        color: #00000045;
    }

    .sendOtp {
        width: 86%;
        padding: 10px 7%;
    }

    .activeBtn {
        border-radius: 28px;
        -webkit-border-radius: 28px;
        -moz-border-radius: 28px;
        -ms-border-radius: 28px;
        -o-border-radius: 28px;
    }

    .disabledBtn {
        border-radius: 28px;
        -webkit-border-radius: 28px;
        -moz-border-radius: 28px;
        -ms-border-radius: 28px;
        -o-border-radius: 28px;

    }


    .bcfooter {
        display: inline-flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        padding: 14px 0px;
    }

    .otpContainer {
        width: 100%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .otpContainer input {
        padding: 11px 3px;
    }

    .actionBtns {
        display: inline-flex;
        justify-content: space-between;
        width: 86%;
        padding: 20px 7% 4px;
    }

    .actionBtns button {
        padding: 10px;
        border: none;
        width: 40%;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        text-transform: uppercase;
        letter-spacing: 1.3px;
        font-weight: 700;
    }

    .resendOtpBtn {
        background-color: black;
        color: white;
    }

    .submitOtpBtn {
        background-color: #da1884;
        color: white;
    }

    .smsSentMessage {
        display: inline-flex;
        width: 84%;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
        padding: 0px 7%;
    }

    .smsSentMessage button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        color: blue;
        font-weight: 600;
    }

    .navigationHeader {
        position: fixed;
        top: 0px;
        left: 0px;
        color: white;
        z-index: 44;
        width: 100%;

    }

    .backBtnContainer {
        width: 92%;
        position: relative;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        padding: 14px 4%;
        color: white;
    }

}


@media only screen and (max-width: 650px) {
    .smsSentMessage {
        font-size: 0.7rem;
    }
}