.mainWrapper {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1900;
    background-color: #00000081;
}

.mainContainer {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
}

.mainContent {
    height: 40vh;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: space-between;
}

.mainHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: 1px solid #4cd4f7;
    padding: 0px 12px;
    background-color: white;
    gap: 10px;
    border-radius: 45px;
    -webkit-border-radius: 45px;
    -moz-border-radius: 45px;
    -ms-border-radius: 45px;
    -o-border-radius: 45px;
    animation: visiblityUp 0.4s ease;
    -webkit-animation: visiblityUp 0.4s ease;
}

.citiesWrapper {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: white;
    overflow-y: scroll;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    animation: appear 0.2s;
    -webkit-animation: appear 0.2s;
}

.eachCity {
    padding: 10px 2%;
    border-bottom: 1px solid #dfdfdf8f;
    cursor: pointer;
    font-weight: 500;
    color: #00000081;
}

.eachCity:hover {
    background-color: #dfdfdf6c;
}

.profileInputContainer {
    flex: 0.5;
    border-right: 1px solid transparent;
    display: flex;
    align-items: center;
}

.profileInputContainer input {
    width: calc(100% - 20px);
    padding: 10px 10px;
    border: none;
    font-weight: 600;
}

.profileInputContainer input::placeholder {
    font-weight: 500;
}


.cityInputContainer {
    flex: 5.5;
    display: flex;
    align-items: center;
}

.cityInputContainer input {
    width: calc(100% - 20px);
    padding: 10px 10px;
    border: none;
    font-weight: 600;
}

.cityInputContainer input::placeholder {
    font-weight: 500;
}

.searchBtnContainer {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.searchBtnContainer a {
    background-color: #212121;
    color: white;
    padding: 12px 26px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    cursor: pointer;
    font-weight: 500;
    font-weight: 0.9rem;
}

@keyframes appear {
    0% {
        transform: scaleY(0);
        -webkit-transform: scaleY(0);
        -moz-transform: scaleY(0);
        -ms-transform: scaleY(0);
        -o-transform: scaleY(0);
    }

    100% {
        transform: scaleY(1);
        -webkit-transform: scaleY(1);
        -moz-transform: scaleY(1);
        -ms-transform: scaleY(1);
        -o-transform: scaleY(1);
    }
}

@keyframes visiblityUp {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}



@media only screen and (max-width: 600px) {
    .mainContent {
        top: 0px;
        margin-top: 0px !important;
        height: 100svh;
        width: 100% !important;
        row-gap: 0px !important;
    }

    .mainHeader {
        height: 8svh !important;
        border-radius: 0px !important;
        -webkit-border-radius: 0px !important;
        -moz-border-radius: 0px !important;
        -ms-border-radius: 0px !important;
        -o-border-radius: 0px !important;
        border: none;
        border-bottom: 1px solid #4cd4f7;
        background-color: #f0f0f0;
    }

    .citiesWrapper {
        height: calc(92svh - 0px) !important;
        width: 100%;
        border-radius: 0px !important;
        -webkit-border-radius: 0px !important;
        -moz-border-radius: 0px !important;
        -ms-border-radius: 0px !important;
        -o-border-radius: 0px !important;
    }
    .cityInputContainer input{
        background-color: transparent;
    }
    .searchBtnContainer{
        flex: 4;
    }

}