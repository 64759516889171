.mainContainer {
    margin-top: -25px;
}
.heroContainer {
    height: 100vh;
    position: relative;
    background-color: #F7F7F7;
}

.mainTxt {
    position: absolute;
    height: 100vh;
    text-align: center;
    width: 100%;
    align-items: center;
    align-content: center;
    display: inline-flex;
    flex-direction: column;
    background-color: #F7F7F7;
    color: black;
}

.mainTxtContainer {
    width: 100%;
    padding: 10vh 0px 0px 0px;
}

.mainTxtContainer h2 {
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1.8px;
    line-height: 1.4em;
    font-size: 54px;

    animation-name: showMainTxt;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
}

@keyframes showMainTxt {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes showSubTxt {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.subTxtContainer {
    width: 100%;
}

.subTxtContainer h4 {
    font-weight: 300;
    font-size: 20px;
    animation: showSubTxt 1s linear 4;
    -webkit-animation: showSubTxt 1s linear 4;
}

.uspContainer {
    position: absolute;
    left: 7%;
    bottom: 10vh;
    font-size: 24px;
}

.uspContainer button {
    border: none;
    background-color: transparent;
    color: #686868;
    font-size: 20px;
    margin: 0px 15px 0px 0px;
    font-weight: 600;
    text-transform: uppercase;


}

.border-bottom {
    border-bottom: 2px solid #da1884;
}

.uspContainer button:hover {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
}


.videoPlayer {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    opacity: 1;
}


.visionSection {
    height: 100vh;
    background-color: black;
    position: relative;
    color: papayawhip;
}

.leftBorder {
    position: absolute;
    width: 5px;
    height: 60vh;
    background: white;
    left: 7%;
    top: 20vh;
}

.visionContent {
    position: absolute;
    left: 12%;
    top: 20vh;
    height: 60vh;
    justify-content: center;
    align-items: center;
    align-content: center;
    display: flex;
}

.visionContentContainer {
    width: 50%;
}

.sectionalTitle {
    font-size: 16px;
    padding: 10px 0px;
    text-transform: uppercase;
    letter-spacing: 1.4px;
    font-weight: 800;
}

.sectionalSubTitle {
    font-size: 30px;
    width: 70%;
}

.sectionalDescription {
    font-size: 16px;
    font-weight: 400;
    padding: 20px 0px;
    width: 75%;
    display: flex;
}

.visionImageContainer {
    height: 100vh;
    width: 43%;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 7%;
}

.visionImageContainer img {
    height: 50vh;
}

.visionImageContainerCollapsible {
    display: none;
}



.servicesSection {
    position: relative;
    background-color: white;
    color: black;
    height: 200vh;
}

.leftBorderAlt {
    position: absolute;
    width: 5px;
    height: 10vh;
    background: black;
    left: 7%;
    top: 15vh;
}

.servicesSectionHeader {
    height: 40vh;
    width: 100%;
    padding-left: 12%;
}

.servicesHeaderContent {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.servicesContentWrap {
    width: 100%;
}

.sectionalTitle {
    font-size: 16px;
    padding: 10px 0px;
    text-transform: uppercase;
    letter-spacing: 1.4px;
    font-weight: 800;
}

.servicesContent {
    padding-left: 12%;
    height: 160vh;
    align-items: center;
    align-content: center;
    display: flex;
    width: 76%;
}

.servicesContentWrap {
    width: 100%;
}

.ServicesSectionalDescription {
    font-size: 16px;
    font-weight: 400;
    padding: 20px 0px;
    width: 100%;
    justify-content: space-between;
    color: black;
}

.eachSectionalDescription {
    justify-content: space-between;
    width: 100%;
    display: flex;
    height: 40vh;
    margin-bottom: 10vh;
}

.eachCol {
    width: 50%;
    display: flex;
    align-items: center;
}

.eachSectionIcon {
    width: 100%;
    height: 40vh;
}

.eachSectionIcon img {
    width: 60%;
    padding: 0% 15%;
}

.eachSectionIconConnect {
    width: 100%;
    height: 40vh;
}

.eachSectionIconConnect img {
    width: 60%;
    padding: 0% 15%;
}

.eachSectionIconEarn {
    width: 100%;
    height: 40vh;
}

.eachSectionIconEarn img {
    width: 60%;
    padding: 0% 15% 0% 0%;
}

.readMoreBtn {
    border: 1px solid #c0bebe;
    padding: 0px 10px;
    margin: 10px 0px;
    border-radius: 7px;
    cursor: pointer;
    font-weight: 700;
    background: white;
    display: flex;
    justify-content: space-between;
    width: 40%;
}

.readMoreTxt {
    padding: 10px;
}

.readMoreBtn img {
    width: 20px;
}

.rmIcon {
    border-left: 1px solid #c0bebe;
    justify-content: center;
    align-items: center;
    display: flex;
}

.rmIcon img {
    padding: 10px;
}

.servicesContentCollipsible {
    display: none;
}

.eachSectionalDescriTitle {
    font-weight: 700;
    font-size: 28px;
    text-transform: uppercase;
    letter-spacing: 1.4px;
}




.appPromoSection {
    width: 100%;

    background-image: url(https://images.unsplash.com/photo-1560066984-138dadb4c035);
    min-height: 100vh;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.floatingContainer {
    margin-top: 10vh;
    margin-bottom: 10vh;
    left: 10%;
    position: absolute;
    background-color: black;
    height: 80vh;
    width: 30%;
    box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;
    border-radius: 15px;
}

.floatingContent {
    position: relative;
    color: white;
}

.leftBorderInFloating {
    position: absolute;
    width: 5px;
    height: 60vh;
    background: white;
    left: 7%;
    top: 10vh;
}

.floatingContentWrapper {
    position: absolute;
    left: 14%;
    width: 80%;
    height: 80vh;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
}

.floatingTitle {
    text-transform: uppercase;
    letter-spacing: 1.4px;
    font-weight: 700;
    font-size: 28px;
}

.eachSectionalDescriDetails {
    padding: 20px 0px;
}







.ourIdea {
    height: 100vh;
    background-color: black;
    color: white;
}

.mainTxtMobile {
    display: none;
}



@media only screen and (max-width: 600px) {
    .visionSection {
        height: 120vh;
    }

    .visionContent {
        position: absolute;
        left: 12%;
        top: 20vh;
        height: 60vh;
        justify-content: center;
        align-items: center;
        align-content: center;
        display: flex;

        flex-direction: column;
        width: 80%;
    }

    .visionContentContainer {
        width: 100%;
    }

    .sectionalSubTitle {
        width: 100%;
        font-size: 24px;
    }

    .sectionalDescription {
        width: 100%;
    }

    .visionImageContainer {
        display: none;
    }

    .visionImageContainerCollapsible {
        display: flex;
        height: 50vh;
        margin-top: 80vh;
        width: 100%;
        position: absolute;
        justify-content: center;
    }

    .visionImageContainerCollapsible img {
        height: 40vh;
    }


    .servicesContent {
        display: none;
    }

    .servicesContentCollipsible {
        display: inline-block;
    }

    .servicesSectionHeader {
        height: 20vh;
        width: 88%;
        padding-left: 12%;
    }

    .leftBorderAlt {
        top: 5.5vh;
    }

    .eachSectionalDescription {
        justify-content: space-between;
        width: 76%;
        padding: 0px 12%;
        display: flex;
        flex-direction: column;
        height: auto;
    }

    .eachSectionalDescriDetails {
        padding: 0px 0px;
    }

    .eachCol {
        width: 100%;
    }

    .eachSectionIcon {
        height: auto;
    }

    .eachSectionIcon img {
        padding: 40px 20%;
        width: 60%;
    }

    .eachSectionIconConnect {
        height: auto;
    }

    .eachSectionIconConnect img {
        padding: 40px 10%;
        width: 80%;
    }

    .readMoreBtn {
        width: 75%;
    }

    .readMoreTxt {
        padding: 15px;
    }

    .servicesSection {
        height: auto;
    }

    .floatingContainer {
        width: 90%;
        left: 5%;
    }

    .uspMobileWrapper {
        width: 100%;
        text-align: center;
    }

    .mainTxt {
        display: none;
    }

    .mainTxtMobile {
        position: absolute;
        height: 100vh;
        text-align: center;
        width: 100%;
        justify-content: space-around;
        align-items: center;
        align-content: space-between;
        display: inline-flex;
        background-color: #F7F7F7;
        flex-direction: column;
        color: black;

    }

    .mainTxtMobile h2 {
        letter-spacing: 1.7px;
        font-weight: 600;
        line-height: 2rem;
    }

    .mainTxtMobile h4 {
        font-weight: 500;
        padding: 0px 15%;
        font-style: italic;
        font-size: 0.8rem;
    }

    .uspMobileContainer {
        padding: 20px 0px;

    }

    .eachUspContainer:nth-child(1) {
        padding: 0px 1%;
        font-size: 1.4rem;
        font-weight: 600;
        overflow: hidden;
        white-space: nowrap;

        animation: revealSubLine 2.5s steps(40, end), blink-caret .75s step-end infinite;
        -webkit-animation: revealSubLine 2.5s steps(40, end), blink-caret .75s step-end infinite;
    }

    .eachUspContainer:nth-child(2) {
        padding: 0px 1%;
        font-size: 1.4rem;
        font-weight: 600;
        overflow: hidden;
        white-space: nowrap;
        animation-delay: 3.5s;
        animation: revealSubLine 2.5s steps(40, end) 3.5s, blink-caret .75s step-end infinite;
        -webkit-animation: revealSubLine 2.5s steps(40, end) 3.5s, blink-caret .75s step-end infinite;
    }

    .eachUspContainer:nth-child(3) {
        padding: 0px 1%;
        font-size: 1.4rem;
        font-weight: 600;
        overflow: hidden;
        white-space: nowrap;
        animation-delay: 3.5s;
        animation: revealSubLine 2.5s steps(40, end) 7s, blink-caret .75s step-end infinite;
        -webkit-animation: revealSubLine 2.5s steps(40, end) 7s, blink-caret .75s step-end infinite;
    }

    .eachUspContainer:nth-child(4) {
        padding: 0px 1%;
        font-size: 1.4rem;
        font-weight: 600;
        overflow: hidden;
        white-space: nowrap;
        animation-delay: 3.5s;
        animation: revealSubLine 2.5s steps(40, end) 10.5s, blink-caret .75s step-end infinite;
        -webkit-animation: revealSubLine 2.5s steps(40, end) 10.5s, blink-caret .75s step-end infinite;
    }

    .mainTxtMobileContainer h2 {
        text-transform: lowercase;
        font-style: italic;
        letter-spacing: 1px;
        animation-name: revealMainLine;
        animation-duration: 2s;
        /* animation-timing-function: ease; */
    }



    @keyframes blink-caret {

        from,
        to {
            border-color: transparent
        }

        50% {
            border-color: orange;
        }
    }

    @keyframes revealMainLine {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes revealSubLine {
        0% {
            width: 0%;
            opacity: 0;
            color: white;
        }

        100% {
            width: 100%;
            opacity: 1;
            color: #da1884;
        }
    }

    @keyframes revealUsp {
        0% {
            width: 0%;
            right: 100px;
        }

        100% {
            width: 100%;
            right: 0px;
        }
    }
}