.mainWrapper{
    width: 100%;
    height: 50vh;
    background-color: #101010;
    position: relative;
    color: white;
}
.mainContainer{
    padding: 0px 7%;
    width: 84%;
    border-top: 2px solid #696969;
}
.mainTitle{
    width: 100%;
    padding: 40px 0px;
    font-size: 1.4rem;
    font-weight: 600;
}
.eachQuery{
    padding: 10px 0px;
}
.queryQuestion{
    color: #EDEDED;
    font-weight: 400;
    font-size: 0.8rem;
}
.queryQuestion h2{
    display: flex;
    align-items: center;
}
.queryAnswer{
    color: #C6C6C6;
}
.queryAnswer p{
    font-weight: 600;
}