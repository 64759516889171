@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
html{
  scroll-behavior: smooth;
  font-family: 'Montserrat' !important;
}

body {
  margin: 0;
    font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  overflow-x: hidden;
}
input, button, span, a, select{
  font-family: 'Montserrat' !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.no-scroll{
  overflow-y: hidden !important;
  height: 100vh !important;
}


.timer {
  animation: slideBottomToTop 0.5s forwards;
  -webkit-animation: slideBottomToTop 0.5s forwards;
}



@-webkit-keyframes slideBottomToTop {
  0% { top : 100vh }
  100% { top: 0vh; }
}
@keyframes slideBottomToTop {
  0% {
    top: 100vh
  }
  100% { top: 0; }
}



