.mainContainer{
    width: 100%;
}
.userLoginCard {
    width: 100%;
    background-color: white;
    border: 1px solid #dfdfdfcf;
    padding: 30px 0px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;

    margin-bottom: 14px;
}

.personProfilePic {
    display: inline-flex;
    width: 100%;
    justify-content: center;
}

.personProfilePic img {
    width: 80px;
    height: 80px;
}

.personDetails {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

.editProfileBtn {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.editProfileBtn button {
    width: 80%;
    border: 1px solid #dfdfdfcf;
    padding: 10px 0px;
    cursor: pointer;
    font-weight: 600;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}


@media only screen and (max-width: 1380px) {
    .personDetails h4{
        font-size: 0.8rem;
    }

    .editProfileBtn button{
        padding: 8px 0px;
    }
    .personDetails p{
        font-size: 0.8rem;
    }
    
}