.mainWrapper{
    background-color: #EFF1EF;
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
input, button, span{
    font-family: 'Montserrat';
}
.mainContainer{
    padding: 2%;
    background-color: white;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
    width: 82%;
    
}

.upperContainer{
    height: 55vh;
    padding: 0px 4%;
}


.firstLineWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 0px 4px;
}
.registerTxt h2{
    font-weight: 600;
    padding: 0px 0px;
    margin: 0px;
}
.forWorkshopTxt p{
    font-weight: 500;
    padding: 0px;
    margin: 0px;
    color: #0000007a;
}
.threeLineDivider{
    display: flex;
    gap: 10px;
    justify-content: center;
    padding: 15px 0px;
}
.eachLine{
    height: 5px;
    background-color: #da1884;
    width: 30%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.fieldsWrapper{
    padding: 30px 0px 0px;

}


.phoneNumberWrapper{
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding: 10px 0px;
    background-color: #dfdfdf20;
    width: calc(100% - 2px);
}
.phoneCaption{
    padding: 0px 10px;
    font-weight: 500;
    color: #0000007a;
    font-size: 0.8rem;
}
.phoneInput input{
    padding: 8px 10px;
    width: calc(100% - 20px);
    border: none;
    background-color: transparent;
    font-size: 1.1rem;
    font-weight: 500;
    letter-spacing: 1.2px;
}
.phoneInput input::placeholder{
    font-size: 0.8rem;
    font-weight: 400;
    letter-spacing: 0.1px;
}

.registerNowBtn{
    box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;

}
.promoTxtWrapper{
    padding: 10px 0px 0px;
}
.limitedSpotTxt{
    font-size: 0.8rem;
    font-weight: 500;
    color: #00000071;
    text-align: center;
}
.otpSentTxt{
    font-size: 0.8rem;
    font-weight: 500;
    color: #00000071;
    text-align: center;
}

.changePhoneWrapper{
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
}
.sentOnNumber{
    font-size: 0.9rem;
    color: #00000091;
    font-weight: 500;
    letter-spacing: 0.8px;
}
.registerBtnWrapper{
    padding: 25px 0px 20px;
}
.changePhoneTxt{
    font-size: 0.9rem;
    color: #0000007a;
    font-weight: 500;
}
.registerBtnDull{
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 1px solid #da1884;
    background-color: #F8CAE1;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    color: #da1884;
    font-weight: 500;
    font-size: 1rem;
}
.registerBtnActive{
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 1px solid #da1884;
    background-color: #da1884;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    color: white;
    font-weight: 500;
    font-size: 1rem;
}


.pleaseEnterOtp{
    text-align: center;
    font-weight: 500;
    padding: 10px 0px;
    margin: 0px 0px 10px;
    font-size: 1.2rem;
}

.otpContainer{
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.otpContainer input{
    padding: 5px;
    height: 40px;
    min-width: 40px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}


.resendWrapper{
    padding: 40px 0px 0px;
}
.haventReceivedTxt{
    font-weight: 600;
    color: #da1884;
    text-align: center;
}
.resendOtpTimer{
    text-align: center;
    font-size: 0.8rem;
    padding: 7px 0px;
    color: #00000071;
}
.resendOtp{
    text-align: center;
    padding: 10px 0px 10px;
    font-weight: 500;
}