.mainHeaderWrapper {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: black;
}

.leftHeaderWrapper {
    flex: 1;
    display: inline-flex;
    justify-content: flex-start;
}

.centerHeaderWrapper {
    flex: 3;
}

.rightHeaderWrapper {
    flex: 1;
}

.logo {
    width: 140px;
    padding: 0px 0px 0px 20%;
}


.navOptionsContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 25px;
}
.rightHeaderWrapper .navOptionsContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0px 2% 0px 0px;
}
.navOptionClass {
    font-weight: 500;
}

.navOptionsContainerCollapsible {
    display: none;
}

.eachNavOptionWhite {
    padding: 0px 2%;
    text-decoration: none;
}

.eachNavOptionBlack {
    padding: 0px 2%;
    text-decoration: none;
    color: black;
}

.signUpBtn {
    text-decoration: none;
    margin: 0px 2% 0px 0px;
    padding: 5px 20px !important;
    border: 1px solid black;
    background-color: black;
    color: white;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: pointer;
}

.signUpBtnHome {
    text-decoration: none;
    margin: 0px 2% 0px 0px;
    padding: 5px 20px !important;
    border: 1px solid black;
    background-color: white;
    color: black;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
}


.mobileNavOptions {
    display: none;
}
.forSalonBtn{
    font-weight: 600;
    border: 1px solid #dfdfdf;
    background-color: #e0e0e04a;
    padding: 9px 20px;
    font-size: 0.8rem;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    margin: 0px 5% 0px 0px;
}


@media only screen and (max-width: 600px) {
    .mainHeaderWrapper {
        background-color: white;
        padding: 12px 0px;
        box-shadow: rgba(0, 0, 0, 0.082) 0px 0px 0px 1px;
    }

    .leftHeaderWrapper {
        width: 50%;
    }

    .centerHeaderWrapper {
        display: none;
    }

    .logo {
        width: 120px;
        padding: 0px 0px 0px 10%;
    }

    .rightHeaderWrapper {
        width: 50%;
    }

    .navOptionsContainer {
        display: none;
    }
    .rightHeaderWrapper .navOptionsContainer{
        display: none;
    }

    .navOptionsContainerCollapsible {
        display: inline-flex;
        width: 100%;
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding-right: 10%;
    }
    .forSalonBtn{
        margin: 0px;
    }
    .burgerMenu {
        padding: 0px 10px;
    }

    .mobileNavOptions {
        display: inline-block;
    }
}



@media only screen and (max-width: 1380px) {
    .logo {
        width: 120px;
    }
}