.eachWrapper{
    width: calc(96% - 2px);
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    padding: 10px 1.5%;
    margin: 0px 0px 14px;
    font-family: 'Montserrat';
    cursor: pointer;
}
.questionRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.questionRow h4{
    font-weight: 600;
    margin: 0px;
    color: #00000090;
}
.questionRow button{
    border: none;
    background-color: transparent;
    padding: 0px 0px 0px;
}
.answer{
    text-align: justify;
    color: #00000067;
    padding: 6px 0px 0px 0px;
    font-weight: 500;
    font-size: 0.9rem;
}

@media screen and (max-width: 480px) {
    .eachWrapper{
        width: calc(94% - 2px);
        padding: 10px 3%;
    }
}
