.mainWrapper{
    width: 100%;
    height: 45vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.timerWrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.hrsMinWrapper{
    display: flex;
    font-size: 15px;
    align-items: center;
}
.hourContainer{
    font-size: 105px;
}
.hourContainer h2{
    padding: 0px 0px;
    margin: 0px 0px;
}
.minutesContainer{
    font-size: 1rem;
}
.minutesContainer h2{
    padding: 0px;
    margin: 0px;
}
.secondsWrapper{
    display: flex;
    font-size: 10px;
}

.workshopEnded{
    font-weight: 600;
    padding: 10px 0px;
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.767);
}
.soonBeAble{
    font-weight: 600;
    padding: 10px 0px;
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.767);
}
.getNowBtn{
    color: white;
}
.getNowTxt{
    color: white;
}