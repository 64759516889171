.mainContainer{
    width: 100%;
}
.mainBody{
    width: 100%;
}
.mainBodyContainer{
    width: 86%;
    display: flex;
    padding: 12vh 7%;
    justify-content: space-between;
    /* border: 1px solid #dfdfdf; */
}
.leftContainer{
    width: 60%;
    overflow: hidden;
    /* background-color: #e9e8e8; */
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.rightContainer{
    width: 40%;
    display: flex;
    justify-content: flex-end;
}

.jobDetailsContainer{
    width: 100%;
    background-color: #e9e8e8;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    padding-bottom: 16px;
}
.bannerImageContainer{
    width: 100%;

    background: linear-gradient(to right, #FF00FF, #ebb3bd); /* Change colors here */
    height: 10vh;
    margin: 0;
}
.bannerImageContainer img{
    width: 100%;
    height: 100%;
}

.profileLogoContainer{
    width: 92%;
    background-color: aliceblue;
    padding: 0px 4%;
}
.profileLogo{
    position: absolute;
    border: 4px solid white;
    margin-top: -30px;
    background-color: coral;
    height: 50px;
    width: 50px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.profileLogoIcon{
    font-size: 24px;
    font-weight: 700;
}

.headerContainer{
    width: 100%;
    padding: 0px 0px 20px 0px;
}
.profileNActionContainer{
    width: 92%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 4% 3px;
}
.profileContainer{
    width: 75%;
}
.profileContainer h1{
    padding: 0px;
    margin: 0px;
    font-size: 1.4rem;
}
.actionContainer{
    width: 25%;
    display: inline-flex;
    justify-content: flex-end;
}
.eachActionIcon{
    height: 30px;
    width: 30px;
    border: 2px solid #dfdfdf;
    margin: 0px 0px 0px 7px;
    background-color: white;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.locationTimelineContainer{
    width: 92%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 4%;
    font-size: 0.9rem;
}

.eligiblityContainer{
    width: calc(92% - 2px);
    border: 1px solid #c4c4c4;
    margin: 0px 4% 10px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    display: inline-flex;
    justify-content: space-between;
    flex-direction: row;
    overflow: hidden;
}

.eachElContainer:nth-child(2){
    border-right: 1px solid #c4c4c4;
    border-left: 1px solid #c4c4c4;
}

.eachElContainer{
    width: 31%;
    padding: 14px 1.5%;
    background-color: white;
}
.eachElLabel{
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1.2px;
    color: #323232;
    font-size: 0.8rem;
}
.eachElVal{
    font-size: 1.1rem;
    padding: 8px 0px 0px;
    color: #252525;
}

.jobDescription{
    width: calc(92% - 2px);
    padding: 0px 4%;
}
.skillsContainer{
    width: calc(92% - 2px);
    padding: 0px 4%;
    display: inline-flex;
    flex-wrap: wrap;
}
.eachSkill{
    background-color: #c9c9c9;
    padding: 6px 14px;
    margin: 4px 8px 4px 0px;
    font-size: 0.9rem;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.loaderContainer{
    padding: 60px 7%;
}
.loaderSkillsContainer{
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    margin: 10px 0px 20px;
}
.eachSkillBadge{
    margin: 0px 10px -10px 0px;
}
.loaderEligiblityContainer{
    width: calc(100% - 2px);
    border: 1px solid #eeeeee;
    margin: 0px 0% 10px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    display: inline-flex;
    justify-content: space-between;
}
.loaderEachElContainer:nth-child(2){
    border-right: 1px solid #eeeeee;
    border-left: 1px solid #eeeeee;
}
.loaderEachElContainer{
    width: 30%;
    padding: 14px 1.5% 0px;
}


.bewareContainer{
    color: white;
    width: calc(92% - 2px);
    margin: 0px 4% 10px;
    background-color: blue;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    /* background-image: url("https://res.cloudinary.com/iamironman/image/upload/v1660397026/card-backgrounds/painted-background-multicoloured-palette_23-2148427592_tgcjor.webp");
    background-size: cover;
    background-repeat: no-repeat; */
    position: relative;
    overflow: hidden;

    background: linear-gradient(to right, hotpink, orange);
    position: relative;
}
.bewareContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, transparent 40%, orange 60%);
    transform: skewX(-20deg);
}
.bewareBlackLayer{
    position: absolute;
    background-color: rgb(0 0 0 / 35%);
    height: 100%;
    width: 100%;
}
.bewareTitle{
    position: relative;
    padding: 10px 2%;
    color: white;
    text-align: center;
}
.bewareTitle h3{
    font-size: 1.4rem;
    font-weight: 700;
    margin: 0px auto;
    padding: 0px auto;
}
.fnaContainer{
    width: calc(92% - 2px);
    margin: 0px 4% 10px;
}
.fnaBox{
    border-left: 4px solid #31A3BA;
    background-color: #CFEAF0;
    max-width: fit-content;
    padding: 6px 1%;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.fnaTitle{
    font-size: 0.9rem;
    font-weight: 800;
}
.fnaValue{
    font-size: 0.8rem;
}


.rightHolder{
    position: fixed;
    width: 27%;
    /* width: auto; */
    margin-left: 3%;
}
.rightHc{
    width: 100%;
    padding: 0px;
    
    height: 60vh;

    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;

}
.rhcEc{
    margin: 0px 0px 10px 0px;
}

.rightContentCol{
    position: absolute;
    width: 27%;
    background-color: white;
    /* border: 1px solid #dfdfdf; */
    margin-left: 3%;
    
}
.rightContentColFixed{
    /* right: 10%; */
    position: fixed;
    /* top: 80px; */
    width: 27%;
    margin-left: 3%;
    background-color: white;
    /* border: 1px solid #dfdfdf; */
    
}





@media only screen and (max-width: 600px) {
    .mainBodyContainer{
        display: flex;
        flex-direction: column;
        width: 92%;
        padding: 12vh 4%;
    }
    .leftContainer{
        width: 100%;
    }
    .eligiblityContainer{
        flex-direction: column;
        background-color: white;
    }
    .eachElContainer{
        width: 92%;
        padding: 8px 4%;
    }
    .rightContainer{
        width: 100%;
    }
    .eachElContainer:nth-child(2){
        border-right: none;
        border-left: none;
        border-top: 1px solid #c4c4c4;
        border-bottom: 1px solid #c4c4c4;
    }
    .rightHolder{
        position: relative;
        width: 100%;
        margin-left: 0px;
    }

    .rightContentCol{
        position: relative;
        width: 100%;
        background-color: white;
        margin: 0px !important;
    }
    .rightHc{
        height: auto;
    }
}


@media only screen and (max-width: 1380px) {
    .locationTimelineContainer{
        font-size: 0.7rem;
    }
    .eachElVal{
        font-size: 0.7rem;
        padding: 2px 0px 0px;
    }
    .eachElContainer{
        padding: 8px 1.5%;
    }
    .fnaTitle{
        font-size: 0.7rem;
    }
    .fnaValue{
        font-size: 0.6rem;
    }
    .bewareTitle{
        padding: 8px 2%;
    }
    .bewareTitle h3{
        font-size: 0.7rem;
    }
    .bewareTitle p{
        font-size: 0.7rem;
    }
    .eachSkill{
        font-size: 0.7rem;
    }
    .jobDescription{
        font-size: 0.8rem;
    }

}