.mainWrapper{
    height: 90vh;
    background-color: #000000;
    position: relative;
    width: 100%;
}
.mainContainer{
    width: 100%;
    height: 90vh;
    justify-content: space-between;
    align-items: center;
    display: flex;
}
.leftSection{
    height: 90vh;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 0px 7%;
}
.rightSection{
    height: 90vh;
    width: 50%;
    color: white;
}
.mainTitle{
    color: #ffffff;
}
.mainTitle h3{
    font-weight: 700;
    padding: 0px 0px;
    margin: 0px 0px;
    color: #eeeeee;
    font-size: 105px;
}
.mainTitle h4{
    font-size: 1.2rem;
    font-weight: 500;
    padding: 0px 0px;
    margin: 0px;
    color: #ffffff85;
    letter-spacing: 1.2px;
}

.trustedWrapper{
    width: 100%;
    padding: 30px 0px;
}

.trustedContainer{
    background-color: #FFFFFF;
    color: #414141;
    padding: 14px 2%;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    width: 60%;
    font-weight: 600;
}
.solidfyingSection{
    font-size: 1.4rem;
    font-weight: 700;
    color: white;
    text-transform: lowercase;
}


@media screen and (max-width: 640px) {
    .mainContainer{
        flex-direction: column;
    }
    .leftSection{
        width: 86%;
        height: 45vh;
    }
    .rightSection{
        height: 45vh;
        width: 100%;
    }
    .trustedContainer{
        width: 96%;
    }
    .mainTitle h3{
        font-size: 2rem;
    }
    .mainTitle h4{
        color: #ffffffd9;
        font-weight: 600;
        text-align: right;
    }
    .trustedWrapper{
        padding: 30px 0px 15px;
    }
    .solidfyingSection{
        color: #ffffffde;
    }
}
