.mainContainer{
    width: 100%;
}
.mainContent{
    width: 100%;
    
}
.courseBanner{
    padding: 10vh 10%;
    background-color: black;
    height: 26vh;
    /* border: 1px solid green; */
}
.courseBannerImg {
    width: 100%;
}
.courseBannerImg img{
    width: 100%;
    
}
.mainBody{
    /* margin: -10vh 0px 0px 0px; */
    margin: -20vh 10% 0px 10%;
    /* padding: 0vh 10% 10vh; */
    display: inline-flex;
    width: 80%;
    /* border: 1px solid red; */
    justify-content: space-between;
}

.leftContentCol{
    width: 60%;
    /* border: 1px solid #dfdfdf; */
    background-color: white;
    /* display: inline-flex; */
    position: relative;
}
.rightContentCol{
    /* position: relative; */
    width: 30%;
    background-color: white;
    border: 1px solid #dfdfdf;
    margin-left: 10%;
}
.rightContentColFixed{
    right: 10%;
    position: fixed;
    top: 80px;
    width: 24%;
    background-color: white;
    border: 1px solid #dfdfdf;
    
}
.courseTitle{
    padding: 0px 3%;
}
.courseTitleFixed{
    background-color: black;
    position: fixed;
    top: 0px;
    width: 92%;
    padding: 0px 4%;
    left: 0px;
    color: white;
}
.coursePriceBox{
    padding: 10px 7%;
    text-align: center;
}
.courseOrgPrice{
    font-size: 12px;
    text-decoration: line-through;
    color: black;
    opacity: 65%;
}
.courseDisPrice{
    font-size: 20px;
    color: black;
    font-weight: 600;
}
.courseBuyNow{
    padding: 10px 7%;
    text-align: center;
}
.courseBuyNow button{
    width: 100%;
    padding: 8px 0px;
    letter-spacing: 1.1px;
    font-weight: 500;
    font-size: 1.1rem;
    cursor: pointer;
}
.courseBuyNow p{
    padding: 6px 0px 0px 0px;
    margin: 0px;
    font-size: 0.8rem;
    color: #646464;
}
.thisCourseIncludes{
    border-top: 1px solid #dfdfdf;
    padding: 10px 7%;
}
.thisCourseIncludes h4{
    margin: 0px;
    padding: 8px 0px;
}
.eachSection{
    border: 1px solid #dfdfdf;
    padding: 10px 0% 0px 0%;
    margin: 20px 0px;
}
.eachSectionHeader{
    padding: 0px 2% 20px;
}
.whatYoullLearnContainer{
    padding-left: 3%;
}
.wylValContainer{
    padding-left: 1%;
    color: black;
    opacity: 55%;
    display: inline-flex;
    flex-direction: column;
}
.sectionTitle{
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.sectionTitle h3{
    padding: 6px 0px;
    margin: 0px;
}
.chevDown{
    cursor: pointer;
}
.eachTagInSection{
    padding: 3px 8px;
    font-size: 12px;
    margin-right: 8px;
    background-color: #dfdfdf;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
}
.sectionBodyModules{
    border-top: 1px solid #dfdfdf;
}
.eachModuleContainer{
    border-bottom: 1px solid #d3d1d1;
    
}
.eachModuleHeader{
    padding: 15px 3%;
    background-color: #F6F9FA;
    cursor: pointer;
    border-bottom: 1px solid #d3d1d1;
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.moduleHeaderTitle{
    width: 75%;
}
.moduleHeaderStats{
    width: 25%;
    font-size: 0.75rem;
}
.eachModuleHeader h3{
    padding: 0px;
    margin: 0px;
}
.eachModuleContent{
    width: 90%;
    display: inline-flex;
    align-items: center;
    padding: 12px 5%;
    justify-content: space-between;
}
.eachModuleLeft{
    width: 80%;
    display: inline-flex;
}
.eachModuleRight{
    width: 20%;
    text-align: right;
    font-size: 0.7rem;
}
.subModuleTitle{
    padding: 0px 10px;
}
.subModuleTitle h5{
    padding: 0px;
    margin: 0px;
}




.sectionBody{
    display: inline-flex;
    flex-direction: column;
    width: 100%;
}
.eachWhatYoullDo{
    display: inline-flex;
    align-items: center;
    padding: 2px 0px;
}
.eachWhatYoullDo span{
    padding: 0px 0px 0px 8px;
}

.dummy{
    height: 400vh;
    background-color: red;
}

/* .faqSection{
    height: 80vh; 
    border-bottom: 1px solid #dfdfdf;
} */

.preRegistrationFormContainer{
    width: 86%;
    padding: 10px 7% 0px;
}
.prfcFieldContainer{
    width: 100%;
    margin-bottom: 8px;
}
.prfcFieldContainer input{
    width: calc(90% - 4px);
    padding: 10px 5%;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.registeredMsg{
    color: #da1884;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    padding: 10px 0px;
}


@media only screen and (max-width: 600px) {
    .mainBody{
        margin: -20vh 5% 0px 5%;
        width: 90%;
    }
    .leftContentCol{
        width: 100%;
    }
    .rightContentCol{
        display: none;
    }
    .rightContentColFixed{
        width: 100%;
        bottom: 0px;
        top: auto;
        left: 0px;
        border-left: none;
        border-right: none;
        border-top: 1px solid #dfdfdf;
        border-bottom: none;
    }
    .thisCourseIncludes{
        display: none;
    }
    .courseBuyNow p{
        font-size: 0.6rem !important;
    }
    .eachModuleContainer{
        border-bottom: none;
    }
    
    .courseTitleFixed h1{
        font-size: 1rem;
    }

    .courseTitle h1{
        font-size: 1rem;
    }
    .eachModuleHeader h3{
        font-size: 14px;
    }

}