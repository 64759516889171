.mainWrapper{
    width: 100%;
}
.mainHeader{
    width: 100%;
    text-align: center;
    padding: 20px 0px 40px;
}
.mainHeader h3{
    font-weight: 600;
    font-size: 1.4rem;
}
.mainBody{
    padding: 10px 8%;
}