@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.mainBodyWrapper {
    height: 100vh;
    width: 100%;
    background-image: linear-gradient(#000000, #00000056, #00000056, #000000);
}

.footerWrapper {
    width: 100%;

}

.mainCertificate {
    width: 100%;
    padding-top: 10vh;
    height: 80vh;
    padding-bottom: 10vh;
}

.mainCertificateWrapper {
    width: 75%;
    margin-left: 12.5%;
    margin-right: 12.5%;
    background-color: #000000;
    height: 100%;
    padding: 20px;
}

.mainOuterBorder {
    border: 1px solid #43320D;
    height: calc(100% - 18px);
    padding: 8px;
    position: relative;
    width: calc(100% - 18px);
}

.objectsContainer {
    position: absolute;
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
}

.leftShapeContainer {
    width: 33%;
    position: relative;
}

.leftShapeContainer img {
    width: 60%;
}

.centerShapeContainer {
    width: 33%;
    position: relative;
    text-align: center;
}

.centerShapeContainer img {
    padding-top: 5vh;
    width: 100%;
}

.rightShapeContainer {
    width: 33%;
    position: relative;
    text-align: center;
}

.rightShapeContainer img {
    padding-top: 5vh;
    width: 40%;

}

.mainOuterBorderSec {
    border: 1px solid #43320D;
    height: 100%;
}

.mainCertificateContent {
    position: relative;
    color: white;
    display: inline-flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.upperRow {
    height: 28%;
    width: 100%;
}

.centerRow {
    height: 42%;
    width: 100%;
}

.bottomRow {
    height: 30%;
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.proudlyTitle {
    width: 100%;
    text-align: center;
    color: #8c744f;
}

.proudlyTitle p {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2px;
}

.candiName {
    font-size: 70px;
    text-align: center;
    text-decoration: underline;
    text-decoration-color: #43320D;
    text-underline-offset: 20px;
    text-decoration-thickness: 2px;
    font-family: 'Alex Brush';
    background: linear-gradient(to right, #cc8d0b 0%, #a36515 14.2%, #d18511 28.2%, #f4dd99 42.6%, #e09a21 56.8%, #f0d17b 71%, #a66615 85.8%, #f5b723 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Alex Brush', cursive;
}

.belowParaContainer {
    padding-top: 20px;
    width: 100%;
    text-align: center;
    color: #8c744f;
    font-family: 'Montserrat';
}

.bpcFirst {
    width: 100%;
}

.bpcSecond {
    width: 100%;
    padding-top: 20px;
    line-height: 150%;
}

.eachBtmRow {
    width: 33%;
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.scanToVerifyRow {
    width: 33%;
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ebrDate {
    font-family: 'Montserrat';
    color: #fee2b8;
    font-weight: 600;
}

.ebrDivider {
    border-bottom: 1px solid #43320D;
    margin-top: 1.2vh;
    margin-bottom: 1.2vh;
    width: 30%;
}

.ebrCaption {
    font-family: 'Montserrat';
    color: #8c744f;
    font-weight: 600;
}

.ebrCaptionScan {
    color: #ffffff70;
    font-size: 0.75rem;
    letter-spacing: 1.3px;
    padding: 8px 0px;
}

.scanToVerifyWrapper {
    display: none;
}
.certificateOfIcon{
    display: none;
}


.validatingContent {
    color: #ffffff94;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    background-color: #000000d4;
    flex-direction: column;
    line-height: 170%;
}   
.validatingContent  img{
    width: 100px;
}

.invalidKey{
    color: #ffffff94;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    background-color: #000000d4;
    flex-direction: column;
    line-height: 170%;
}
.invalidKey img{
    width: 100px;
}
.invalidKey p{
    font-weight: 600;
}

.sign img{
    width: 40%;
}

.successParticles{
    position: absolute;
    width: 75%;
}
.successParticles img{
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .leftShapeContainer img {
        width: 90%;
    }

    .centerShapeContainer {
        display: none;
    }

    .rightShapeContainer img {
        width: 50%;
    }

    .certificateOfIcon {
        display: block;
        width: 100%;
        text-align: center;
        position: absolute;
    }

    .certificateOfIcon img {
        padding-top: 10vh;
        width: 40%;
    }

    .mainCertificateWrapper {
        width: calc(90% - 42px);
        margin-left: 5%;
        margin-right: 5%;
    }

    .proudlyTitle {
        padding: 0px 5%;
        width: calc(90%);
    }

    .candiName {
        font-size: 30px;
    }

    .bpcFirst {
        width: 94%;
        padding: 0px 3%;
        font-size: 12px;
    }

    .bpcSecond {
        width: 94%;
        padding: 0px 3%;
        font-size: 12px;
    }

    .scanToVerifyRow {
        display: none;
    }

    .scanToVerifyWrapper {
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        margin-top: 20px;
    }

    .stvwImg {
        width: 100%;
        justify-content: center;
        align-items: center;
        display: inline-flex;
    }

    .stvwImg img {
        width: 80px;
        height: 80px;
    }

    .ebrCaptionScan {
        text-align: center;
        padding-top: 8px;
    }

    .ebrDate {
        font-size: 0.75rem;
    }

    .ebrDivider {
        margin-top: 1vh;
        margin-bottom: 1vh;
    }

    .ebrCaption {
        font-size: 0.75rem;
    }
}