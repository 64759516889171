.mainContainer{
    /* height: 100vh; */
    width: 86%;
    padding: 0px 7%;
    background-color: #FFFFFF;
}
.logoContainer{
    padding: 80px 0px;
    width: 100%;
}
.logoContainer img {
    width: 140px;
}
.containersWrapper{
    display: inline-flex;
    width: 100%;
}
.leftContainer{
    width: 75%;

}
.colFooterContainer{
    display: inline-flex;
    width: 100%;
}
.eachColFooter{
    width: 30%;
}
.eachColFTitle{
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1.2px;
    color: #a5a5a5;
}
.eachColFContent{
    padding: 30px 0px 10px 0px;
    flex-direction: column;
    display: inline-flex;
}
.eachCFCR{
    color: #272626;
    padding: 14px 0px;
    cursor: pointer;
    text-decoration: none;
}


.eachCFCRInputContainer{
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    overflow: hidden;
}
.eachCFCRInput{
    padding: 9px 1.5%;
    width: 57%;
    border: none;
}
.eachCFCRInput:focus{
    outline: none;
}
.eachCFCRBtn{
    width: 40%;
    padding: 9.4px 0px;
    border: none;
    background-color: black;
    cursor: pointer;
    color: white;
    text-transform: capitalize;
    letter-spacing: 1.2px;
    font-weight: 600;
}
.rightContainer{
    width: 25%;
}
.socialRow{
    display: inline-flex;
    width: 100%;
    justify-content: flex-end;
    padding: 40px 0px 10px 0px;
}
.eachSocial{
    padding: 0px 0px 0px 18px;
    cursor: pointer;
}
.eachSocial:hover{
    color: #DA1884;
}
.copyrightContainer{
    text-align: center;
    padding: 20px 0px;
}








@media only screen and (max-width: 600px) {
    .containersWrapper{
        display: block;
    }
    .leftContainer{
        width: 100%;
    }
    .colFooterContainer{
        display: block;
    }
    .eachColFooter{
        width: 100%;
        padding: 0px 0px 28px 0px;
    }
    .eachColFContent{
        padding: 10px 0px;
    }
    .rightContainer{
        width: 100%;
    }
    .eachCFCRInput{
        padding: 14px 1.5%;
    }
    .eachCFCRBtn{
        padding: 14.4px 0px;
    }
}


@media only screen and (max-width: 1340px) {
    .eachCFCR{
        font-size: 0.8rem;
    }
    .eachCFCRInput{
        padding: 8px 1.5%;
        font-size: 0.8rem;
    }
    .eachCFCRBtn{
        font-size: 0.8rem;
    }
    .copyrightContainer{
        font-size: 0.8rem;
    }
}

