.mainPageContent{
    padding: 9vh 0px 0px 0px;
}
.mainTitleHeader{
    width: 100%;
    justify-content: center;
}
.mainTitle{
    text-align: center;
}
.mainTitle h2{
    font-weight: 500;
}
.mainSubTitle{
    text-align: center;
    color: #1f1d1d;
}
.mainSubTitle h4{
    font-weight: 500;
}




.plansWrapper{
    display: flex;
    padding: 20px 7%;
    justify-content: space-between;
}
.eachPlanWrapper{
    width: 28%;
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75vh;
}

.eachPlanContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 75vh;
}
.eachPlanHeader{
    min-height: 80px;
    background: #da1884;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    width: 100%;
    margin-bottom: -30px;

}
.recommendedTxt{
    background-color: #da1884;
    width: 100%;
    height: 50px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
    font-size: 0.8rem;
    letter-spacing: 1.1px;
    text-transform: uppercase;
}
.eachPlanBody{
    width: 86%;
    padding: 7%;
    height: 70vh;
    background-color: #FCE9E1;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
.eachPlanWrapper:nth-child(1) .eachPlanHeader {
    opacity: 0;
    /* display: none; */
}
.eachPlanWrapper:nth-child(2) .eachPlanHeader {
    opacity: 1;
}
.eachPlanWrapper:nth-child(3) .eachPlanHeader {
    opacity: 0;
    /* display: none; */
}
.eachPlanWrapper:nth-child(1) .eachPlanBody {
    background-color: #FCE9E1;
}
.eachPlanWrapper:nth-child(2) .eachPlanBody {
    background-color: #E5F4F2;
}
.eachPlanWrapper:nth-child(3) .eachPlanBody {
    background-color: #E7EEF7;
}


.planFor{
    font-size: 0.8rem;
    font-weight: 500;
}

.planName h4{
    font-size: 1.4rem;
    font-weight: 500;
    padding: 0px;
}
.priceQuote{
    padding: 10px 0px 4px 0px;
    font-size: 1.4rem;
}
.startBtn{
    width: 100%;
    padding: 40px 0px 20px;
}
.startBtn a{
    padding: 15px 40px;
    width: 100%;
    background-color: #da1884;
    font-size: 0.9rem;
    font-weight: 600;
    color: white;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}


.placementValueWrapper{
    padding: 20px 0px 20px;
}
.placementFeeTitle{
    font-size: 0.8rem;
    font-weight: 500;
}
.placementFeeValue{
    font-weight: 500;
    font-size: 1.2rem;
}



.featuresWrapper{
    padding: 20px 0px;
    border-top: 2px solid #fff;
}
.eachFeature{
    display: flex;
    align-items: center;
}
.eachFeatureIcon{
    display: flex;
    align-items: center;
    padding: 10px 10px 10px 0px;
}
.eachFeatureTxt{
    font-size: 0.8rem;
    font-weight: 500;
}


.compareHeader h4{
    font-size: 1.8rem;
    font-weight: 500;
    text-align: center;
}   
.compareHeaderTilesWrapper{
    display: flex;
}

.comparePlansContent{
    width: 86%;
    padding: 10px 7%;
}
.compareHeaderTilesWrapper{
    display: flex;
    width: 100%;
}
.eachCompareHeaderTilesCaptionWrapper {
    border: 1px solid #dfdfdf;
    width: 25%;
    height: 18vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f8f8;
}
.eachCompareHeaderTilesWrapper{
    border: 1px solid #dfdfdf;
    width: 25%;
    height: 18vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f8f8;
}


.eachCompareStatusWrapper{
    display: flex;
}
.statusActionCaptionWrapper{
    border: 1px solid #dfdfdf;
    width: 23%;
    height: 8vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 1%;
}
.statusActionWrapper{
    border: 1px solid #dfdfdf;
    width: 25%;
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sacwIcon{
    display: flex;
    justify-content: center;
    align-items: center;
}

.largeWrapper{
    padding: 20px 7% 0px;
}
.largeContainer{
    /* background-color: #DDC59C; */
    background-color: #E7EEF7;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    padding: 3%;
    display: flex;
}
.largeContentIcon{
    padding: 0px 40px 0px 10px;
}
.largeContentIcon img{
    color: #da1884;
}
.enterpriseTxt{
    font-size: 2rem;
    font-weight: 600;
    padding-bottom: 10px;
}
.enterpriseSubTxt{
    font-weight: 400;
    padding-bottom: 10px;
}
.letsTalk{
    padding: 20px 0px;
}
.letsTalk a{
    background-color: #da1884;
    color: white;
    padding: 10px 30px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-weight: 500;
}


@media screen and (min-width: 500px) and (max-width: 780px) {
    .plansWrapper{
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .eachPlanWrapper{
        width: 100%;
        height: 90vh;
    }
    .eachPlanContainer{
        height: 90vh;
    }
    .eachPlanHeader{
        overflow: hidden;
        min-height: 120px;
        border-top-left-radius: 42px;
        border-top-right-radius: 42px;
        margin-bottom: -40px;
    }
    .eachPlanWrapper:nth-child(1) .eachPlanHeader {
        opacity: 0;
    }
    .eachPlanWrapper:nth-child(2) .eachPlanHeader {
        opacity: 1;
    }
    .eachPlanWrapper:nth-child(3) .eachPlanHeader {
        opacity: 0;
        display: none;
    }
    .recommendedTxt{
        height: 90px;
        font-size: 1.4rem;
        font-weight: 600;
        letter-spacing: 1.1px;
    }
    .eachPlanBody{
        border-radius: 42px;
        -webkit-border-radius: 42px;
        -moz-border-radius: 42px;
        -ms-border-radius: 42px;
        -o-border-radius: 42px;
}
}


@media screen and (max-width: 480px) {
    .plansWrapper{
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .eachPlanWrapper{
        width: 100%;
        height: 75vh;
    }

    .eachPlanWrapper:nth-child(1) .eachPlanHeader {
        opacity: 0;
    }
    .eachPlanWrapper:nth-child(2) .eachPlanHeader {
        opacity: 1;
    }
    .eachPlanWrapper:nth-child(3) .eachPlanHeader {
        opacity: 0;
        display: none;
    }
    .eachPlanHeader{
        min-height: 80px;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        margin-bottom: -30px;
    }
    .recommendedTxt{
        height: 50px;
        font-weight: 600;
        font-size: 0.8rem;
    }
    .eachPlanBody{
        height: 65vh;
        
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
    }

    .eachCompareHeaderTilesCaptionWrapper{
        width: 42%;
        height: 8vh;

    }
    .eachCompareHeaderTilesWrapper{
        width: 20%;
        padding: 0px;
        font-size: 0.7rem;
        height: 8vh;
    }
    .statusActionCaptionWrapper{
        width: 40%;
        font-size: 0.7rem;
        font-weight: 500;
        height: 6vh;
    }
    .statusActionWrapper{
        width: 20%;
        font-size: 0.7rem;
        height: 6vh;
    }
    .sacwIcon{
        font-size: 14px;
    }
    .largeContainer{
        flex-direction: column;
        gap: 20px;
        padding: 3% 5%;
    }
    .enterpriseSubTxt{
        font-size: 0.9rem;
        font-weight: 500;
        color: #00000070;
    }
    .largeContentIcon{
        padding: 20px;
    }
    .largeContentIcon img{
        width: 170px;
    }



}


@media screen and (min-width: 420px) and (max-width: 430px) {
    .plansWrapper{
        gap: 15px;
        padding: 20px 4%;
    }
    .eachPlanWrapper{
        min-height: 67vh;
    }
    .eachPlanContainer{
        min-height: 67vh;
    }
    .eachPlanBody{
        min-height: 55vh;
    }
    .largeWrapper{
        padding: 0px 4%;
    }
    .comparePlansContent{
        padding: 10px 4%;
        width: 92%;
    }
}



@media screen and (max-width: 420px) {
    .plansWrapper{
        gap: 15px;
        padding: 20px 4%;
    }
    .eachPlanWrapper{
        width: 100%;
        height: 75vh;
    }
    .eachPlanContainer{
        height: 75vh;
    }
    .eachPlanBody{
        height: 66vh;
    }
    .largeWrapper{
        padding: 20px 4% 0px;
    }
    .comparePlansContent{
        width: 92%;
        padding: 10px 4%;
    }

    /* .eachPlanWrapper:nth-child(1) .eachPlanHeader {
        opacity: 1;
        display: none;
    }
    .eachPlanWrapper:nth-child(2) .eachPlanHeader {
        opacity: 1;
    }
    .eachPlanWrapper:nth-child(3) .eachPlanHeader {
        opacity: 1;
        display: none;
    } */

}

@media screen and (max-width: 414px) {
    .eachPlanWrapper{
        height: 66vh;
    }
    .eachPlanContainer{
        height: 66vh;
    }
    .eachPlanBody{
        height: 55vh;
    }
}


@media screen and (max-height: 835px) {
    .eachPlanWrapper{
        min-height: 77vh;
    }
    .eachPlanContainer{
        min-height: 77vh;
    }
    .eachPlanBody{
        min-height: 63vh;
    }
}

@media screen and (max-width: 375px) {
    .eachPlanWrapper{
        height: 85vh;
    }
    .eachPlanContainer{
        height: 85vh;
    }
    .eachPlanBody{
        height: 70vh;
    }
    .eachPlanHeader{
        min-height: 90px;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        margin-bottom: -30px;
    }
    .recommendedTxt{
        height: 60px;
        font-size: 1rem;
    }
    .eachPlanBody{
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
    }

    
}