.mainWrapper{
    width: 100%;
}
.mainBody{
    width: 100%;
}
.firstPaintWrapper{
    width: 100%;
    background-color: black;
    height: 100vh;
    position: relative;
}
.fplayerOne{
    width: 100%;
    height: 100vh;
    z-index: 1;
}
.fplayerOneContent{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.fplayerTwo{
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    color: white;

}
.fplayerTwoContent{
    width: 100%;
    height: 100vh;
    display: flex;
}
.fpltcLeft{
    width: 42%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 0px 0px 0px 8%;
}
.workshopTitle h1{
    line-height: 1.4;
}
.workshopTitle h4{
    font-weight: 400;
    color: #ffffff85;
    padding: 0px 0px 40px;
}

.buttonWrapper{
    width: 100%;
    display: flex;
    align-items: center;
}
.buttonWrapper a{
    padding: 10px 20px;
}
.secondaryBtn{
    background-color: transparent;
    border: 1px solid #dfdfdf;
    color: white;
    padding: 10px 0px;
    text-transform: uppercase;
    width: 140px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    letter-spacing: 1.2px;
    font-weight: 700;
}
.primaryBtn{
    background-color: #da1884;
    border: 1px solid #dfdfdf;
    color: white;
    padding: 10px 0px;
    text-transform: uppercase;
    width: 140px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    letter-spacing: 1.2px;
    margin-left: 3%;
    font-weight: 700;
}




.fpltcRight{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.fpltcRight video{
    height: 600px;
    width: 280px;
}

.videoWrapper{
    position: absolute;
    left: 26%;
    top: 10%;
    border-radius: 30px;
    background-image: linear-gradient(black, transparent, black);
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}
.frameWrapper{
    position: absolute;
    left: 25%;
    top: 10%;
}
.frameWrapper img{
    width: 300px;
}

.leftObject{
    width: 50%;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}
.rightObject{
    width: 50%;
}





.secondPaintWrapper{
    width: 100%;
    height: 10vh;
    /* background-image: linear-gradient(#ff0066,#FCBE4C); */
    background-image: linear-gradient(#ff0066,#DE2885);
}

.dumbLostInSpace{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dumbLostInSpace img{
    height: 300px;
}



@media only screen and (max-width: 600px) {
    .fplayerTwoContent{
        flex-direction: column-reverse;
        position: relative;
    }
    .fpltcLeft{
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 2;
        width: 92%;
        height: 97vh;
        padding: 0px 4% 4vh 4%;

        justify-content: flex-end;
        background-image: linear-gradient(180deg, black, transparent, black);
    }
    .fpltcRight{
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 1;
        width: 100%;
    }
    .workshopTitle{
        position: relative;
        width: 100%;
    }
    .workshopTitle h1{
        width: 100%;
    }
    .workshopTitle h4{
        text-align: justify;
    }
    .videoWrapper{
        width: 100%;
        position: static;
    }
    .videoWrapper video{
        width: 100%;
        height: auto;
    }
    .frameWrapper{
        display: none;
    }
    .secondPaintWrapper{
        display: none;
    }

    .dumbLostInSpace{
        overflow: hidden;
        padding: 140px 0px;
    }
}