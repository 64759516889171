.mainWrapper{
    height: 100vh;
    font-family: 'Montserrat';
}
button, h1, h2, h3, h4, h5, h6, p, span, li, ul{
    font-family: 'Montserrat';
}
.mainContainer{
    height: 100vh;
    width: 100%;
}
.loader{
    height: 100vh;
    width: 100%;
    top: 0px;
    left: 0px;
    position: fixed;
    z-index: 2;
    background-color: #00000045;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader img{
    width: 70px;
}
.headerContainer{
    height: 6vh;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    font-weight: 600;
}
.bodyContainer{
    height: 94vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.eachInputWrapper{
    padding: 0px 7% 30px;
}
.captionWrapper{
    display: flex;
    justify-content: space-between;
    padding: 0px 0px 5px;
}
.caption{
    font-size: 0.8rem;
    font-weight: 600;
}
.inputWrapper{
    width: 100%;
}
.inputWrapper input{
    border: 1px solid #dfdfdf;
    width: calc(96% - 2px);
    padding: 11px 2%;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.errorMsg{
    font-style: italic;
    color: red;
    font-weight: 600;
    font-size: 0.7rem;
}
.dataSafeLine{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    font-size: 10px;
    font-weight: 600;
    color: #00000080;
}
.submitBtnWrapper{
    display: flex;
    justify-content: center;
    padding: 0px 7%;
}
.btnsWrapper{
    display: flex;
    justify-content: space-between;
}
.normalBtn{
    padding: 10px 20px;
    width: 45%;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.selectedBtn{
    padding: 10px 20px;
    width: 45%;
    background-color: green;
    border: 1px solid green;
    font-weight: 500;
    color: white;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.defaultBtn{
    padding: 12px 0px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.171);
    color: #00000060;
    border: 1px solid #a5a5a5;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.3px;
    text-align: center;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.activeBtn{
    padding: 12px 0px;
    width: 100%;
    background-color: black;
    color: white;
    border: 1px solid black;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.3px;
    text-align: center;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.successMsg{
    color: green;
}
.thanksTxt{
    text-align: center;
    padding: 0px 0px 40px;
    color: #da1884;
    font-weight: 600;
}
.actionTxtWrapper{
    text-align: center;
}
.actionTxtPri{
    font-weight: 600;
    color: #00000080;
    padding: 30px 0px 20px;
}
.actionTxtSec{
    font-weight: 600;
    padding: 0px 7%;
}
.exploreBtn{
    margin: 30px 0px 30px 0px;
    padding: 12px 30px;
    background-color: aliceblue;
    border: 1px solid #505050d5;
    font-weight: 500;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}