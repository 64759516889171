.mainWrapper{
    background-color: black;
    height: 100svh;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}



.priBtn{
    padding: 12px 0px;
    width: 260px;
    background-color: #c3c3c3;
    text-align: center;
    cursor: pointer;
}



.hnrContainer{
    width: 100%;
    height: 100svh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.bigTitle{
    font-size: 100px;
    font-weight: 600;
}
.subTitle{
    font-weight: 700;
    padding: 20px 0px;
    color: azure;
}
.subTitle h6{
    font-weight: 400;
    font-size: 0.8rem;
    color: #ffffff90;
}
.captionsContainer{
    text-align: center;
}
.readAboutEC{
    color: #c1c1c1;
    padding: 10px 0px;
    font-size: 0.8rem;
}
.actionWrapper{
    padding: 10px 0px;
}



.gcContainer{
    width: 100%;
    height: 100svh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.gcContent{
    width: 60%;
    height: 60svh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.gcBigTitle{
    font-size: 100px;
    font-weight: 600;
}




.gaContainer{
    width: 100%;
    height: 100svh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.gaContent{
    position: relative;
    width: 100%;
    height: 100svh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.gaMessage{
    padding: 40px 25%;
    width: 50%;
}
.lineOne{
    width: 100%;
    font-size: 60px;
}
.lineTwo{
    width: 100%;
    padding: 15px 0px;
    font-weight: 600;
    color: #717171;
}
.lineThree{
    color: #b3b3b3;
    font-weight: 500;
}
.lineFour{
    color: #717171;
    padding: 7px 0px 0px;
}