.activeHome{
    background-color: white;
    position: fixed;
    top: 0;
    width: 100%;
    text-align: center;
    z-index: 888;

    visibility: visible;
    transition: all 0.5s;

    padding: 10px 0px;
    box-shadow: rgba(0, 0, 0, 0.082) 0px 0px 0px 1px;
}

.active {
    background-color: transparent;
    color: #fff;
    position: fixed;
    top: 0;
    width: 100%;
    text-align: center;
    z-index: 888;

    visibility: visible;
    transition: all 0.5s;

    padding: 12px 0px;
    /* box-shadow: 0px 1px 7px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important; */
    /* box-shadow: 0px 1px 1px 0px rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important; */
    box-shadow: 0 2px 18px 0 rgb(129 162 182 / 20%);
}
.activeBg{
    background-color: black !important;
    color: #fff;
    position: fixed;
    top: 0;
    width: 100%;
    text-align: center;
    z-index: 888;

    visibility: visible;
    transition: all 0.5s;
    padding: 10px 0px;
}

.hidden {
    background-color: transparent;
    height: 54px;
    color: #fff;
    position: fixed;
    top: 0;
    width: 100%;
    text-align: center;
    z-index: 888;

    visibility: hidden;
    transition: all 0.5s;
    transform: translateY(-100%);
}


@media only screen and (max-width: 600px) {
    .active{
        padding: 0px 0px;
    }
    .activeBg{
        padding: 0px 0px;
    }

    .activeHome{
        padding: 0px;
    }

}


@media only screen and (max-width: 1380px) {
    .active{
        padding: 8px 0px;
        font-size: 0.8rem;
    }
}