.mainWrappper{
    width: 100%;
    background-color: #f1f1f1;
}
.mainContainer{
    padding: 10px 3%;
}

.mainHeader{
    width: 100%;
    border-bottom: 10px solid #da1884;
}
.mainTitle{
    padding: 10px 2%;
    font-weight: 600;
    cursor: pointer;
    font-size: 1.4rem;
}

.mainBody{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 20px;
}