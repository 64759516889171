.mainContainer {
    width: 100%;
    border: 1px solid #dfdfdf;

    background-color: white;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;

    
}

.mainContainer:hover {
    border: 1px solid #a5a5a5;
}

.apwC {
    overflow: hidden;
    position: relative;
}

.apwCTitle {
    text-align: center;
    font-size: 1.08rem;
    color: #323232;
    padding: 10px 0px 10px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1px;
    background-color: #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.apwcCaption {
    color: #a5a5a5;
    text-align: center;
    font-size: 0.9rem;

    text-align: justify;
    text-justify: inter-word;
    font-weight: 400;
    padding: 16px 0px;

    padding: 10px 4%;
    width: 92%;
}

.aac {
    text-align: center;
}


.applyNowBtn {
    padding: 12px 4% 14px;
    width: 92%;
    display: inline-flex;
    justify-content: space-between;
}

.applyNowBtn button {
    padding: 10px 0px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    cursor: pointer;
    border: none;
    width: 40%;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.applyNowBtn button:hover {
    transform: scale(1.04);
    -webkit-transform: scale(1.04);
    -moz-transform: scale(1.04);
    -ms-transform: scale(1.04);
    -o-transform: scale(1.04);
}

.btnApp {
    background-color: #da1884;
    color: white;
}

.btnDull {
    background-color: black;
    color: white;
}


.applyNowBtn a {
    padding: 10px 10px;
    width: 40%;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    text-align: center;
    font-weight: 600;
}

@media only screen and (max-width: 600px) {
    .mainContainer {
        position: fixed;
        bottom: 0px;
        left: 0px;
        border: none;
        border-radius: 0px;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        -o-border-radius: 0px;

        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    .apwcCaption {
        display: none;
    }
    .mainContainer{
        border: none !important;
    }
    .applyNowBtn a {
        width: 36%;
    }
}

@media only screen and (max-width: 340px) {
    .applyNowBtn a {
        width: 40%;
    }
}


@media only screen and (max-width: 1340px) {
    .apwCTitle {
        font-size: 1rem;
    }

    .apwcCaption {
        font-size: 0.85rem;
    }

    .btnApp {
        font-size: 0.85rem;
    }

    .btnDull {
        font-size: 0.85rem;
    }

}