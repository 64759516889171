.mainWrapper{
    width: 100%;
    font-family: 'Montserrat';
}
.firstPaintWrapper{
    min-height: 160vh;
    background-image: linear-gradient(antiquewhite, transparent, transparent);
}
.mainHeader{
    width: 90%;
    text-align: center;
    padding: 0px 5%;
}
.mainHeader h2{
    padding: 50px 0px 30px;
    margin: 0px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 2rem;
}
.mainHeader p{
    padding: 0px 0px 40px;
    margin: 0px;
    font-weight: 500;
    color: #00000099;
}
.mainBody{
    padding: 20px 7%;
}
.logoHolder{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.eachCard{
    /* background-color: white; */
    margin: 15px 2%;
    padding: 20px;
    /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.brandLogo img{
    height: 70px;
    width: auto;
}


@media screen and (max-width: 480px) {
    .brandLogo img{
        height: auto;
        width: 100px;
    }
}