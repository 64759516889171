.mainContainer{
    width: 92%;
    background-color: #e9e8e8;
    margin: 20px 0px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    padding: 4%;
}
.ch{
    width: 100%;
}
.ch h2{
    padding: 0px;
    margin: 0px;
}


@media only screen and (max-width: 1380px) {
    .ch h2{
        font-size: 0.9rem;
    }
}