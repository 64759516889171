.mainWrapper {
    padding: 40px 0px 100px;
}

.mainTitle {
    text-align: center;
}

.mainTitle h4 {
    padding: 1px 0px 30px;
}

.mainBody {
    padding: 0px 7%;
}

.reviewsWrapper {
    display: flex;
    justify-content: space-between;
}

.eachCardWrapper {
    background-color: #214358;
    color: white;
    font-weight: 500;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;

    padding: 14px 14px;

}

.eachCardWrapper {
    /* margin: 0px 4%; */
    max-width: 28%;
    min-width: 28%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.content{
    font-size: 0.8rem;
    font-weight: 500;
    text-align: justify;
}
.titlesCaption {
    padding: 8px 0px;
}
.author{
    color: #ffffff97;
    font-weight: 500;
}

.stars {
    padding: 7px 0px 0px;
}

.eachStar {
    padding: 0px 3px 0px 0px;
}


@media screen and (max-width: 480px) {
    .mainWrapper {
        width: 100%;
        padding: 40px 0px 60px;
    }

    .mainBody {
        padding: 0px 4%;
        overflow-y: scroll;
    }

    .reviewsWrapper {
        width: 100%;
    }

    .eachCardWrapper {
        min-width: calc(88% - 4%);
        margin: 0px 4% 20px 0px;
    }
}