.mainContainer {
    width: 100%;
    background-color: #F8FAFD;
}

button {
    cursor: pointer;
}

.mainBodyWrapper {
    width: 100%;
    height: 100vh;
}

.mainBody {
    width: 100%;
    height: 100vh;
    display: inline-flex;
}

.filterSectionWrapper {
    margin: 8vh 0px 0px 0px;
    width: 25%;
    height: auto;
    overflow-y: scroll;
}


.mainSection {
    margin: 8vh 0px 0px 0px;
    width: 50%;
    overflow-y: scroll;
}

.rightSection {
    padding: 8vh 1% 0px 1%;
    width: 23%;
}




/* ------- left section ------ */
.filterSection {
    padding: 0px 5%;
    width: 90%;
}

.jobFilterTitleBox {
    background-color: white;
    display: inline-flex;
    width: 96%;
    justify-content: space-between;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding: 10px 2%;
}

.jobFilterTitle {
    font-size: 20px;
    font-weight: 700;
}

.clearAllBtn button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.eachFilterBoxContainer {
    width: 90%;
    padding: 10px 5%;
    margin: 14px 0px;
    background-color: white;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.eachFilterBoxHeader {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;

}

.filterBoxTitle {
    color: #DA2884;
    font-size: 0.9rem;
    font-weight: 700;

}

.filterBoxAction {
    font-size: 0.8rem;
    color: rgba(12, 4, 4, 0.425);
    font-weight: 700;
}

.filterBoxAction button {
    background-color: transparent;
    border: none;
    color: rgba(12, 4, 4, 0.425);
}

.eachFilterBoxBody {
    padding: 8px 0px;
}

.checkBoxContainer {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
}

.filterStats {
    font-size: 12px;
    color: #615d5d;
}


/* -------- main section --------- */
.eachJobLoader {
    margin: 20px 0px;
    overflow: hidden;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.mainBodyWrapperMobile {
    display: none;
}







/* ------- mobile view -------- */
@media only screen and (max-width: 600px) {
    .mainBodyWrapper {
        display: none;
    }

    .mainBodyWrapperMobile {
        width: 100%;
        display: block;
        padding: 9vh 0px 0px;

    }

    .mh {
        display: inline-flex;
        width: 94%;
        justify-content: space-between;
        align-items: center;
        padding: 0px 3%;
    }

    .jobFilterTitleBoxM {
        background-color: white;
        display: inline-flex;
        width: 96%;
        justify-content: space-between;
        padding: 0px 2%;
        align-items: center;
    }

    .sb {
        width: 90%;
        border: 1px solid #dfdfdf;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        overflow: hidden;
    }

    .sf {
        width: 10%;
        text-align: right;
    }

    .sb input {
        padding: 11px 2.5%;
        width: 95%;
        border: none;
    }


    .mbmC {
        padding: 0px 3%;
    }

    .fmlW {
        height: 100vh;
        position: fixed;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        background-color: #615d5d70;
        text-align: center;
    }

    .fmlC {
        color: white;
        position: relative;
        display: inline-flex;
        height: 100vh;
        justify-content: center;
        align-items: center;
    }

    .filterWrapper {
        background-color: white;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 100%;
        left: 0px;
        z-index: 999;

        /* animation-name: openFilter;
        animation-duration: 0.3s; */
    }

    .showFilter {
        background-color: white;
        height: 100vh;
        width: 100%;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 999;

        animation-name: openFilter;
        animation-duration: 0.2s;

    }

    .noResultsFound {
        text-align: center;
        display: inline-flex;
        height: 80vh;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-direction: column;
    }

    .sortingContainer select {
        padding: 10px 1%;
        border: none;
        background: #ecf0f5;
        border-radius: 8px;

    }

    .filterContainer {
        position: relative;
        width: 100%;
        height: 100vh;
        /* min-height: 100%; */
        display: flex;
        flex-direction: column;
    }

    .fhW {
        height: calc(7vh - 1px);
        border-bottom: 1px solid #dfdfdf;
        display: flex;
        align-items: center;
    }

    .fbW {
        height: 85vh;
        overflow-y: scroll;
    }
    .fbWContainer{
        padding-bottom: 10vh;
    }
    .spacer{
        height: 100%;
    }
    .ffW {
        background-color: white;
        position: sticky;
        bottom: 0;
        height: 8vh;
        display: flex;
        align-items: center;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    .ffC {
        background-color: white;
        text-align: center;
        display: inline-flex;
        width: 92%;
        justify-content: space-between;
        padding: 0px 4%;
    }

    .cfb {
        width: 45%;
        padding: 12px;
        border: 1px solid #dfdfdf;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        font-size: 0.9rem;
        text-transform: uppercase;
        letter-spacing: 1.1px;
        font-weight: 700;
    }

    .afb {
        width: 45%;
        padding: 12px;
        border: 1px solid #dfdfdf;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        font-size: 0.9rem;
        text-transform: uppercase;
        letter-spacing: 1.1px;
        font-weight: 700;
        background-color: black;
        color: white;
    }

    @keyframes openFilter {
        0% {
            top: 100%;
        }

        /* 25% { top: 75%; }
        50% { top: 50%; }
        75% { top: 25%; } */
        100% {
            top: 0%;
        }
    }
}



@media only screen and (max-width: 1380px) {
    .jobFilterTitle{
        font-size: 14px;
    }
    .clearAllBtn button{
        font-size: 0.7rem;
    }
    .filterBoxTitle{
        font-size: 0.8rem;
    }
    .filterBoxAction{
        font-size: 0.7rem;
    }
    .filterBoxAction button{
        font-size: 0.7rem;
    }
}



@media only screen and (max-width: 480px) {
    .ffC button{
        font-size: 0.7rem;
    }

}