.parallaxContainer{
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}
.videoOverlay {
    text-align: center;
    color: white;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    z-index: 1;
    background-image: linear-gradient(45deg, black, transparent);
}
.content {
    padding: 40vh 0; /* Add padding to push content below the video */
    background-color: #f2f2f2;
    text-align: center;
    font-size: 24px;
}






body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scrollbars */
}




.parallaxContainer video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover; /* Resize video to cover the container */
}

@media screen and (max-width: 480px) {
    .videoOverlay{
        padding: 0px 10%;
        width: 80%;
        text-align: center;
    }
}