.mainWrapper{
    display: flex;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: #EFF1EF;
}
.mainContainer{
    padding: 9vh 5% 6vh;
    display: flex;
    flex-direction: column;
    gap: 2vh;
    width: 90%;
}

.topWrapper{
    border: 1px solid #EFEFEF;
    height: 12vh;
    background-color: white;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.topContainer{
    padding: 3%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.centerWrapper{
    border: 1px solid #EFEFEF;
    min-height: 58vh;
    background-color: white;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    overflow-y: scroll;
}
.centerContainer{
    padding: 4%;
    
}
.workshopTitle{
    border: 1px solid #6F7F88;
    padding: 8px 10px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    margin-bottom: 10px;
    background-color: #6F7F88;
    font-weight: 500;
    color: white;
}
.workshopDescription{
    background: #dfdfdf40;
    padding: 10px 4%;
    border-radius: 7px;
    text-align: justify;
    font-size: 0.75rem;
    font-weight: 500;
    color: #0000008a;
}
.bottomWrapper{
    border-top: 1px solid #d7d7d7;
    height: 9vh;
    background-color: white;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
}
.bottomContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 9vh;
}
.smallArTxt{
    text-align: center;
    font-weight: 1.1rem;
    font-weight: 500;
    color: #da1884;
    padding: 0px 0px 5px 0px;
}
.tinyArTxt{
    text-align: center;
    font-size: 0.7rem;
    font-weight: 500;
    color: #00000071;
}
.registerNow{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.registerNow button{
    padding: 14px 20px;
    width: 86%;
    background-color: #da1884;
    border: 1px solid #da1884;
    font-weight: 500;
    font-size: 1.1rem;
    color: white;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    text-align: center;
}

.startingInWrapper{
    text-align: center;
    padding: 5px 0px;
}
.startingInTitle{
    font-weight: 500;
    font-size: 1.1rem;
}
.startingInVal{
    text-align: center;
    font-size: 0.8rem;
    font-weight: 500;
    padding: 5px 0px 15px;
}

.twoInARowWrapper{
    display: flex;
    justify-content: space-between;
    border: 1px solid #dfdfdf;
    margin: 10px 0px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    background-color: #e7e7e7;
    height: 44px;
}
.workshopCategory{
    border-right: 1px solid #585858;
    width: calc(50% - 1px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.platform{
    width: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}



.benefitsWrapper{
    border-top: 1px solid #6F7F88;
    padding: 10px 0px;
    margin-top: 10px;
}
.eachBenefitWrapper{
    display: flex;
    padding: 4px 2%;
    gap: 10px;
}
.eachBenefitIcon{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;

    background-color: #dfdfdf40;
}
.eachBenefitTxt{
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 0.8rem;
}



.payNowWrapper{
    display: flex;
    justify-content: space-between;
    width: 92%;
}
.payNowAmountWrapper{
    width: 48%;
    display: flex;
    align-items: baseline;
    gap: 20px;
    align-items: right;
    justify-content: flex-end;
}
.orgPrice{
    font-size: 1rem;
    font-weight: 500;
    color: #00000071;
    text-decoration: line-through;
}
.discountedPrice{
    font-size: 2rem;
    font-weight: 500;
}
.payNowBtnWrapper{
    width: 48%;
}
.payNowBtnWrapper button{
    width: 100%;
    background-color: #da1884;
    border: 1px solid #da1884;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 14px;
    font-weight: 600;
    color: white;
}
