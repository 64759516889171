.mainWrapper{
    padding: 0 7%;
}
.urlWrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: space-between;
}

.eachPageUrl{
    padding: 5px 10px;
    font-size: 0.8rem;
    font-weight: 400;
    color: #383838;
    width: 16%;
}
.eachPageUrl:hover{
    font-weight: 500;
}
.title{
    text-align: center;
    padding: 20px 0px;
}
.showMoreButton{
    
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0px 0px 0px;
}
.showMoreButton button{
    text-align: center;
    padding: 12px 60px;
    border-radius: 36px;
    -webkit-border-radius: 36px;
    -moz-border-radius: 36px;
    -ms-border-radius: 36px;
    -o-border-radius: 36px;
    border: 1px solid #dfdfdf;
    font-size: 0.8rem;
    font-weight: 600;
    color: #969696;
}

@media only screen and (max-width: 600px) {
    .mainWrapper{
        padding: 0px 3%;
    }
    .eachPageUrl{
        overflow: hidden;
        width: 40%;
    }

}