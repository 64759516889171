.topJobsWrapper{
    align-items: center;
    background-color: white;
}
.topJobTitle{
    text-align: center;
    padding: 20px 0px;
}
.topJobTitle h2{
    font-weight: 600;
}
.topJobsContainer{
    display: flex;
    padding: 0px 7% 50px;
    gap: 3%;
}

@media only screen and (max-width: 600px) {
    .topJobsContainer{
        padding: 0px 3%;
        flex-direction: column;
        gap: 20px;
        width: 94%;
    }
    .topJobTitle h2{
        font-weight: 600;
        font-size: 1.2rem;
    }
}