.mainWrapper{
    width: 100%;
    height: 100vh;
    background-color: black;
    z-index: 24;
    position: relative;
}
.mainContainer{
    width: 100%;
    height: 100vh;
    z-index: 999;
    background-color: #000000;
}
.galleryHeader{
    height: 12.5vh;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}
.galleryHeader h2{
    font-weight: 500;
    letter-spacing: 1.2px;
    padding: 10px 0px 0px 0px;
    margin: 0px 0px;
    font-size: 2rem;
}
.galleryHeader p{
    padding: 6px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    color: #ffffff85;
    font-weight: 600;
}
.eachRow{
    display: flex;
    justify-content: center;
    align-items: center;
    padding:  0px 7% 12.5vh 7%;
    height: 75vh;
    width: 86%;
    color: white;
}
.squareWrapper{
    width: 48.5%;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
    overflow: hidden;
    height: 60vh;
    margin: 0px 1.5% 0px 0px;

    border: 1.5px solid #f1f1f1;
}
.squareWrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.landscapeHolder{
    width: 28.5%;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
    overflow: hidden;
    margin: 0px 1.5% 0px 0px;
    display: flex;
    height: 60vh;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: space-between; */

}
.landscapeWrapper{
    height: 28.5vh;
    overflow: hidden;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;

    border: 1.5px solid #f1f1f1;
}
.landscapeWrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.portraitWrapper{
    width: 20%;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
    overflow: hidden;
    height: 60vh;

    border: 1.5px solid #f1f1f1;
}
.portraitWrapper img{
    object-fit: cover;
    height: 100%;
    width: 100%;
}


@media only screen and (max-width: 600px) {
    .mainWrapper{
        background-color: #da1884;
    }
    .mainContainer{
        background-color: #da1884;
    }
    .eachRow{
        flex-direction: column;
    }
    .squareWrapper{
        height: 400px;
        width: 100%;
    }
    .landscapeHolder{
        width: 100%;
        margin: 4px 1.5% 10px 0%;
        height: auto;
    }
    .landscapeWrapper{
        height: 400px;
        margin: 5px 0px;
    }
    .portraitWrapper{
        height: auto;
        width: 100%;
    }

}
