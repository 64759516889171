.mainWrapper {
    background-color: black;
    height: 100svh;
    width: 100%;
    color: white;
}

.mainHeaderWrapper {
    height: calc(8svh - 1px);
    border-bottom: 1px solid #dfdfdf96;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #393A3B;

}
.headerIcon{
    display: flex;
    align-items: center;
    width: 14%;
    justify-content: center;
}
.headerTxt{
    width: 86%;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.2rem;
    letter-spacing: 1.2px;
}

.mainBodyWrapper {
    height: 84svh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
}
.mainBodyContent{
    width: 30%;
    padding: 0px 35%;
    position: relative;
}
.titleBigWrapper {
    padding: 20px 5%;
    width: 90%;
}

.titleBigBold {
    font-size: 3rem;
    font-weight: 700;
}

.title {
    font-size: 1.3rem;
    text-align: center;
    font-weight: 600;
}

.smallInstruction {
    font-size: 0.7rem;
}

.dataBoxWrapper {
    padding: 20px 5%;
    width: 90%;
}

.inputBoxWrapper {
    margin: 8px 0px;
}

.phoneBoxInvalid {
    padding: 13px 2%;
    width: calc(96% - 2px);
    border: 1px solid red;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    background-color: #393A3B;
    color: white;
    letter-spacing: 1.4px;
}
.phoneBox {
    padding: 13px 2%;
    width: calc(96% - 2px);
    border: 1px solid #dfdfdf96;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    background-color: #393A3B;
    color: white;
    letter-spacing: 1.4px;
}
.inputBoxWrapper input::placeholder{
    letter-spacing: normal;
}
.submitBtnWrapper{
    width: 100%;
    padding: 20px 0px;
}
.submitBtnWrapper button{
    padding: 13px;
    width: 100%;
    background-color: #ffcb08;
    border: 1px solid #ffcb08;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-weight: 700;
    text-transform: uppercase;
}



.checkTncWrapper{
    padding: 20px 0px 0px;
}
.checkTnc{
    display: flex;
    align-items: center;
}
.readCertificateCriteria{
    text-align: center;
    padding: 10px 0px 0px;
    font-size: 0.8rem;
}
.checkBox{
    display: flex;
    align-items: center;
}
.tncTxt{
    padding: 0px 0px 0px 5px;
    color: #ffffff91;
    font-weight: 600;
    font-size: 0.9rem;
}
.readCertificateCriteriaTxt{
    color: rgb(184, 184, 184);

}

.mainFooterWrapper {
    height: calc(8svh - 1px);
    background-color: #393A3B;
    width: 100%;
}


.enterOtpWrapper{
    position: relative;
    height: 84svh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
}
.enterOtpContent{
    position: relative;   
    height: 84svh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 85%;
    padding: 0px 7.5%;
}
.sectionTitle{
    width: 100%;
    font-size: 1.3rem;
    padding: 10px 0px;
    text-align: center;
}
.sectionSubTitle{
    font-size: 0.8rem;
    text-align: center;
    width: 100%;
    color: #ffffff91;

}
.otpEnterHandler{
    padding: 10px 0px;
}
.otpInputs{
    padding: 10px;
}
.otpInputs input{
    padding: 12px;
    color: white;
    font-weight: 800;
    font-size: 1.1rem;
    background-color: #393A3B;
    /* border: 1px solid #dfdfdf96; */
    border: none;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;;
    
}

.otpActionsWrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.otpCancelBtn{
    padding: 12px;
    border: 1px solid #dfdfdf96;
    background-color: #393A3B;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    text-transform: uppercase;
    color: white;
    font-size: 1.1rem;
    font-weight: 600;

    width: 200px;
}
.otpSubmitBtn{
    padding: 12px;
    border: 1px solid #ffcb08;
    background-color: #ffcb08;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;

    width: 200px;
    margin: 0px 0px 0px 20px;
}


.resendCodeWrapper{
    width: 100%;
    padding: 10px 0px;
    text-align: center;
}
.didnotReceiveTxt{
    color: #aaaaaa;
}
.resendCode{
    padding: 8px 0px;
    font-weight: 600;
}
.contactUs{
    padding: 15px 0px;
}
.contactUsTxt{
    margin: 12px 0px;
    font-weight: 600;
    color: #da1884;

}




@media only screen and (max-width: 600px) {
    .mainBodyContent{
        padding: 0px 0px;
        width: 100%;
    }
    .sectionTitle{
        text-align: left;
    }
    .sectionSubTitle{
        text-align: left;
    }
    .tncTxt{
        font-size: 0.8rem;
    }

    .otpActionsWrapper{
        flex-direction: column-reverse;
    }
    .otpCancelBtn{
        margin: 20px 0px;
        width: 100%;
    }
    .otpSubmitBtn{
        width: 100%;
        margin: 0px;
    }
}