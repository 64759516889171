.mainContainer{
    width: 100%;
}
.eachModuleContent{
    width: 100%;
    display: inline-flex;
    padding: 14px 2%;
}
.eachModuleLeft{
    display: inline-flex;
    flex-direction: row;
    width: 80%;
    align-items: center;
}
.eachModuleRight{
    width: 20%;
}
.subModuleTitle{
    padding-left: 2%;
}
.subModuleTitle h5{
    padding: 0px;
    margin: 0px;
}