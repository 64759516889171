.mainWrapper{
    height: 80vh;
    background-color: #da1880;
}
.mainTitle{
    text-align: center;
}
.mainTitle h4{
    padding: 30px 5% ;
    color: white;
    font-weight: 600;
    font-size: 1.3rem;
}
.mainTitle{
    height: 15vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mainBody{
    height: 65vh;
}
.uspContainer{
    display: flex;
    padding: 0px 7%;
    justify-content: space-between;
    align-items: center;
    height: 65vh;
}
.eachUspCard{
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;


    height: 30vh;
    width: 24%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.eachUspCard h4{
    padding: 0px;
    margin: 0px;
}
.content{
    padding: 5px 10px 0px;
}
.content p{
    padding: 0px ;
    margin: 0px;
    font-weight: 500;
    text-align: center;
    color: #00000080;
}



@media screen and (max-width: 480px) {
    .mainWrapper{
        height: 95vh;
    }
    .uspContainer{
        flex-direction: column;
    }
    .eachUspCard{
        width: 94%;
        margin: 0px 0px 20px;
    }
    .eachUspCard h4{
        padding: 10px 0px 0px;
        margin: 0px;
    }
    .content p{
        padding: 10px 0px 10px;
        text-align: center;
    }
}