.readMoreBtn {
    border: 1px solid #c0bebe;
    padding: 0px 10px;
    margin: 10px 0px;
    border-radius: 7px;
    cursor: pointer;
    font-weight: 700;
    background: white;
    display: flex;
    justify-content: space-between;
    width: 40%;
}

.readMoreTxt {
    padding: 10px;
}

.readMoreBtn img {
    width: 20px;
}

.rmIcon {
    border-left: 1px solid #c0bebe;
    justify-content: center;
    align-items: center;
    display: flex;
}

.rmIcon img {
    padding: 10px;
}

@media only screen and (max-width: 600px) {

    .readMoreBtn {
        width: 75%;
    }

    .readMoreTxt {
        padding: 15px;
    }
}