.eachFAQQuestion{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.eachFAQQuestion h4 {
    padding: 0px;
    margin: 0px;
}
.questionContainer{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 90%;
}
.question{
    padding-left: 0%;
    width: 100%;
}