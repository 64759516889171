.mainWrapper{
    width: 100%;
    height: 80vh;
    background-color: #242424;
    position: relative;
}
.aboutSection{
    color: white;
    padding: 0px 7%;
    text-align: justify;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
}
.title{
    color: #EDEDED;
    font-weight: 700;
    font-size: 1.25rem;
    padding: 5px 0px;
    text-align: left;
    width: 100%;
}
.description{
    color: #C6C6C6;
    line-height: 1.4;
    font-weight: 600;
    padding: 10px 0px;
}