.mainWrapper{
    padding: 70px 0px 0px 0px;
    background-color: #F7F7F7;
}
.mainContainer{
    width: 100%;
}
.mainPageTitle{
    text-align: center;
}
.mainPageTitle h1{
    font-weight: 600;
    font-size: 2rem;
}

.eachBusinessWrapper{
    padding: 0px 0px 30px;
}
.businessHeader{
    padding: 0px 30% 30px;
}
.businessHeaderContainer{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 20px 0px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    text-align: center;
    font-weight: 500;
}
.businessSubHeaderContainer{
    text-align: center;
}
.businessSubHeaderContainer p{
    font-weight: 500;
    color: #6a6a6a;
}
.tilesContainer{
    display: flex;
    gap: 2%;
    padding: 0px 5%;
}
.eachTileWrapper{
    flex: 1;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 0px 0px 10px;
}


.eachTileHeader{
    padding: 20px 5%;
}
.eachTileIcon{
    height: 200px;
    width: 200px;
}
.eachTileIconFt{
    display: flex;
    justify-content: center;
    align-items: center;
}
.eachTileIconFt img{
    width: 200px;
}
.eachTileBody{
    padding: 0px 5%;
}
.ftTitle{
    padding: 0px;
    margin: 0px;
}
.eachTileBody p{
    font-weight: 500;
    color: #3a3a3a;
    font-size: 0.9rem;
}
.eachTileFooter{
    padding: 0px 5%;
    height: 10vh;
    display: flex;
    align-items: center;
}
.eachTileFooterFt{
    padding: 30px 5% 30px;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.actionBtn{
    background-color: #da1884;
    padding: 14px 14px;
    color: white;
    font-weight: 500;
    width: 160px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.actionBtnFt{
    background-color: #da1884;
    padding: 14px 14px;
    color: white;
    font-weight: 500;
    width: 160px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.ftUspWrapper{
    display: flex;
    gap: 2%;

}
.eachFtUspWrapper{
    flex: 1;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    flex-direction: column;
}
.eftuIconWrapper{
    display: flex;
    justify-content: center;
    align-items: center;

}
.eftuIcon{
    height: 140px;
    width: 140px;
    text-align: center;
}
.eftuTitle{
    text-align: center;
    padding: 10px 2% 20px;
    font-weight: 600;
    color: #6a6a6a;
}

@media only screen and (max-width: 600px) {
    .businessHeader{
        padding: 0px 5% 30px;
    }
    .tilesContainer{
        flex-direction: column;
        gap: 20px;
    }
    .mainPageTitle h1{
        font-weight: 600;
        font-size: 1rem;
    }
    .ftUspWrapper{
        flex-direction: column;
        gap: 20px;
    }
    .eftuIcon{
        height: 200px;
        width: 200px;
    }
    .eachTileIconFt img{
        width: 120px;
    }
    .eachTileFooterFt{
        display: flex;
        justify-content: center;
        text-align: center;
    }
}