.mainWrapper{
    height: 8vh;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
}
.logoWrapper{
    height: 8vh;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 1%;
}
.logoWrapper img{
    height: 4vh;
}
.helpWrapper{
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
}


@media screen and (max-width: 780px) {
    .logoWrapper{
        padding: 0px 0px 0px 3%;
    }
}