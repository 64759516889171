.eachCarouselCard{
    margin: 0px 5%;
    height: 80%;
    /*width: 100%;
    */position: relative;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    overflow: hidden;
}
.eachCarouselCard:hover{
    border: 1px solid white;
    cursor: pointer;
}
.eachCarouselCard img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.coverImageContainer{
    width: 100%;
    height: 100%;
}


.mainContentContainer{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #1d1d1d5b;
    top: 0px;
    left: 0px;

    /* position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, transparent, transparent, black);
    top: 0px;
    left: 0px; */
}
.mainContentWrapper{
    position: relative;
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;
}
.mainContentHeader{
    width: 100%;
    padding: 8px 2%;
}
.imageContainer{
    width: 40px;
    height: 40px;
    border: 2px solid white;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    overflow: hidden;
}
.mainContentTxt{
    color: white;
    width: 100%;
    /* height: 20vh; */
    /* border: 1px solid red; */
    text-align: left;
    padding: 8px 2%;


    background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.459), #141414ce);
}
.categoryTag{
    background-color: #da1884;
    padding: 4px 6px;
    letter-spacing: 1.4px;
    font-weight: 600;
    font-size: 0.9rem;
}

.workshopTitle{
    width: 100%;
}
.workshopTitle h3{
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.6rem;
}


.viewDetailsContainer{
    width: 100%;
    padding: 0px 2% 20px;
    text-align: right;
}
.workshopDescription{
    padding: 0px 0px 20px;
    color: beige;
    font-size: 0.8rem;
}
.viewDetailsContainer button{
    background-color: #da1884;
    border: 1px solid white;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1.4px;
    padding: 10px 20px;
}