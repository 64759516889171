.loaderWrapper{
    height: 100vh;
    width: 100%;
}
.iconWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
}
.iconContainer{
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.iconContainer img{
    width: 20%;
}

.mainWrapperInvalidUrl{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.iuIconWrapper{
    display: flex;
    width: 100%;
    justify-content: center;
    align-content: center;
    overflow: hidden;
}
.iuIconWrapper img{
    width: 100%;
}
.iuTxtWrapper{
    text-align: center;
}
.iuTitle{
    font-size: 1.5rem;
    font-weight: 600;
}
.iuSubTitle{
    font-size: 1rem;
    font-weight: 500;
    color: #00000056;
    padding: 10px 0px;
}
.findMoreJobsWrapper{
    padding: 16px 0px 30px;
}
.findMoreJobsWrapper a{
    border: 1px solid #4296E0;
    padding: 10px 30px;
    color: #4296E0;
    font-weight: 600;
    background-color: #4296E034;
}


.mainWrapper{
    height: 100vh;
    width: 100%;
}
.mainContainer{
    height: 100vh;
    width: 100%;
    padding: 60px 0px;
}
.headerWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 94%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
    padding: 10px 3%;
    border-bottom-left-radius: 20px;
    background-color: #da1884;
    color: white;
}
.mainTitle{
    font-weight: 600;
    font-size: 1.2rem;
}
.idTagLine{
    font-size: 0.7rem;
    font-weight: 500;
    color: #ffffff90;
}
.detailsWrapper{
    border: 1px solid #dfdfdf;
    margin: 15px 3% 0px 3%;
    border-radius: 12px;
}
.username{
    font-size: 1rem;
    padding: 10px 3% 0px;
    font-weight: 600;
}
.messageAndSalonDetails{
    padding: 0px 3%;
}
.smallMsg{
    font-size: 0.7rem;
    font-weight: 500;
    color: #00000076;
}
.company_name{
    font-size: 0.8rem;
    font-weight: 600;
}
.timeSlotWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
}

.timeWrapper{
    display: flex;
    align-items: flex-end;
}
.hour{
    font-size: 32px;
    font-weight: 600;
    padding: 10px 20px;
    background-color: #f2f2f247;
    border-radius: 2px;
    height: 50px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 10px;
}
.minute{
    font-size: 32px;
    font-size: 32px;
    font-weight: 600;
    padding: 10px 20px;
    background-color: #f2f2f247;
    border-radius: 2px;
    height: 50px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 10px;
}
.meridian{
    font-size: 22px;
}
.daysWrapper{
    text-align: center;
    padding: 20px 0px;
}
.day{
    color: #00000046;
    font-size: 0.8rem;
    font-weight: 600;
}
.month{
    color: #00000056;
    font-size: 1rem;
    font-weight: 600;
}

.storeDetailsWrapper{
    border-top: 1px solid #dfdfdf;
}
.storeDetailsContainer{
    padding: 10px 3%;
}
.storeName{
    font-size: 0.8rem;
    font-weight: 600;
}
.storeAddress{
    font-size: 0.6rem;
    font-weight: 600;
    color: #00000056;
}
.mapIconWrapper{
    overflow: hidden;
    height: 20vh;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    margin: 8px 0px 0px;
    display: block;
}
.mapIconWrapper img{
    object-fit: cover;
    height: 100%;
    width: 100%;
}
.jobDetailsWrapper{
    background-color: #f1f1f1;
    margin: 10px 0px 0px 0px;
    padding: 10px 3%;
}
.helpWrapper{
    padding: 10px 3%;
}
.needHelpTitle{
    font-weight: 600;
    font-size: 0.9rem;
    color: #00000076;
}
.needHelpSubtitle{
    font-size: 0.8rem;
    color: #00000057;
    font-weight: 600;
    padding: 5px 0px;
}
.contactOptions{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
}
.callNow{
    padding: 10px 6px;
    background-color: #3684F5;
    color: white;
    font-weight: 500;
    width: 40%;
    text-align: center;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.whatsApp{
    padding: 10px 6px;
    background-color: #2CB641;
    color: white;
    font-weight: 500;
    width: 40%;
    text-align: center;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.footerWrapper{
    border-top: 1px solid #dfdfdf;
    padding: 0px 0px 80px;
}
.navWrapper{
    position: fixed;
    background-color: white;
    bottom: 0px;
    left: 0px;
    z-index: 4;
    width: 100%;
    height: 10vh;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.languageWrapper{
    width: 94%;
    display: flex;
    justify-content: flex-end;
    padding: 10px 3%;
}
.languageWrapper select{
    padding: 7px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    color: #a5a5a5;
    font-size: 0.7rem;
    font-weight: 500;
}
.eachSectionWrapper{
    padding: 7px 3%;
}
.eachSectionContainer{
    border: 1px solid #dfdfdf;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
    overflow: hidden;
}
.sectionTitle{
    padding: 10px 10px 0px 10px;
    font-size: 0.8rem;
    font-weight: 600;
    color: #3d3d3d;
}

.contactDetailsWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #dfdfdf70;
    height: 70px;
    margin: 10px 0px 0px;
    padding: 0px 3%;
}
.twoInARow{
    display: flex;
    justify-content: space-between;
}
.userDetailsContainer{
    display: flex;
    align-items: center;
    height: 70px;
}
.skillsWrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin: 9px 0px 0px ;
    padding: 10px 3%;
    background-color: #F1F1F1;
}
.eachSkill{
    padding: 4px 8px;
    font-size: 0.6rem;
    font-weight: 600;
    border: 1px solid #dfdfdf;
    color: #00000057;
    background-color: white;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
}
.userIcon{
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dfdfdf;
    border-radius: 46px;
    -webkit-border-radius: 46px;
    -moz-border-radius: 46px;
    -ms-border-radius: 46px;
    -o-border-radius: 46px;
    background-color: white;
}
.pocDetails{
    padding: 0px 10px;
}
.pocName{
    font-weight: 600;
    color: #00000087;

}
.pocDesignation{
    font-weight: 600;
    font-size: 0.7rem;
    color: #00000087;
}


.confirmTxt{
    text-align: center;
    font-size: 0.6rem;
    font-weight: 600;
    padding: 6px;
}

.confirmationBtn{
    display: flex;
    justify-content: space-between;
    padding: 0px 3%;
}


.disagreeBtn{
    height: 5vh;
    width: 48%;
}
.agreeBtn{
    height: 5vh;
    width: 48%;
}