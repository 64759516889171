.mainWrapper{
    width: 100%;
    height: 100vh;
}
.firstPaintWrapper{
    background-color: #202020;
    height: 70vh;
    padding: 0px;
    margin: 0px;
    display: flex;
}
.fpwLeftWrapper{
    width: 50%;
    color: white;
}
.fpwRightWrapper{
    width: 50%;
}
.fpwLeftContainer{
    height: 70vh;
    padding: 0px 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.secondPaintWrapper{
    background-color: white;
    width: 100%;
    padding: 0px 0px 10vh;
}
.secondPaintContainer{
    width: 100%;
}
.spcTitle{
    text-align: center;
    padding: 40px 0px 30px;
}
.spcTitle h2{
    padding: 0px;
    margin: 0px;
    font-weight: 600;
}
.citiesWrapper{
    display: flex;
    gap: 2%;
    row-gap: 25px;
    padding: 0px 5%;
    flex-wrap: wrap;
}
.eachCityWrapper{
    width: calc(23.5% - 42px);
    border: 1px solid #dfdfdf;
    padding: 20px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.fpwRightContainer{
    width: 80%;
    height: 60vh;
    color: white;
    color: white;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    padding: 0px 10%;
}
.citiesCount{
    font-size: 3.5rem;
    font-weight: 500;
}

.growing{
    font-size: 1rem;
    font-weight: 500;
}
.thirdPaintWrapper{
    height: 5vh;
}

@media only screen and (max-width: 600px) {
    .firstPaintWrapper{
        width: 100%;
        height: 100vh;
        flex-direction: column;
    }
    .fpwLeftWrapper{
        width: 100%;
        height: 50vh;
    }
    .fpwLeftContainer{
        height: 50vh;
    }
    .fpwRightWrapper{
        width: 100%;
        height: 50vh;
    }
    .fpwRightContainer{
        width: 80%;
        height: 50vh;
    }
    .eachCityWrapper{
        width: calc(100% - 40px);
    }
}