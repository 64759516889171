.similarJobsFooter a{
    text-decoration: none;
    color: black;

}
.similarJobsFooter a:hover{
    color: #da1884;
    font-weight: 600;
}
.mainContainer{
    width: 100%;
    border: 1px solid #dfdfdf;
    background-color: white;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    overflow: hidden;
    max-height: 360px;
}
.mainContainer:hover{
    border: 1px solid #a5a5a5;
}
.mainTitle{
    width: 100%;
    background-color: #dfdfdf;
}
.mainTitle h4{
    padding: 13px 10px;
    margin: 0px;
    
}

.similarJobsBody{
    width: 92%;
    padding: 10px 4% 0px;
    max-height: 240px;
    overflow-y: scroll;
}

.similarJobsFooter{
    width: 92%;
    padding: 13px 4% 13px;
    text-align: right;
}


@media only screen and (max-width: 1380px) {
    .mainTitle h4{
        padding: 10px 10px;
        font-size: 0.8rem;
    }
    .similarJobsFooter{
        padding: 6px 4% 10px;
    }
    .similarJobsFooter a{
        font-size: .7rem;
    }

}