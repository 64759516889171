.mainContainer{
    width: 100%;
    font-family: 'Montserrat';
}
h1, h2, h3, h4, h5, h6, p, span, li, ul, a, button, span{
    font-family: 'Montserrat';
}
.heroContainer{
    height: 70vh;
    background-color: aliceblue;
}
.videoPlayerContainer video{
    width: 100%;
}
.heroContainer h3{
    padding: 0px;
    margin: 0px;
}
.heroTitle{
    font-size: 60px;
}
.yellowShape{
    position: relative;
    z-index: 1;
    transition: all .3s ease-out 0s;
}
.yellowShape img{
    position: absolute;
    bottom: 22px;
    left: -8px;
    z-index: -1;
    animation: section-animation .8s 1;
    -webkit-animation: section-animation .8s 1;
    animation-delay: 1s;
}
.firstContainer{
    height: 180vh;
    background-image: linear-gradient(aliceblue, white);
}
.heroContent{
    width: 100%;
    height: 80vh;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.likeNeverBefore{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
}
.likeNeverBeforeTxt{
    width: 100%;
    font-size: 24px;
    padding-left: 10px;
}
.centeredHoriLine{
    width: 17%;
    background-color: black;
    height: 4px;
}


@keyframes section-animation {
    0%   {width: 0%;}
    100% {width: 100%;}
}


.courseDomainsContainer{
    background-color: black;
    /* height: 100vh; */
    color: white;
    padding-bottom: 30px;
}
.courseDomainsTitle{
    width: 100%;
    text-align: center;
    padding: 40px 0px 60px;
    letter-spacing: 1.4px;
    text-transform: uppercase;
}
.courseDomainsCardsContainer{
    display: inline-flex;
    justify-content: space-between;
    width: 86%;
    padding: 0px 7%;
}
.eachCourseDomains{
    border: 2px solid white;
    padding: 30px 3%;
    width: 22%;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
}
.eachCourseDomainsTitle{
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1.8px;
    font-size: 20px;
    padding: 20px 0px;
}


.exploreCourseRow{
    width: 100%;
    padding: 20px 0px;
}
.exploreCourseBtnContainer{
    text-decoration: none;
    background-color: white;
    border: 2px solid black;
    padding: 10px 10px;
    display: inline-flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    cursor: pointer;
    /* background: linear-gradient(to right, #ffefd5 50%, white 50%); */
    background: linear-gradient(to right, #000 50%, white 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .2s ease-out;
    -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    -ms-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
}
.exploreCourseBtnContainer:hover{
    background-position: left bottom;
    border: 2px solid white;
    color: white !important;
}
.exploreCourseBtnContainer:hover .exploreBtnTxt{
    color: white;
}
.exploreBtnTxt{
    color: black;
}
.exploreBtnTxt:hover{
    color: white;
}
.exploreBtnIcon img{
    height: 10px;
    color: white;
}
.exploreBtnIcon img:hover{
    color: black;
}




.courseCategorySection{
    width: 86%;
    /* height: 80vh; */
    padding: 10vh 7%;
    background-color: antiquewhite;
}
.eachCourseCategory{
    width: 100%;
    display: inline-flex;
    margin: 80px 0px;
    justify-content: space-between;
    flex-direction: row;
    /* border: 1px solid red; */
}
.eachCourseCategoryCompli{
    width: 100%;
    display: inline-flex;
    margin: 80px 0px;
    justify-content: space-between;
}
.eachCourseCategoryLeft{
    width: 40%;
    display: inline-flex;
    align-items: center;
}
.eachCourseCategoryLeftCompli {
    width: 40%;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
}
.eachCategoryTitle{
    text-transform: uppercase;
    letter-spacing: 1.3px;
    font-weight: 800;
    font-size: 34px;
    padding: 20px 0px;
}
.eachCourseCategoryRight{
    width: 60%;
}
.cardContainer{
    color: white;
    background-color: black;
    border: 5px solid white;
    width: calc(90% - 10px);
    padding: 10px 5%;
    display: inline-flex;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    position: relative;
}
.cardLeft{
    width: 70%;
}
.cardRight{
    width: 30%;
}
.cardRight img{
    position: absolute;
    height: 50vh;
    bottom: 0px;
    right: 5%;
}
.cardRightAlt{
    width: 30%;
}
.cardRightAlt img{
    position: absolute;
    height: 40vh;
    bottom: 0px;
    left: 0px;
}

.cardTitle{
    text-transform: uppercase;
    letter-spacing: 1.3px;
    font-weight: 800;
    font-size: 34px;
    padding: 20px 0px;
}
.cardDescription{
    padding: 20px 0px 40px 0px;
}
.courseCategorySectionMobile{
    display: none;
}



@media only screen and (max-width: 600px) {
    .cardTitle{
        overflow: hidden;
    }
    .courseDomainsTitle{
        padding: 20px 0px;
    }
    .heroContainer h3{
        font-size: 3rem;
    }
    .courseDomainsCardsContainer{
        flex-direction: column;
    }
    .eachCourseDomains {
        width: calc(94% - 4px);
        padding: 0px 3%;
        margin: 8px 0px;
    }
    .courseCategorySection{
        display: none;
    }
    .courseCategorySectionMobile{
        display: block;
    }

    .eachCourseCategory{
        flex-direction: column;
        margin: 15px 3%;
        width: 94%;
    }

    .eachCourseCategoryLeft{
        width: 100%;
        overflow: hidden;
    }
    .eachCourseCategoryRight{
        width: 100%;
        overflow: hidden;
    }
    .eachCourseCategoryCompli{
        width: 100%;
    }
    .cardLeft{
        width: 100%;
    }
    .cardRight{
        width: 100%;
    }
    .cardContainer{
        margin: 0px 0px 8px 0px;
        background-image: url("https://res.cloudinary.com/drotwjbzs/image/upload/v1657885383/brush-2_cm0ppa.png");
    }
    .cardTitle{
        /* display: none; */

        font-size: 1rem;
    }
    .cardDescription{
        text-align: justify;
        text-justify: inter-word;
    }
    .ccsmTitle{
        text-align: center;
    }
}



@media only screen and (max-width: 1340px) {
    .exploreCourseBtnContainer{
        font-size: 0.7rem;
    }
    .heroContainer h3{
        font-size: 50px;
    }
    .eachCategoryTitle{
        font-size: 28px;
    }
    .cardTitle{
        font-size: 28px;
    }
    .cardDescription{
        font-size: 0.8rem;
    }
}