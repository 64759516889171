.mainWrapper {
    width: 100%;
    font-family: 'Montserrat';
}
h1, h2, h3, h4, h5, h6, button, span, p, li, ul {
    font-family: 'Montserrat';
}

.firstPaintWrapper {
    background-color: aliceblue;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.firstPaintContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10vh;
}

.bgContainer img {
    height: 100vh;

}

.fpContentContainer {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    /* background-image: linear-gradient(transparent, #00000040, #000000); */
    background-image: linear-gradient(transparent, #000000);
}

.fpContentMain {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100vh;
    color: white;
    font-weight: 600;
    flex-direction: column;
}

.fpUpperContent {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 80vh;
}

.reinventingTxt h3 {
    font-weight: 500;
}

.fpLowerContent {
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.mainTitle h1 {
    font-weight: 600;
    font-size: 3.3rem;
    padding: 0px 0px 10px;
    margin: 0px 0px 10px;
}

.oneTxt p {
    padding: 0px;
    margin: 0px;
}

.enrollNow {
    padding: 13px 20px;
    width: 200px;
    background-color: white;
    color: black;
    text-align: center;
}

.mobileView {
    display: none;
}

.webView {
    display: block;
}

/* styles.btmStickyBtn.css */
.stickyBtn {
    position: fixed;
    bottom: -1px;
    left: 0;
    width: 100%;
    z-index: 2;
    right: 0;
    background-color: white;
    border: none;
    color: black;
    padding: 12px 0px; 
    transition: bottom 0.3s ease-in-out;
    cursor: pointer;
    display: none;
    -webkit-transition: bottom 0.3s ease-in-out;
    -moz-transition: bottom 0.3s ease-in-out;
    -ms-transition: bottom 0.3s ease-in-out;
    -o-transition: bottom 0.3s ease-in-out;
    
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.stickyBtnHidden {
    bottom: -100px;
    display: none;
}
.hireNowSticky{
    padding: 12px 0px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    background-color: black;
    width: 90%;
    margin: 0px 5%;
    color: white;
}
.hireNowStickyHidden{
    display: none;
}
.secondPaintWrapper{
    height: 60vh;
    padding: 0px 7%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.spTitle{
    width: 50%;
}
.spTitle h2{
    font-weight: 600;
}
.sptFirstTxt{
    color: #000000d1;
}
.sptSecondTxt{
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.sptThirdTxt{
    font-weight: 600;
    color: #00000080;
    margin-top: 6px;
}
.enroll{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
}
.scholarship{
    font-size: 0.7rem;
    font-weight: 600;
    color: rgb(224, 20, 20);
    margin: 40px 0px 4px;
}
.thirdPaintWrapper{
    height: 70vh;
}
.enrollNowLink{
    
    padding: 12px 50px;
    background-color: black;
    color: white;
    font-weight: 600;
    width: 130px;
    text-align: center;
}

@media screen and (max-width: 480px) {
    .mainTitle {
        text-align: center;
    }

    .webView {
        display: none;
    }

    .mobileView {
        display: block;
    }
    .stickyBtn{
        display: block;
    }
    .stickyBtnHidden{
        display: block;
    }
    .spTitle{
        width: 100%;
    }
    .enroll{
        display: none;
    }
}