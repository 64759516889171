.mainWrapper{
    padding: 70px 0px;
    background-color: #f7f7f7;
    width: 100%;
}
.mainContainer{
    width: 100%;
}
.firstPaintWrapper{
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.topTitle{
    text-align: center;
}
.topTitle h1{
    font-weight: 600;
    padding: auto 0px 5px;
    margin: 20px 0px 5px;
}
.subTitle{
    text-align: center;
    font-size: 0.8rem;
}
.subTitle p{
    font-weight: 500;
    padding: 0px 0px 14px;
    margin: 0px 0px 14px;
    color: #424242;
}
.searchWrapper{
    padding: 0px 30%;
    width: 40%;
}
.searchContainer{
    display: flex;
    border: 1px solid #dfdfdf;
    border-radius: 45px;
    -webkit-border-radius: 45px;
    -moz-border-radius: 45px;
    -ms-border-radius: 45px;
    -o-border-radius: 45px;
    padding: 12px;
    background-color: white;
    gap: 10px;
}
.profileInputContainer{
    flex: 3;
    border-right: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
}
.profileInputContainer input{
    width: calc(100% - 20px);
    padding: 10px 10px;
    border: none;
    font-weight: 600;
}
.profileInputContainer input::placeholder{
    font-weight: 500;
}
.cityInputContainer{
    flex: 3;
    display: flex;
    align-items: center;
}
.cityInputContainer input{
    width: calc(100% - 20px);
    padding: 10px 10px;
    border: none;
    font-weight: 600;
}
.cityInputContainer input::placeholder{
    font-weight: 500;
}
.searchBtnContainer{
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}
.searchBtnContainer a{
    background-color: #212121;
    color: white;
    padding: 12px 26px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    cursor: pointer;
    font-weight: 500;
    font-weight: 0.9rem;
}


@media only screen and (max-width: 600px) {
    .upperHeader{
        padding: 0px 3%;
    }
    .searchWrapper{
        padding: 0px 3%;
        width: 94%;
    }
    .firstPaintWrapper{
        height: 70vh;
        gap: 30px;
    }
    .searchContainer {
        flex-direction: column;
        border-radius: 16px;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        -ms-border-radius: 16px;
        -o-border-radius: 16px;
        gap: 0px;
    }
    .profileInputContainer{
        border: 1px solid #dfdfdf;
        background-color: #dfdfdf20;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        overflow: hidden;
    }
    .profileInputContainer input{
        width: calc(100% - 24px);
        padding: 18px 12px;
        background-color: #dfdfdf20;
        font-size: 0.9rem;
    }
    .profileInputContainer input::placeholder{
        color: #42424260;
    }
    .cityInputContainer{
        border: 1px solid #dfdfdf;
        border-top: none;
        display: flex;
        align-items: center;
        background-color: #dfdfdf20;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        overflow: hidden;
    }
    .cityInputContainer input{
        width: calc(100% - 24px);
        padding: 18px 12px;
        background-color: #dfdfdf20;
        font-size: 0.9rem;
    }
    .cityInputContainer input::placeholder{
        color: #42424260;
    }
    .searchBtnContainer{
        padding: 10px 0px 0px 0px;
    }
}