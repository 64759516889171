.mainWrapper{
    width: 30%;
    max-height: 340px;
    border: 1px solid transparent;
    margin: 16px 0px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    position: relative;
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.mainWrapper:hover{
    border: 1px solid #3ab0c0;
    background-color: white;
}
.subCaptionsWrapper{
    display: flex;
    font-size: 0.75rem;
    padding: 4px 0px;
}
.durationWrapper{
    padding: 4px 2%;
}
.duration{
    padding: 0px 10px 0px 0px;
    font-weight: 600;
    color: #00000086;
}
.dateWrapper{
    font-size: 0.6rem;
    color: #00000086;
}
.modeOfConduction{
    color: #1700FF;
    font-weight: 600;
}
.category{
    padding: 0px 0px 0px 10px;
}

.actionWrapper{
    display: flex;
    justify-content: space-between;
    padding: 10px 2%;
    /* background-color: #da1884; */
}
.eachActionWrapper{
    display: flex;
    align-items: center;
    padding: 6px 12px;
    border: 1px solid #dfdfdf;
    /* background-color: #f1f1f1; */
    background-color: #dfdfdf00;

    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-size: 0.7rem;
}
.eachActionWrapperGallery{
    display: flex;
    align-items: center;
    padding: 6px 12px;
    border: 1px solid #dfdfdf;
    background-color: #dfdfdf00;

    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-size: 0.7rem;
}
.eachActionWrapperCerti{
    display: flex;
    align-items: center;
    padding: 6px 12px;
    border: 1px solid transparent;
    /* background-color: #da188450; */
    background-color: #bdbdbdb0;
    color: black;
    font-weight: 600;

    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-size: 0.7rem;
}

.workshopTitle{
    font-weight: 600;
    padding: 0px 0px 0px 5px;
}
.bannerDescription{
    display: flex;
}
.bigBanner{
    width: 50%;
    padding: 0px 0px 0px 3%;
}
.bigBanner img{
    width: 100%;
    max-height: 180px;
    object-fit: cover;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    overflow: hidden;
    border: 1px solid #dfdfdf;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.descriptionWrapper{
    width: 40%;
    text-align: justify;
    height: 150px;
    overflow: hidden;
    padding: 0px 10px;
}
.descriptionWrapper p{
    font-size: 0.7rem;
    color: #00000086;
    font-style: italic;
}

.smallGallery{
    display: flex;
    padding: 6px 3%;
}
.smallImg{
    width: 54px;
    height: 54px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    /* box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin: 0px 10px 0px 0px;
    border: 1px solid #f1f1f1;
    object-fit: cover;
}
.headerWrapper{
    display: flex;
    align-items: center;
    position: relative;
}
.categoryIcon{
    /* position: absolute; */
    left: -3%;
    top: -10px;
}
.categoryIcon img{
    height: 50px;
    margin-left: -10px;
}



@media only screen and (max-width: 600px) {
    .mainWrapper{
        width: 100%;
        margin: 8px 0px;
    }

}