
a{
    text-decoration: none;
    color: black;
}
.eachSimilarJob{
    width: 100%;
    margin: 18px 0px 0px;
    padding: 0px 0px 14px;
    border-bottom: 1px solid #dfdfdf;

}

.eachSimilarJob:hover{
    transform: scale(1.01);
    -webkit-transform: scale(1.01);
    -moz-transform: scale(1.01);
    -ms-transform: scale(1.01);
    -o-transform: scale(1.01);
}
.esjH{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}
.esjT{
    width: 60%;
}
.esjST{
    width: 40%;
    text-align: end;
}
.esjST span{
    background-color: #da1884;
    color: white;
    font-size: 0.7rem;
    font-weight: 700;
    padding: 2px 6px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.esjtP{
    font-size: 1.2rem;
    font-weight: 700;
}
.esjtL{
    font-size: 0.8rem;
    font-weight: 400;

}
.esJB{
    width: 100%;
}
.esjbRow{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}
.esjbCol:nth-child(1) {
    width: 40%;
}
.esjbCol:nth-child(2) {
    width: 60%;
    text-align: right;
}
.esjbcE{
    width: 100%;
}
.esjbceT{
    font-size: 0.7rem;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    font-weight: 700;
    color: #323232;
    
}
.esjbceV{
    color: #323232;
    font-weight: 600;
}



@media only screen and (max-width: 1380px) {
    .eachSimilarJob{
        margin: 0px;
        padding: 3px 0px 6px;
    }
    .esjtP{
        font-size: 1rem;
    }
    .esjtL{
        font-size: 0.6rem;
    }
    .esjbceT{
        font-size: 0.6rem;
    }
    .esjbceV{
        font-size: 0.7rem;
    }


}