.eachFAQQuestion{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.eachFAQQuestion h4 {
    padding: 0px;
    margin: 0px;
}
.questionContainer{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 90%;
}
.question{
    padding-left: 0%;
    width: 100%;
}
.question h4{
    font-size: 1rem;
}
.eachFAQAnswer p{
    font-size: 0.9rem;
    text-align: justify;
}


@media only screen and (max-width: 600px) {
    .question h4{
        font-size: 0.8rem;
    }
    .eachFAQAnswer p{
        font-size: 0.7rem;
        text-align: justify;    
    }

}