.mainContainer {
    width: 100%;
    background-image: linear-gradient(#F0F9FF, white);
}

.privacyPolicyContainer {
    width: 100%;
    padding: 10vh 0;
}

.privacyPolicyContent {
    margin: 0px 7%;
    padding: 2% 2%;
    border: 1px solid #dfdfdf;
    background-color: white;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    box-shadow: -1px 0px 59px -12px rgb(0 0 0 / 19%);
    
}

.title{
    font-weight: 600;
}