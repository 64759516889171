.mainContainer{
    width: 100%;
    position: relative;
}
.eachCoverImage img{
    padding: 0px 3%;
    min-height: 52vh;
    object-fit: cover;
}
.coverTxt{
    position: absolute;
    top: 0px;
    left: 0px;
    background-image: linear-gradient(#141414, #1212123f, transparent, #121212, #141414);
    height: 100%;
    width: 100%;
    z-index: 2;
    color: white;
    padding: 10px 0px;
}
.coverTxtContainer{
    display: inline-flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    justify-content: space-between;
}


.workshopDescription{
    font-size: 0.7rem;
    text-align: justify;
    padding: 0px 5%;
}
.viewDetailsContainer button{
    background-color: #da1884;
    color: white;
    padding: 8px 14px;
    font-size: 0.8rem;
    border: 1px solid white;
    margin-top: 10px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.startDate{
    border: 1px solid #dfdfdf;
    padding: 5px 10px;
    font-size: 0.7rem;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}