.mainWrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    font-family: 'Montserrat';
}
.leftWrapper{
    width: 50%;
    background-image: linear-gradient(#05141D, #063846);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}
.rightWrapper{
    width: 50%;
}
.logoWrapper{
    padding: 40px 5%;
    height: 20vh;
}
.logoWrapper img{
    height: 20px;
}
.hireTxtBigger{
    padding: 0px 5%;
    text-align: center;
    color: #E4CEA5;
    height: 50vh;
    align-items: center;
}
.hireTxtBigger h1{
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 140%;
}
.inJustFewHours{
    display: flex;
    padding: 5vh 5%;
    justify-content: center;
    align-items: center;
    color: #da1884;
    padding: 10px 4%;
    font-size: 2rem;
    height: 30vh;
}
.leftLine{
    background-image: linear-gradient(90deg, #E4CEA5, #A1784F);
    width: 20%;
    height: 3px;
}
.hireTxt h2{
    font-size: 1rem;
    padding: 10px 5px;
    text-align: center;
    color: #E4CEA5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
}
.rightLine{
    background-image: linear-gradient(270deg, #E4CEA5, #A1784F);
    width: 20%;
    height: 3px;
}




.rightContainer{
    padding: 3vh 10% 7vh;
    display: flex;
    flex-direction: column;
    height: 90vh;
    justify-content: space-between;
}
.eachFieldWrapper{
    padding: 10px 0px;
}
.fieldTitle{
    padding: 0px 0px 4px;
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px 0px;
}
.fieldTitle h4{
    font-weight: 600;
    font-size: 1.2rem;
    margin: 0px 0px 4px;
}
.fieldTitle p{
    font-size: 0.6rem;
    font-style: italic;
    font-weight: 600;
    color: #c10505;
}
.fieldInput input{
    padding: 12px 2%;
    width: calc(76% - 2px);
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.fieldInput select{
    padding: 12px 2%;
    width: calc(80% - 2px);
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.yourDataIsSafe{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
}
.yourDataIsSafe p{
    font-size: 0.8rem;
}
.tncTxt{
    font-size: 0.6rem;
}
.submitBtn{
    width: 80%;
}
.submitBtnActive{
    padding: 12px 0px;
    text-align: center;
    width: 100%;
    background-color: #05141D;
    border: 1px solid #05141D;
    font-weight: 600;
    letter-spacing: 1.1px;
    text-transform: uppercase;
    color: #E4CEA5;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.submitBtnInactive{
    padding: 12px 0px;
    text-align: center;
    width: 100%;
    background-color: #05141D60;
    border: 1px solid #05141D;
    font-weight: 600;
    letter-spacing: 1.1px;
    text-transform: uppercase;
    color: #E4CEA5;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.rightLoader{
    position: fixed;
    top: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 50%;
    background-color: #00000046;
}
.rightLoader img{
    width: 60px;
}
.statusTitle{
    width: 80%;
    display: flex;
    justify-content: space-between;
}
.success{
    color: green;
    font-weight: 600;
}


@media screen and (max-width: 480px) {
    .leftWrapper{
        display: none;
    }
    .rightWrapper{
        width: 100%;
    }
    .fieldInput input{
        width: calc(96% - 2px);
    }
    .fieldInput select{
        width: calc(100% - 2px);
    }
    .yourDataIsSafe{
        width: 100%;
    }
    .submitBtn{
        width: 100%;
    }
    .fieldTitle{
        width: 100%;
    }
    .rightLoader{
        width: 100%;
    }
    .statusTitle{
        width: 100%;
    }
}