.mainWrapper {
    width: 100%;
}

.mainBody {
    width: 100%;
}

.firstImpression {
    padding: 0vh 0px;
    width: 100%;
    height: 100vh;
    background-color: black;
    position: relative;
}

.fibg {
    top: 0px;
    left: 0px;
    display: inline-flex;
    flex-direction: column;
    height: 92vh;
    width: 100%;
}

.videoPlayer {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    opacity: 1;
}

.upperThug {
    height: 50vh;
    width: 100%;
    background-image: linear-gradient(180deg, black, #c5c5c5);

}

.lowerThug {
    width: 100%;
    height: 50vh;
    background-image: linear-gradient(180deg, #c5c5c5, black);
}

.heroTxtWrapper {
    width: 100%;
    height: 100vh;
    position: absolute;
    color: white;
    top: 0px;
    left: 0px;
    text-align: center;
}

.mainHeroTxtWrapper {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    padding: 10vh 0px 0px;
}

.mainHeroTxt h2 {
    text-transform: uppercase;
    font-size: 2.4rem;
    letter-spacing: 1.3px;
    font-weight: 400;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.mainHeroTxt h4 {
    padding: 8px 0px;
    margin: 0px;
}

.registerButtonWrapper {
    width: 100%;
}

.registerButtonContainer {
    position: relative;
    text-align: center;
    width: 100%;
    display: inline-flex;
    justify-content: center;

}

.registerBtn {
    background-color: #FFF7E1;
    border: none;
    padding: 12px 0px;
    text-transform: uppercase;
    font-weight: 800;
    position: absolute;
    z-index: 3;
    width: 180px;
}

.hiddenLine {
    position: absolute;
    border: 1px solid #FFF7E1;
    height: 40px;
    background-color: transparent;
    top: 7px;
    margin-left: 15px;
    z-index: 2;
    width: 180px;
}



.imageAutoSliderWrapper {
    width: 100%;
    height: 40vh;
    color: red;
    /* border: 2px solid red; */
}


.scrollBtnWrapper {
    width: 100%;
    height: 10vh;
    /* border: 2px solid red; */
}

.scrollArrowBtn {
    background-color: #da1884;
    border: none;
    height: 48px;
    width: 48px;
    border-radius: 48px;
    -webkit-border-radius: 48px;
    -moz-border-radius: 48px;
    -ms-border-radius: 48px;
    -o-border-radius: 48px;
}
.scrollDteTxt{
    color: #363636;
    font-size: 0.8rem;
    letter-spacing: 1.2px;
    padding: 6px;
}


.mostPopularWrapper{
    width: 100%;
}
.mostPopularHeader h2{
    text-transform: uppercase;
}






.mpwWrapper{
    height: 80vh;
    background-color: black;
}

.mpwWrapperMobile{
    height: 70vh;
    background-color: black;
    display: none;
}


.gallary{
    height: 80vh;
    background-color: black;
}

.trendingWorkshopLoaderContainer{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    height: 70%;

}
.twlcLeft{
    width: 20%;
    background-color: #141414;
    border: 1px solid #575757;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
}
.twlcCenter{
    width: 50%;
    border: 1px solid #575757;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
}
.twlcRight{
    width: 20%;
    border: 1px solid #575757;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
}
.twlcBtn{
    margin: 15px;
    padding: 25px;
}


.featuredWorkshopWrapper{
    color: white;
    padding: 50px 10%;
    background-color: #202020;
}
.featuredWorkshopContainer{
    width: 100%;   
    display: flex;
    align-items: center;
    gap: 40px;
}
.eachCoverImage{
    width: 100%;
}
.eachCoverImage img{
    width: 100%;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.upComingTitle{
    font-weight: 500;
    font-size: 1.3rem;
    padding: 10px 2%;
}
.workshopTitle h3{
    font-size: 2rem;
}
.workshopDescription{
    text-align: justify;
    font-weight: 500;
    font-size: 0.8rem;
}
.eachUpcomingWorkshopWrapper{
    border: 1px solid #dfdfdf;
}
.viewDetailsContainer{
    padding: 30px 0px 50px;
}
.viewDetailsContainer a{
    padding: 10px 20px;
    background-color: #da1884;
    color: white;
    font-weight: 600;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}


@media only screen and (max-width: 600px) {
    .mainHeroTxt h2{
        font-size: 1.4rem;
    }
    .mostPopularHeader h2{
        font-size: 1.2rem;
    }
    .featuredWorkshopContainer{
        flex-direction: column;
    }
    .workshopTitle h3{
        font-weight: 1.2rem;
    }
    
}