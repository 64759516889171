.mainContainer{
    width: 100%;
    background-color: #f8f8f8;
    height: 100vh;
}
.mainContentWrapper{
    padding: 14vh 0px 0px 0px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.seachBarContainer{
    background-color: white;
    width: 50%;
    border: 1px solid #BEBFC5;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;


}
.seachBarContainer input{
    padding: 14px;
    border: none;
    width: 95%;
}
.seachBarContainer input:focus{
    outline: none;
}
.seachBarContainer span{
    width: 5%;
    align-items: center;
    text-align: center;
}

.popularContainer{
    width: 50%;
}
.popularContainerTitle{
    width: 100%;
}
.popularContainerTitle h1{
    padding: 5vh 0px; 
    font-size: 1rem;
}
.popularContainerBody{
    width: 100%;
    display: inline-flex;
}

.eachPopularTag{
    cursor: pointer;
    padding: 0px 10px;
    min-width: 10%;
}
.eachPopularIcon{
    text-align: center;
}
.eachPopularIcon img{
    width: 40px;
    height: 40px;
}
.eachPopularName{
    text-align: center;
}
.eachPopularName h3{
    font-size: 0.7rem;
    font-weight: 300;
    color: #9e9c9c;
}

.searchResultsContainer{
    padding: 10px 0px;
    width: 50%;
    height: 75vh;
}
.searchResultsTitle{
    color: #9e9c9c;
    height: 4vh;
}
.searchResultsBody{
    overflow-y: scroll;
    height: 70vh;
    /* border: 1px solid red; */
}


.eachCourseContainer{
    width: 100%;
    background-color: white;
    margin: 0px 0px 12px 0px;
    overflow: hidden;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.eachCourseCoverContainer{
    height: 30vh;
    width: 100%;
    position: relative;
}
.eachCourseCoverImg{
    position: absolute;
    height: 30vh;
}
.eachCourseCoverImg img{
    height: 30vh;

}
.eachCourseCoverLayer{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 30vh;
    width: 100%;
    background-color: #0000008c;
}
.courseTitle{
    width: 96%;
    padding: 0px 2%;
}
.courseTitle h3{
    color: white;
}

.courseBenefitTagsContainer{
    width: 96%;
    display: inline-flex;
    padding: 4px 2%;
}
.eachBenefitTag{
    font-size: 0.8rem;
    margin: 4px 8px 4px 0px;
    padding: 2px 14px;
    background-color: whitesmoke;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.langDurModContainer{
    width: 96%;
    padding: 14vh 2% 4px 2%;
    color: white;
    display: inline-flex;
    justify-content: space-between;
}

.certiNJobContainer{
    width: 96%;
    padding: 4px 2%;
    color: white;
    display: inline-flex;
    justify-content: space-between;
}


@media only screen and (max-width: 600px) {
    .mainContentWrapper{
        padding: 8vh 0px 0px 0px;
    }
    .seachBarContainer{
        width: 90%;
    }
    .popularContainer{
        width: 90%;
    }
    .searchResultsContainer{
        width: 90%;
    }
    .popularContainerTitle h1{
        padding: 1vh 0px;
    }
    .seachBarContainer input{
        width: 80%;
    }
    .seachBarContainer span{
        width: 10%;
    }
    .searchResultsBody{
        height: 80vh;
    }

}


@media only screen and (max-width: 1340px) {
    .seachBarContainer input{
        padding: 9px 14px;
        font-size: 0.7rem;
    }

}