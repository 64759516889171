.mainContainer{
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, white,  #EFF3F6, white);
}
.faqHeader{
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
}
.smallTitle{
    width: 100%;
    text-align: center;
    color: black;
    opacity: 65%;
}
.bigTitle{
    width: 100%;
    text-align: center;
    color: black;
    opacity: 86%;
}
.bigTitle h2{
    padding: 0px;
    margin: 0px;
}
.faqBody{
    width: 70%;
    padding: 40px 15%;
}
.eachFAQWrapper{
    border-bottom: 1px solid #dfdfdf9d;
    padding-bottom: 20px;
    margin-bottom: 20px;
}


@media only screen and (max-width: 600px) {
    .faqBody{
        width: 90%;
        padding: 40px 5%;
    }

}