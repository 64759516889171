.mainContainer {
    width: 100%;
    background-color: #f3f3f3;
    /* background-image: linear-gradient(#00000085, #121212, #00000073, #121212, #000000a4); */
}

.contentWrapper {
    width: 100%;
    height: 90vh;
    display: inline-flex;
    margin: 10vh 0px 0px 0px;
}

.leftContentWrapper {
    /* border: 1px solid #a5a5a5; */
    width: 26.5%;
    padding: 0px 2%;
}

.categoryTitleCardContainer {
    /*box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
        */
    /*box-shadow: 0px 2px 6px 2px rgb(0 0 0 / 20%), 0 2px 12px 0 rgb(0 0 0 / 19%);
        */
    border: 1px solid #dfdfdfcf;
    display: inline-flex;
    justify-content: space-between;
    width: calc(96% - 2px);
    align-items: center;
    background-color: white;
    padding: 5px 2%;
    cursor: pointer;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.eachCategoryBtnSelected {
    width: 24%;
    text-align: center;
    text-decoration: none;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1.3px;
    font-size: 13px;
    font-weight: 700;
    cursor: pointer;
    background-color: black;
    padding: 7px 10px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.eachCategoryBtn {
    width: 24%;
    text-align: center;
    text-decoration: none;
    color: #a5a5a5;
    text-transform: uppercase;
    letter-spacing: 1.3px;
    font-size: 13px;
    font-weight: 700;
    cursor: pointer;
    padding: 7px 10px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.listWrapper {
    height: 80vh;
    padding: 1.5vh 0px 0px 0px;
    overflow-y: scroll;
}

.courseSmallTitle h4 {
    padding: 0px 0px;
    margin: 0px 0px;
}

/* .eachItemWrapper {
    border: 1px solid #dfdfdfcf;
    width: calc(94% - 2px);
    align-items: center;
    background-color: white;
    padding: 10px 3%;
    margin: 10px 0px;
    cursor: pointer;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.eachItemWrapperSelected {
    border: 1px solid #da1884;
    width: calc(94% - 2px);
    align-items: center;
    background-color: white;
    padding: 10px 3%;
    margin: 10px 0px;
    cursor: pointer;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
} */

/* .noCourseFound {
    text-align: center;
    font-weight: 600;
    height: 60vh;
    display: inline-flex;
    width: 100%;
    justify-content: center;
    align-items: center;
} */

/* .ratingContainerSmall {} */
/* 
.courseSmallCardFooter {
    padding: 20px 0px 5px 0px;
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}

.trendingNStats {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
}

.isTrendingSmall {
    font-size: 14px;
    color: #b9b9b9;
    padding: 0px 8px;
}

.tagsContainerEachRow {
    display: inline-flex;

}

.eachTag {
    font-weight: 600;
    color: #a5a5a5;
    font-size: .8rem;
    background-color: #f2f2f2;
    padding: 5px 8px;
    margin: 4px 4px 4px 0px;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
} */


.centerContentWrapper {
    width: 45%;
    position: relative;
}

.centerTitleCardContainer {
    border: 1px solid #dfdfdfcf;
    display: inline-flex;
    justify-content: space-between;
    width: calc(100% - 2px);
    align-items: center;
    background-color: white;
    overflow: hidden;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.centerTitleCardContainer input {
    padding: 12px 2%;
    width: 86%;
    border: none;
}

.centerTitleCardContainer input:focus {
    outline: none;
}

.centerTitleCardContainer button {
    width: 10%;
    padding: 10.3px 0px;
    cursor: pointer;
    background-color: black;
    color: white;
    border: none;
}

.courseContentWrapper {
    border: 1px solid #dfdfdfcf;
    background-color: white;
    height: 79vh;
    margin: 2.5vh 0px 0px 0px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    position: relative;
    overflow: hidden;
}

.courseContentContainer {
    height: 79vh;
    overflow-y: scroll;
}

.courseHeroContainer {
    position: relative;
    height: 40vh;
}

.courseContentWrapper video {
    width: 100%;
    position: absolute;
    z-index: 1;
}

.courseHeroContent {
    background-image: linear-gradient(black, transparent);
    position: absolute;
    z-index: 2;
    height: 40vh;
    color: white;
    width: 100%;
}

.courseTitleContainer {
    display: inline-flex;
    padding: 10px 2%;
    width: 96%;
}

.courseTitleContainer .courseTitle {
    width: 90%;
    text-transform: capitalize;
}

.courseDurationNLectCount {
    margin-top: 20vh;
    width: 96%;
    display: inline-flex;
    justify-content: space-between;
    padding: 0px 2%;
}

.courseRating {
    width: 96%;
    display: inline-flex;
    justify-content: space-between;
    padding: 8px 2%;
}

.courseLanguage {
    display: inline-flex;
    align-items: center;
}

.courseMainContent {
    z-index: 3;
    position: relative;
    padding-bottom: 10vh;
}

.courseAdvantage {
    width: 91%;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 8px 2%;
    /* background-color: white; */
    background-image: linear-gradient(#EFF7F6, #ECF6F5);
    color: black;
    padding: 10px 2%;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border: 2px solid white;
}

.isCertificate {
    display: inline-flex;
    align-items: center;
    padding: 10px 0px;
}

.isJobOffer {
    display: inline-flex;
    align-items: center;
    padding: 10px 0px 4px 0px;
}

.courseTitleContainer .courseTitle h1 {
    padding: 0px;
    margin: 0px;
}

.courseTitleContainer .bookmarkCourse {
    width: 10%;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;

}

.courseTitleContainer .bookmarkCourse .bookmarkIcon {
    padding: 10px;
    background-color: #f2f2f2;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    cursor: pointer;
}

.whatYoullLearnTitle {
    font-size: 14px;
    font-weight: 600;
}

.whatYoullLearnContent {
    padding: 6px 0px 0px 1%;
    font-size: 13px;
    display: inline-flex;
    flex-direction: column;
}

/* .whatYoullLearnContentEach {} */



.courseContentFooter {
    position: absolute;
    bottom: 0px;
    width: 96%;
    padding: 22px 2%;
    padding-bottom: 10px;
    z-index: 4;
    background-image: linear-gradient(transparent, black);
}

.courseActionContainer {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.courseActionUrl {
    width: 45%;
    margin: 0px 5% 0px 0px;
    border: 1px solid #dfdfdf;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F2F2F2;
    overflow: hidden;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 0px 0px 0px 8px;
}

.courseActionUrl input {
    width: 80%;
    padding: 10px 0px;
    border: none;
    background-color: #F2F2F2;
}

.courseActionUrl input:focus {
    outline: none;
}

.courseActionUrl button {
    width: 14%;
    padding: 4px 4px;
    border: none;
    background-color: #F2F2F2;
    cursor: pointer;
}

.courseActionShare {
    width: 20%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.courseActionShare .shareCourse {
    width: 100%;
    padding: 10px 0px;
    text-decoration: none;
    font-size: 0.9rem;
    cursor: pointer;
    border: 1px solid #da1884;
    background-color: #da1884;
    color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.courseActionViewDetails {
    width: 20%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.courseActionViewDetails .viewDetailsOnPage {
    text-decoration: none;
    font-size: 0.9rem;
    width: 100%;
    padding: 10px 0px;
    cursor: pointer;
    border: 1px solid black;
    background-color: black;
    color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}





.rightContentWrapper {
    width: 19.5%;
    padding: 0px 2%;
}


.buyThisCard {
    margin: 20px 0px;
    width: 100%;
    background-color: white;
    border: 1px solid #dfdfdfcf;
    padding: 30px 0px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.buyCardFooterSection {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.buyCardFooterSection button {
    width: 80%;
    padding: 12px 0px;
    font-weight: 600;
    font-size: 1.01rem;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    cursor: pointer;
    background-color: black;
    color: white;
    border: none;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.contentWrapperMobile {
    display: none;
}
.courseLoader{
    overflow: hidden;
    margin: 40px 0px 0px 0px ;
}
.havenotLaunchedYet{
    text-align: center;
}
.havenotLaunchedYet h2{
    font-size: 1rem;
    padding: 0px 4%;
}
.eachCourseLoader{
    border-radius: 8px;
    border: 1px solid #e7e7e7;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    margin: 20px 0px;
    overflow: hidden;
}
.noCourseSelectedContainer{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    width: 100%;
    background-color: white;
    border-radius: 14px;
    border: 1px solid #dfdfdf;
}


@media only screen and (max-width: 600px) {
    .eachCategoryBtn{
        font-size: 1rem !important;
    }
    .contentWrapper {
        display: none;
    }

    .contentWrapperMobile {
        display: block;
    }

    .topContentWrapperMobile {
        width: 96%;
        padding: 8vh 0px 0px 2%;
    }
    .courseListWrapper{
        padding: 0px 0px 10vh;
    }
    .courseFetchingLoader{
        margin: 0px 3%;
    }

    .categoryTitleCardContainer{
        padding: 5.4px 6px !important;
    }
    .centerTitleCardContainer input{
        width: 82%;
    }
    .centerTitleCardContainer button{
        padding: 15.3px 0px;
        width: 14%;
    }



    .footerContentMobile {
        position: fixed;
        bottom: 0px;
        width: 96%;
        padding: 8px 2%;
        background-color: #dfdfdf;
    }
    .noCourseFound{
        text-align: center;
        padding: 30px 0px;
        height: 80vh;
    }

}



@media only screen and (max-width: 1380px) {
    .eachCategoryBtn{
        font-size: 0.7rem
    }
    .eachCategoryBtnSelected{
        font-size: 0.7rem;
        padding: 6px 10px;
    }
    .categoryTitleCardContainer{
        padding: 2.4px 0px;
    }
    .centerTitleCardContainer input {
        padding: 14px 2%;
        font-size: 1rem;
    }
    .centerTitleCardContainer button{
        padding: 15.3px 0px;
    }
    .eachCategoryBtnSelected{
        font-size: 16px;
    }
    .buyCardFooterSection button{
        padding: 10px 0px;
    }
    .courseTitleContainer .bookmarkCourse .bookmarkIcon {
        padding: 6px;
    }
    .bookmarkIcon{
        border-radius: 6px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -ms-border-radius: 6px;
        -o-border-radius: 6px;
    }
    .courseActionShare .shareCourse {
        padding: 8px 0px;
        font-size: 0.8rem;
    }
    .courseActionViewDetails .viewDetailsOnPage {
        font-size: 0.8rem;
        padding: 8px 0px;
    }
    .courseActionUrl input {
        padding: 8px 0px;
    }

    .courseTitleContainer .courseTitle h1 {
        font-size: 18px;
    }
    .courseDurationNLectCount {
        font-size: 0.8rem;
    }
    .courseLanguage{
        font-size: 0.8rem;
    }
    .isCertificate{
        font-size: 0.8rem;
        padding: 4px 0px;
    }
    .isJobOffer{
        font-size: 0.8rem;
        padding: 4px 0px;
    }

    .buyThisCard{
        padding: 20px 0px;
    }
    .buyCardFooterSection button{
        font-size: 0.8rem;
    }
}