.mainWrapper {
    width: 100%;
    font-family: 'Montserrat';
}

.firstPaintWrapper {
    height: 100vh;
    width: 100%;
}

.backgroundImageWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #F3D5DD;
    height: 100vh;
}

.backgroundImageWrapper img {
    height: 60vh;
}

.contentWrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;

}

.headerWrapper {
    height: 30vh;
    padding: 20px 3%;
}

.headerWrapper img {
    width: 120px;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    padding: 0px 5%;
    height: 10vh;
    justify-content: flex-end;
}

.subTitleContainer {
    padding: 0px 5%;
    height: 6vh;
    display: flex;
    align-items: flex-start;
}

.subTitleContainer h2 {
    font-weight: 600;
    font-size: 1rem;
    color: #00000080;
}

.titles {
    display: flex;
}

.title {
    opacity: 0;
    display: none;
    transform: translateY(100%);
    margin: 0 10px;

    transition: opacity 0.5s, transform 0.5s;
    -webkit-transition: opacity 0.5s, transform 0.5s;
    -moz-transition: opacity 0.5s, transform 0.5s;
    -ms-transition: opacity 0.5s, transform 0.5s;
    -o-transition: opacity 0.5s, transform 0.5s;
}

.title h1 {
    font-family: 'Montserrat';
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.titleVisible {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.5s, transform 0.5s;
    -webkit-transition: opacity 0.5s, transform 0.5s;
    -moz-transition: opacity 0.5s, transform 0.5s;
    -ms-transition: opacity 0.5s, transform 0.5s;
    -o-transition: opacity 0.5s, transform 0.5s;
}

.titleVisible h1 {
    font-family: 'Montserrat';
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.uspWrapper {
    padding: 20px 7.5%;
    height: 24vh;
}

.eachUsp {
    display: flex;
    align-items: center;
    padding: 0px 0px 7px;
    color: #00000070;
}

.eachUsp h3 {
    font-family: 'Montserrat';
    font-weight: 500;
    padding: 0px;
    margin: 0px;
}

.ctaWrapper {
    height: 30vh;
    padding: 0px 5%;
}

.enrollNowBtn {
    background-color: white;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;

    padding: 14px 26px;
    cursor: pointer;
    font-weight: 600;
    font-family: 'Montserrat';
    text-transform: uppercase;
    letter-spacing: 1.1px;
    width: 150px;
    text-align: center;
}


@media screen and (max-width: 480px) {
    .headerWrapper{
        height: 10vh;
    }
    .backgroundImageWrapper{
        align-items: flex-start;
    }
    .backgroundImageWrapper img{
        height: 18vh;
    }
    .titleVisible{
        padding: 0px 20% 0px 0px;
    }
    .headerWrapper{
        height: 14vh;
    }
    .bodyWrapper{
        height: 86vh;
    }
    .subTitleContainer h2{
        color: #000000;
        font-size: 1rem;
    }
    .ctaWrapper {
        padding: 0px;
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        padding: 0px;
        display: flex;
        align-items: center;
        height: 9vh;
        background-color: white;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    }
    
    .ctaWrapper a{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .enrollNowBtn {
        width: 92%;
        padding: 0px;
        height: 50px;
        border-radius: 0px;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        -o-border-radius: 0px;

        display: flex;
        align-items: center;
        justify-content: center;
        background-color: black;
        color: white;
    }
    .uspWrapper{
        justify-content: center;
        display: flex;
        flex-direction: column;
    }
    .eachUsp{
        color: #000000;
    }
    .eachUsp h3{
        font-size: 1rem;
    }
    
}