.mainContainerVisible{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: inline-flex;
    background-color: #f8f8f8;
    color: black;
    background-color: #f8f8f8;
    flex-direction: column;
}
.hide{
    display: none;
}
.header{
    width: 100%;
    display: inline-flex;
    height: 10vh;
}
.header button{
    background-color: transparent;
    border: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: 44px;
    background-color: white;
    margin: 14px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.eachNavOptions{
    width: 84%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 4%;
    font-size: 1.2rem;
    font-weight: 600;
    color: #464646;
    /* border-bottom: 1px solid #dfdfdf; */

    /* border: 1px solid #dfdfdf; */
    margin: 5px 4%;
    border-radius: 6px;
}

.headerSectionTitle{
    text-align: left;
    margin-left: 4%;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    color: #a1a1a1;
    font-size: 0.9rem;
    margin-top: 20px;
}
.navOptionsTitle{
    display: inline-flex;
    align-items: center;
}
.navOptionTxt{
    padding: 0px 0px 0px 10px;
}
.body{
    height: 80vh;
    overflow-y: scroll;
}
.footer{
    height: 10vh;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.loginWrapper{
    border: 1px solid #dfdfdf;
    padding: 12px 0px;
    width: 50%;
    border-radius: 50px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.8px;
    background: black;
    color: white;
}