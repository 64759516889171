.mainWrapper{
    width: calc(100% - 14px);
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    padding: 7px;
    animation: growUp .6s;
    -webkit-animation: growUp .6s;
}
.mainContainer{
    width: 100%;
}

.mainContent{
    width: 100%;
}
.bodyWrapper{
    padding: 4%;
    background-color: #FFE1CC;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;

}


.dateWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
.dateTag{
    background-color: #ffffff;
    border-radius: 36px;
    -webkit-border-radius: 36px;
    -moz-border-radius: 36px;
    -ms-border-radius: 36px;
    -o-border-radius: 36px;
    padding: 12px 18px;
    font-weight: 500;
    font-size: 0.8rem;
    animation: growUp .4s;
    -webkit-animation: growUp .4s;
    animation-delay: 0.2s;
}
.bookmarkTag{
    width: 40px;
    height: 40px;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    animation: growUp .4s;
    -webkit-animation: growUp .4s;
    animation-delay: 0.2s;
}

.profile h3{
    font-weight: 600;
    font-size: 1.2rem;
}

.footerWrapper{
    padding: 4%;
}
.footerContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.apply{
    background-color: #212121;
    padding: 12px 18px;
    color: whitesmoke;
    font-weight: 500;
    font-size: 0.8rem;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;

    animation: growUp .4s;
    -webkit-animation: growUp .4s;
    animation-delay: 0.2s;
}


.benefitWrapper{
    display: flex;
    gap: 10px;
}
.benefitContainer{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
.eachBenefitContainer{
    border: 1.5px solid #adadad;
    padding: 6px 10px;
    border-radius: 28px;
    -webkit-border-radius: 28px;
    -moz-border-radius: 28px;
    -ms-border-radius: 28px;
    -o-border-radius: 28px;
    font-size: 0.7rem;
    font-weight: 500;
}


.salary{
    font-weight: 600;
    animation: growUp .4s;
    -webkit-animation: growUp .4s;
    animation-delay: 0.2s;
}
.address{
    color: #9D9EA4;
    font-weight: 500;
    font-size: 0.7rem;
    animation: growUp .4s;
    -webkit-animation: growUp .4s;
    animation-delay: 0.2s;
}


@keyframes growUp {
    0% { transform: scale(0.1); -webkit-transform: scale(0.1); -moz-transform: scale(0.1); -ms-transform: scale(0.1); -o-transform: scale(0.1); }
    100% { transform: scale(1); -webkit-transform: scale(1); -moz-transform: scale(1); -ms-transform: scale(1); -o-transform: scale(1); }
}