.mainWrapper{
    width: 100%;

}
.firstPaintWrapper{
    background-image: url("https://stylelink.s3.ap-south-1.amazonaws.com/web-design-assets/waves/1.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.firstPaintContainer{
    display: flex;
    color: white;
    flex-direction: column;
    height: 60vh;
    padding: 30px 7%;
    align-items: center;

}
.firstPaintContainer h1{
    margin: 25px 0px 4px;
}
.contactUsBtn{
    padding: 10px 30px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-weight: 500;
}

.centersTagline{
    text-align: center;
    font-weight: 500;
    padding: 0px 40px;
}