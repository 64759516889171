.bookYourSeatWrapper{
    height: 80vh;
    background-color: #141414;
    color: white;
    padding: 5vh 12%;
    width: 76%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
}
.mainTitle{
    font-size: 1.4rem;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    width: 100%;
    text-align: center;
}
.mainTitle h3{
    font-weight: 500;
}
.eachrow{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    padding: 20px 0%;
}

.eachCol{
    width: 50%;
}
.eachCol:nth-child(even) {
    width: 50%;
    display: inline-flex;
    justify-content: flex-end;
    align-items: flex-end;
    /* background-color: red; */
}
.eachField{
    width: 80%;

}
.efLabel{
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
}
.efLabel button{
    border: none;
    background-color: transparent;
    color: #ffffffda;
    font-size: 0.8rem;
    font-weight: 700;

}
.efLabel button:hover{
    color: red;
}
.efField{
    width: 100%;
    padding: 12px 0px;
}
.eachField .efField input{
    width: 94%;
    padding: 12px 3%;
    border: 1px solid rgba(255, 255, 255, 0.884);
    background-color: transparent;
    color: #ffffffdc;
}
.eachField .efField select{
    width: 100%;
    padding: 12px 4px;
    border: 1px solid rgba(255, 255, 255, 0.884);
    background-color: transparent;
    color: #ffffffdc;
}
.phoneNumber{
    letter-spacing: 1.8px;
}
.phoneNumber::placeholder{
    letter-spacing: normal;
}

.bookNowBtnContainer{
    width: 100%;
    text-align: center;
}
.bookNowBtnContainer button{
    padding: 12px 0px;
    width: 200px;
    background-color: #FFF7E1;
    letter-spacing: 1.4px;
    font-weight: 600;
    text-transform: uppercase;
    border: none;
}

.enterOtpWrapper{
    display: inline-flex;
    padding: 20px 10%;
    width: 80%;
    justify-content: center;
    height: 100%;
    flex-direction: column;
}
.optActionContainer{
    padding: 20px 15%;
    width: 70%;
    display: inline-flex;
    justify-content: space-between;
}
.optActionContainerCenter{
    padding: 20px 15%;
    width: 70%;
    display: inline-flex;
    justify-content: center;
}
.optActionContainer button{
    padding: 11px 0px;
    width: 200px;
    border: none;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 700;
}
.optActionContainer button:hover{
    transform: scale(1.03);
    -webkit-transform: scale(1.03);
    -moz-transform: scale(1.03);
    -ms-transform: scale(1.03);
    -o-transform: scale(1.03);
}
.optActionContainerCenter button{
    padding: 11px 0px;
    width: 200px;
    border: none;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 700;
}
.optActionContainerCenter button:hover{
    transform: scale(1.03);
    -webkit-transform: scale(1.03);
    -moz-transform: scale(1.03);
    -ms-transform: scale(1.03);
    -o-transform: scale(1.03);
}
.resendOtpBtn{
    background-color: #FFF7E1;
}
.submitBtn{
    background-color: #da1884;
    color: white;
}
.submitBtnDull{
    background-color: #da188371;
    color: #ffffff71;

}
.verifyingOtp{
    padding: 0px 0px;
    color: green;
    /* margin: 0px 10px; */
    /* background-color: #da1884; */
}
.otpVerifiedContainer{
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.verifiedIcon{
    margin: 30px 0px;
    height: 60px;
    width: 60px;
    background-color: white;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    text-align: center;
    justify-content: center;
    display: inline-flex;
    align-items: center;
}

.otpVerifiedContainer h4{
    color: white;
    opacity: 65%;
    font-weight: 400;
}
.otpVerifiedContainer h3{
    color: white;
    opacity: 87%;
    font-weight: 600;
}
.otpVerifiedContainer h5{
    color: white;
    opacity: 30%;
    font-weight: 400;
}
.otpVerifyResponseWrapper{
    width: 100%;
    text-align: center;
    font-weight: 600;
    color: red;
}
.invalidOtpWrapper{
    text-align: center;
    
}
.couldNotSendOtpWrapper{
    text-align: center;
}


@media only screen and (max-width: 600px) {
    .bookYourSeatWrapper{
        height: 90vh;
        padding: 2vh 5%;
        width: 90%;
    }
    .eachrow{
        flex-direction: column;
        padding: 0px 0px 0px;
    }
    .eachCol{
        width: 100%;
    }
    .eachField{
        width: 100%;
    }
    .efField input{
        width: 100%;
    }
    .eachCol:nth-child(even) {
        width: 100%;
    }
    .efLabel{
        display: none;
    }
    .bookNowBtnContainer{
        margin-top: 40px;
    }
    .mainTitle h3{
        padding: 0px 0%;
    }
    .optActionContainer{
        flex-direction: column;
        width: 100%;
        padding: 20px 0px;
    }
    .optActionContainer button{
        margin: 10px 0px;
        width: 100%;
    }
    .enterOtpWrapper{
        width: 100%;
        padding: 0px 0px;
    }
}

.efField option{
    color: black;
    background-color: white;
}

@media only screen and (max-width: 1380px) {
    .efField option{
        color: black;
        background-color: white;
    }

}