.mainContainer{
    width: calc(90% - 2px);
    background-color: white;
    margin: 20px 0px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding: 30px 5%;
    border: 1px solid #e7e7e775;
}


.header{
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
}
.headerLeft{
    display: inline-flex;
    width: 60%;
}
.jobCategoryIcon{
    display: inline-flex;
    align-items: center;
    padding: 0px 14px;
    border-radius: 8px;
    font-weight: 700;
    font-size: 1.2rem;
    background-color: #F390C2;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.profileStoreDetails{
    padding: 0px 0px 0px 4%;

}
.profileName h2{
    padding: 0px;
    margin: 0px;
    font-size: 1.3rem;
}
.storeAddress{
    font-size: 12px;
    color: #a5a5a5;
}

.saveJobContainer{
    background-color: #F390C2;
    border: none;
    display: inline-flex;
    align-items: center;
    padding: 10px 10px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-weight: 600;
}

.body{
    width: 100%;
}
.jobDescription{
    width: 100%;

}
.jobDescription p{
    padding: 10px 0px 0px;
    margin: 0px;
    font-weight: 600;
    color: #a5a5a5;
    font-size: 0.9rem;
}
.skillContainer{
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    padding: 20px 0px;
}
.eachSkillContainer{
    padding: 7px 10px;
    background-color: #F8FAFD;
    margin: 0px 10px 10px 0px;
    font-size: 12px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.foodAccomoWrapper{
    width: 100%;
}
.foodAccomoContainer{
    width: fit-content;
    border-left: 6px solid #31A3BA;
    background-color: #cfeaf0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 6px 3% 6px 1%;
}
.fnaTitle{
    font-size: 12px;
    font-weight: 700;
}
.fnaValue{
    font-size: 11px;
    font-weight: 400;
    
}


.footer{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}
.eachFooterField{
    display: inline-flex;
    align-items: center;
}
.eachFooterField button{
    background-color: #083CFF;
    border: none;
    color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-weight: 700;
    padding: 12px 20px;
}

.readMore{
    font-size: 12px;
    text-align: right;
    color: #a5a5a5;
    float: right;
}


@media only screen and (max-width: 600px) {
    .profileName h2{
        font-size: 0.9rem;
    }
}

@media only screen and (max-width: 1380px) {
    .eachFooterField button{
        padding: 8px 14px;
        font-size: 0.7rem;
    }
    .profileName h2{
        font-size: 1rem;
    }
    .storeAddress{
        font-size: 10px;
    }
    .saveJobContainer{
        padding: 8px 10px;
        font-size: 0.6rem;
    }
    .jobCategoryIcon{
        padding: 0px 10px;
    }
    .skillContainer{
        padding: 4px 0px;
    }
    .fnaTitle{
        font-size: 0.6rem;
    }
    .fnaValue{
        font-size: 0.55rem;
    }
    .eachSkillContainer{
        font-size: 0.6rem;
        padding: 5px 8px;
    }
    .readMore{
        font-size: 10px !important;
    }
    .jobDescription h3{
        font-size: 0.8rem;
    }
    .jobDescription p{
        font-size: 0.7rem;
    }
}