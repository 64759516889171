.mainContainer{
    width: calc(90% - 2px);
    background-color: white;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding: 30px 5%;
    border: 1px solid #e7e7e775;
}
.headerContainer{
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    padding: 0px 0px 14px 0px;
}
.headerTitle{
    font-size: 22px;
    font-weight: 700;
    color: #383332;
}
.headerSubTitle{
    font-size: 14px;
    color: #a5a5a5;
    cursor: pointer;
}
.seachRowContainer{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}
.searchBoxContainer{
    display: inline-flex;
    align-items: center;
    background-color: #ecf0f5;
    width: 68%;
    padding: 0px 1%;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.searchBoxContainer input{
    border: none;
    background-color: #ecf0f5;
    padding: 12px 2%;
    width: 96%;

}
input, select{
    font-family: 'Raleway';
    cursor: pointer;
}
input:focus{
    outline: none;
}
select:focus{
    outline: none;
}

.sortingContainer{
    background-color: #ecf0f5;
    width: 25%;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    overflow: hidden;
}
.sortingContainer select{
    background-color: #ecf0f5;
    width: 96%;
    border: none;
    padding: 11px 2%;
}
.searchResultsCount{
    margin: 14px 0px;
    background-color: #ea59a4ad;
    color: #2d2828;
    font-weight: 600;
    text-align: center;
    padding: 10px 0px;
    font-size: 13px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.sortingContainer select{
    font-weight: 600;
    color: #2d2828;
}



@media only screen and (max-width: 1380px) {
    .mainContainer{
        padding: 20px 5%;
    }
    .headerTitle{
        font-size: 14px;
    }
    .headerSubTitle{
        font-size: 11px;
    }
    .searchBoxContainer input {
        padding: 9px 2%;
        font-size: 10px;
    }
    .sortingContainer select{
        padding: 8px 2%;
        font-size: 11px;
        opacity: 65%;
    }
    .searchResultsCount{
        font-size: 11px;
    }
}