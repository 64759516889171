.mainWrapper{
    padding: 4px 0px 20px;
}
.questionWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.icon{
    display: flex;
    align-items: center;
}
.question h4{
    font-weight: 600;
    padding: 0px 0px 0px;
    margin:  0px 0px 0px 0px;
}
.answer p{
    padding: 0px 0px 0px 0px;
    text-align: justify;
    font-size: 0.9rem;
    font-weight: 500;
    color: #00000087;
}

@media screen and (max-width: 480px) {
    .mainWrapper{
        padding: 4px 0px 14px;
    }

}