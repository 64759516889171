.mainWrapper{
    width: 100%;
    font-family: 'Montserrat';
}
.firstPaintWrapper{
    height: 100vh;
    width: 100%;

}
.fpBackgroundWrapper{
    height: 100vh;
    width: 100%;
}
.fpBackgroundWrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.fpContentWrapper{
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(transparent, #00000095);
}
.mainHeader{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}
.mainHeader h1{
    text-align: center;
    font-weight: 600;
    color: white;
}
.cardTilesWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50vh;
}
.cardTilesContainer{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;  
    width: 100%; 
    padding: 0px 7%;
}
.eachCardTile{
    min-width: 28%;
    max-width: 28%;
    width: 28%;
    /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    height: 120px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;

    margin: 0px 0px 20px;

    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-weight: 600;
    background-color: white;
}
.eachCardTileTxt h3{
    margin: 0px;
    padding: 0px;
    font-family: 'Montserrat';
    font-weight: 600;
}


.secondPaintWrapper{
    height: 40vh;
    background-color: aliceblue;
}



@media screen and (max-width: 480px) {
    .fpBackgroundWrapper img{
        object-fit: cover;
    }
    .fpContentWrapper{
        background-image: linear-gradient(transparent, #00000095);
    }
    .mainHeader h1{
        color: white;
        padding: 0px 7%;
    }
    .cardTilesWrapper{
        align-items: flex-end;
    }
    .cardTilesContainer{
        padding: 0px 3%;
    }
    .eachCardTile{
        min-width: 48%;
        max-width: 48%;
        width: 48%;
        height: 55px;
    }
    .eachCardTileTxt h3{
        font-size: 0.9rem;
    }
}