.mainWrapper{
    height: 100vh;
    width: 100%;
    background-color: #e7e7e740;
}
.mainContainer{
    display: flex;
    flex-direction: column;
}
.upperContainer{
    height: 40vh;
    background-color: white;
    border-bottom-left-radius: 34px;
    border-bottom-right-radius: 34px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}
.failedUpperContainer{
    height: 40vh;
    background-color: #EFF1F3;
    border-bottom-left-radius: 34px;
    border-bottom-right-radius: 34px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}
.iconWrapper{
    width: calc(100% - 80px);
    height: calc(40vh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
}
.iconWrapper img{
    width: 100%;
}
.failedLowerContainer{
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.statusTitle{
    font-weight: 600;
    font-size: 1.4rem;
    text-align: center;
    color: green;
    padding: 44px 0px 10px;
}
.subTitle{
    text-align: center;
    font-weight: 500;
    color: #a5a5a5;
}
.goToBtn{
    text-align: center;
    padding: 40px 0px 10px;
}
.goToBtn a{
    border-radius: 10px;
    padding: 16px 40px;
    background-color: #da1884;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    font-weight: 500;
    color: white;
}


.paymentAmount{
    text-align: center;
}
.amount{
    font-size: 4rem;
    font-weight: 600;
    padding: 20px 0px 20px;
}
.rupeeIcon{
    font-size: 3rem;
}



.failedTitle{
    font-weight: 600;
    font-size: 1.4rem;
    text-align: center;
    color: #FE7866;
    padding: 44px 0px 10px;
}
.failedSubTitle{
    text-align: center;
    font-weight: 500;
    color: #a5a5a5;
    padding: 0px 7%;
}
.retryBtn{
    padding: 40px 0px 20px;
}
.retryBtn a{
    background-color: #FE7866;
    color: white;
    font-weight: 600;
    padding: 15px 20px;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.support{
    font-weight: 500;
    color: #e7e7e7;
    text-transform: uppercase;
    letter-spacing: 1.2px;
}